body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

canvas {
  background: white;
}

.chapter-control {
  display: none;
}

.invisible {
  display: none !important;
}

.controls {
  z-index: 500;
  position: absolute;
  height: 50px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  .Button {
    will-change: opacity;
    -webkit-transition: opacity 0.5s; /* Safari */
    transition: opacity 0.5s;
    margin: 5px;
    &:disabled {
      opacity: 0;
      cursor: progress;
    }
  }

  @media screen and (max-width: 600px) {
    height: 30px;
    button {
      height: 30px;
      margin-top: 30px !important;
      line-height: 30px;
      font-size: 11px;
    }
  }
}

.always-visible {
  opacity: 1 !important;
}

#story-stage {
  position: relative;
}

.canvas-logo {
  position: absolute;
  right: 32px;
  bottom: 0;
  display: inline-block;
  width: 125px;
  height: 41.01562px;
  z-index: 1;
  background: url('/static/media/logo_publiceye_raw_red.393eddc3.png') no-repeat center center;
  background-size: 100%;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    display: none !important;
  }
}

a, span, i {
  &.red {
    color: $color-red;
    font-family: $font-family-sans-serif-bold;
    &.link:hover {
      color: $color-red;
      border-bottom: 2px solid $color-red;
    }
  }
}

hr {
  margin: 10px auto;
}

.fat {
  font-family: $font-family-sans-serif-bold, sans-serif;
}

.ui.publiceye-red.label {
  background-color: $color-red !important;
}

.text-vert {
  font-family: $font-family-sans-serif-bold, sans-serif;
  background: $color-vert;
  color: white;
  padding: 0 5px;
}

.text-mer {
  font-family: $font-family-sans-serif-bold, sans-serif;
  background: $color-mer;
  color: white;
  padding: 0 5px;
}

.text-jaune {
  font-family: $font-family-sans-serif-bold, sans-serif;
  background: $color-jaune;
  color: black;
  padding: 0 5px;
}

.text-violet {
  font-family: $font-family-sans-serif-bold, sans-serif;
  background: $color-violet;
  color: white;
  padding: 0 5px;
}

.text-brown {
  font-family: $font-family-sans-serif-bold, sans-serif;
  background: $color-brown;
  color: white;
  padding: 0 5px;
}

.text-red {
  font-family: $font-family-sans-serif-bold, sans-serif;
  background: $color-red;
  color: white;
  padding: 0 5px;
}

.text-blue {
  font-family: $font-family-sans-serif-bold, sans-serif;
  background: $color-blue;
  color: white;
  padding: 0 5px;
}

i.flag:not(.icon) {
  margin: 0 2px !important;
}

a, .red.link {
  &:hover {
    cursor: pointer;
    color: $color-red;
  }
}

.hyphens {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
