/* STYLES FOR HTML CONTENT COMPONENT*/

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

#html-content-component {
  z-index: 50;

.html-content-container {
    bottom: 3px;
    margin: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 6px $color-red;

    ul li {
      margin-bottom: 10px;

      @media screen and (max-width: 600px) {
        margin-bottom: 5px;
      }
    }

    div {
      text-align: center;
      color: $color-white;
    }

    .red.label {
      background: $color-red !important;
    }

    .green.label {
      background: $color-vert !important;
    }

    .middle-aligned-textbox {
      font-family: GT Sectra, Georgia, serif;
      font-weight: bold;
      font-size: 2em;
      line-height: 1.1;
      width: 100%;
      text-align: left;
      color: black;
      margin: 0;
      background: rgba(255,255,255,0.9);
      padding: 15px;
      border-radius: 0;
      transition: opacity 0.5s;
      -webkit-transition: opacity 0.5s;
      will-change: opacity;
      align-self: flex-end;
      padding-bottom: 30px;

      &.invisible {
        opacity: 0.2;
      }

      p {
        color: black;
      }

      @media screen and (max-width: 500px) {
        padding: 5px;
        margin: 0;
        font-size: 0.85em;
        line-height: 1.1;
      }

      @media screen and (max-width: 321px) {
        font-size: 10px;
      }

    }

  }
}
