@charset "UTF-8";
/* Main Public Eye Storytelling Styles */
/* Importing Stylesheets */
/** @license HV v. 2019-03-04 **/
/* prettier-ignore */
/* stylelint-disable */
/* stylelint-enable */
tr.visuallyhidden > tr, tr.visuallyhidden > th, th.visuallyhidden, td.visuallyhidden {
  position: static !important;
  border: 0 !important;
  padding: 0 !important;
  font-size: 0.00001px !important;
  line-height: 0 !important;
  padding: 0 !important;
  height: 0 !important;
  color: transparent; }

* {
  font: inherit;
  color: inherit;
  line-height: inherit;
  text-decoration: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  color: #000;
  background: #FFF; }

figure {
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin-left: 0;
  margin-right: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

del, ins {
  text-decoration: none; }

li {
  list-style: none; }

caption, th {
  text-align: left; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0;
  text-decoration: none;
  font-variant: normal; }

sup {
  vertical-align: baseline; }

sub {
  vertical-align: baseline; }

legend {
  color: inherit;
  *color: #000; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  background: transparent;
  -webkit-appearance: button;
  cursor: pointer;
  border: 0; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

html {
  width: 100%;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  color: #282828; }

body {
  font-family: "GT Sectra", Georgia, Serjf;
  font-weight: regular;
  font-size: 16px;
  line-height: 1.25;
  overflow-x: hidden; }

@media (min-width: 750px) {
  body {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  body {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  body {
    font-size: 16px; } }

@media print {
  body {
    font-size: 14px;
    font-size: 0.875rem; } }

body, input, select, button, textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.logo {
  text-decoration: none; }

.logo:before {
  content: 'â† '; }

a:active {
  outline: none; }

*:focus {
  outline: none; }

abbr {
  border-bottom: 1px dotted gray; }

p sup {
  position: relative;
  top: -0.75ex; }

p sub {
  position: relative;
  top: 0.5ex; }

button {
  cursor: pointer; }

li {
  list-style: none inside none; }

@media print {
  html, body {
    width: 1080px; }
  body {
    overflow-x: hidden;
    margin: 0 auto; }
  * {
    /* stylelint-disable */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    /* stylelint-enable */ }
  /* *, :before, :after { background: transparent; color: #000; box-shadow: none !important; text-shadow: none !important; } */ }

.l-clearfix:before, .l-clearfix:after {
  content: "";
  display: table; }

.l-clearfix:after {
  clear: both; }

@media screen and (min-height: 600px) {
  html #body {
    display: -webkit-flex;
    display: flex;
    min-height: 100vh;
    -webkit-flex-direction: column;
    flex-direction: column; }
  html #body #main-content {
    height: auto;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    min-height: 0; } }

.site-content:before, .site-content:after {
  display: block;
  content: '';
  float: none; }

.site-content:before {
  margin-top: 40px; }

@media screen and (max-width: 1000px) {
  .site-content:before {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .site-content:before {
    margin-top: 25px; } }

.site-content:after {
  margin-bottom: 160px; }

@media screen and (max-width: 1000px) {
  .site-content:after {
    margin-bottom: 76px; } }

@media screen and (max-width: 580px) {
  .site-content:after {
    margin-bottom: 51px; } }

.site-content > *:first-child {
  margin-top: 0; }

.site-content > *:first-child.CategoryMenu, .site-content > *:first-child.HeroHeader {
  margin-top: -40px; }

@media screen and (max-width: 1000px) {
  .site-content > *:first-child.CategoryMenu, .site-content > *:first-child.HeroHeader {
    margin-top: -38px; } }

@media screen and (max-width: 580px) {
  .site-content > *:first-child.CategoryMenu, .site-content > *:first-child.HeroHeader {
    margin-top: -25px; } }

.site-content > *:first-child.SectionTitle {
  margin-top: 80px; }

@media screen and (max-width: 1000px) {
  .site-content > *:first-child.SectionTitle {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .site-content > *:first-child.SectionTitle {
    margin-top: 36px; } }

.site-content > *:last-child.l-pane, .site-content > *:last-child.CTATeaser:not(.plane) {
  margin-bottom: -160px; }

@media screen and (max-width: 1000px) {
  .site-content > *:last-child.l-pane, .site-content > *:last-child.CTATeaser:not(.plane) {
    margin-bottom: -76px; } }

@media screen and (max-width: 580px) {
  .site-content > *:last-child.l-pane, .site-content > *:last-child.CTATeaser:not(.plane) {
    margin-bottom: -51px; } }

#body {
  overflow-x: hidden; }

:root {
  --theme-color: #222222;
  --theme-background: #ffffff; }

html {
  color: #222222; }

html body {
  background-color: #ffffff; }

html.theme {
  color: var(--theme-color, #222222); }

html.theme body {
  background-color: var(--theme-background, #ffffff); }

#flying-focus {
  position: absolute;
  margin: 0;
  top: -100px;
  background: transparent;
  transition-property: left, top, width, height, opacity;
  transition-timing-function: cubic-bezier(0, 0.2, 0, 1);
  visibility: hidden;
  pointer-events: none;
  display: none; }

#flying-focus.flying-focus_visible {
  visibility: visible;
  z-index: 10000000;
  display: block; }

#flying-focus:after {
  content: '';
  position: absolute;
  top: -9px;
  left: -9px;
  bottom: -9px;
  right: -9px;
  border-radius: 3px;
  box-shadow: 0 0 54px 0px rgba(0, 0, 0, 0.2), 0 0 2px 2px rgba(0, 10, 20, 0.1);
  border: 1px solid rgba(0, 10, 20, 0.5); }

#flying-focus:before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  bottom: -8px;
  right: -8px;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.9) inset;
  border: 1px solid rgba(255, 255, 255, 0.8); }

.Skiplinks a {
  position: absolute;
  z-index: 10000;
  top: -999vw;
  background-color: black;
  color: white;
  padding: 5px 10px;
  font-size: 1rem; }

.Skiplinks a:focus, .Skiplinks a:active {
  top: 20px;
  left: 20px;
  right: auto; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

tr.visuallyhidden {
  position: static !important; }

.is-screenreader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.is-screenreader:focus, .is-screenreader:active, .is-screenreader.had-focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

button.is-screenreader:focus {
  background-color: black;
  color: white;
  padding: 5px 10px; }

/* @font-face{ font-family:"Radikal W01 Medium"; src:url("../fonts/Radikal/Fonts/633e44e3-6556-46ce-8844-33248b683573.eot?#iefix"); src:url("../fonts/Radikal/Fonts/633e44e3-6556-46ce-8844-33248b683573.eot?#iefix") format("eot"),url("../fonts/Radikal/Fonts/10fd958a-ca77-4dfa-9d82-aa8c70208b87.woff2") format("woff2"),url("../fonts/Radikal/Fonts/a583bfda-a81b-49ea-afde-85a5035cf5bc.woff") format("woff"),url("../fonts/Radikal/Fonts/26a1a62d-77d6-49c1-8c4e-8d77e46a3043.ttf") format("truetype"),url("../fonts/Radikal/Fonts/fd98725b-9dfb-4de4-bc03-592eeb42ae21.svg#fd98725b-9dfb-4de4-bc03-592eeb42ae21") format("svg"); } @font-face{ font-family:"Radikal W01 Black"; src:url("../fonts/Radikal/Fonts/3d367196-4371-426c-bd4e-698b65b48ca8.eot?#iefix"); src:url("../fonts/Radikal/Fonts/3d367196-4371-426c-bd4e-698b65b48ca8.eot?#iefix") format("eot"),url("../fonts/Radikal/Fonts/ec3d45b9-ade6-413d-9c8c-465ef0874c88.woff2") format("woff2"),url("../fonts/Radikal/Fonts/1e0a281c-c684-4b81-9cae-9d8d49391400.woff") format("woff"),url("../fonts/Radikal/Fonts/be925929-2f0d-485a-a014-feafeac9d7b1.ttf") format("truetype"),url("../fonts/Radikal/Fonts/0955b90d-2c99-49be-8498-4985f7d0011d.svg#0955b90d-2c99-49be-8498-4985f7d0011d") format("svg"); } */
[data-icon='arrow-down']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat 0px -99px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='arrow-down']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat 0px -96px;
    background-size: 159px 137px; } }

.Icon[data-icon='arrow-down'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='arrow-down-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -44px 0px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='arrow-down-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -43px 0px;
    background-size: 159px 137px; } }

.Icon[data-icon='arrow-down-red'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='arrow-left']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat 0px -47px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='arrow-left']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat 0px -46px;
    background-size: 159px 137px; } }

.Icon[data-icon='arrow-left'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='arrow-left-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -70px 0px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='arrow-left-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -68px 0px;
    background-size: 159px 137px; } }

.Icon[data-icon='arrow-left-red'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='arrow-right']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -52px -47px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='arrow-right']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -50px -46px;
    background-size: 159px 137px; } }

.Icon[data-icon='arrow-right'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='arrow-right-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -26px -47px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='arrow-right-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -25px -46px;
    background-size: 159px 137px; } }

.Icon[data-icon='arrow-right-red'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='arrow-up']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -96px -26px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='arrow-up']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -93px -25px;
    background-size: 159px 137px; } }

.Icon[data-icon='arrow-up'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='arrow-up-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -96px 0px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='arrow-up-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -93px 0px;
    background-size: 159px 137px; } }

.Icon[data-icon='arrow-up-red'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='checked-dark']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -122px -78px;
  width: 17px;
  height: 17px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -9px;
  left: 50%;
  margin-left: -9px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='checked-dark']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -93px -50px;
    background-size: 159px 137px; } }

.Icon[data-icon='checked-dark'] {
  padding: 0 8.5px;
  height: 17px;
  margin: 0; }

[data-icon='download']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -26px -99px;
  width: 18px;
  height: 22px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -11px;
  left: 50%;
  margin-left: -9px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='download']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -25px -96px;
    background-size: 159px 137px; } }

.Icon[data-icon='download'] {
  padding: 0 9px;
  height: 22px;
  margin: 0; }

[data-icon='grid']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -54px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='grid']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -51px -121px;
    background-size: 159px 137px; } }

.Icon[data-icon='grid'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='grid-grey']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -72px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='grid-grey']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -68px -121px;
    background-size: 159px 137px; } }

.Icon[data-icon='grid-grey'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-email']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -118px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-email']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -112px -96px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-email'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-email-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -36px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-email-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -34px -121px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-email-red'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-email-white']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -18px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-email-white']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -17px -121px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-email-white'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-fb']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat 0px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-fb']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat 0px -121px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-fb'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-fb-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -70px -26px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-fb-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -43px -25px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-fb-red'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-fb-white']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -90px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-fb-white']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -85px -121px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-fb-white'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-instagram']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -54px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-instagram']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -51px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-instagram'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-instagram-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -126px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-instagram-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -119px -121px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-instagram-red'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-instagram-white']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -96px -52px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-instagram-white']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -68px -25px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-instagram-white'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-twitter']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -46px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-twitter']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -44px -96px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-twitter'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-twitter-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -44px -26px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-twitter-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -75px -46px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-twitter-red'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-twitter-white']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -104px -73px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-twitter-white']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -100px -71px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-twitter-white'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-youtube']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -100px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-youtube']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -95px -96px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-youtube'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-youtube-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -64px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-youtube-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -61px -96px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-youtube-red'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-youtube-white']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -82px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-youtube-white']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -78px -96px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-youtube-white'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='logo-zewo']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat 0px 0px;
  width: 42px;
  height: 45px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -23px;
  left: 50%;
  margin-left: -21px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='logo-zewo']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat 0px 0px;
    background-size: 159px 137px; } }

.Icon[data-icon='logo-zewo'] {
  padding: 0 21px;
  height: 45px;
  margin: 0; }

[data-icon='play']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -78px -47px;
  width: 16px;
  height: 20px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='play']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -118px -75px;
    background-size: 159px 137px; } }

.Icon[data-icon='play'] {
  padding: 0 8px;
  height: 20px;
  margin: 0; }

[data-icon='publications']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -122px 0px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='publications']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -118px 0px;
    background-size: 159px 137px; } }

.Icon[data-icon='publications'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='publications-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -122px -26px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='publications-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -118px -25px;
    background-size: 159px 137px; } }

.Icon[data-icon='publications-red'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='search']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -26px -73px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='search']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -25px -71px;
    background-size: 159px 137px; } }

.Icon[data-icon='search'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='search-gray']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -78px -73px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='search-gray']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -75px -71px;
    background-size: 159px 137px; } }

.Icon[data-icon='search-gray'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='search-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -52px -73px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='search-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -50px -71px;
    background-size: 159px 137px; } }

.Icon[data-icon='search-red'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='share']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -72px;
  width: 15px;
  height: 15px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='share']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -68px;
    background-size: 159px 137px; } }

.Icon[data-icon='share'] {
  padding: 0 7.5px;
  height: 15px;
  margin: 0; }

[data-icon='stre-down']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat 0px -73px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='stre-down']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat 0px -71px;
    background-size: 159px 137px; } }

.Icon[data-icon='stre-down'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

[data-icon='stre-left']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -18px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='stre-left']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -17px;
    background-size: 159px 137px; } }

.Icon[data-icon='stre-left'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='stre-left-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px 0px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='stre-left-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px 0px;
    background-size: 159px 137px; } }

.Icon[data-icon='stre-left-red'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='stre-right']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -108px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='stre-right']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -102px -121px;
    background-size: 159px 137px; } }

.Icon[data-icon='stre-right'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='stre-right-red']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -36px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='stre-right-red']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -34px;
    background-size: 159px 137px; } }

.Icon[data-icon='stre-right-red'] {
  padding: 0 8px;
  height: 16px;
  margin: 0; }

[data-icon='stre-up']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -122px -52px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  [data-icon='stre-up']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -118px -50px;
    background-size: 159px 137px; } }

.Icon[data-icon='stre-up'] {
  padding: 0 12px;
  height: 24px;
  margin: 0; }

.Icon.inline {
  display: inline;
  /* stylelint-disable */
  display: -webkit-inline-box;
  /* stylelint-enable */
  min-height: 1ex;
  letter-spacing: -1ex;
  text-decoration: none;
  position: relative; }

.Icon.inline:before {
  content: ''; }

html.cssreflections .Icon.inline:before {
  content: '\00a0';
  letter-spacing: -1ex; }

.Icon:not(.inline) {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  letter-spacing: -1ex;
  text-indent: -9999px;
  text-decoration: none;
  line-height: 1;
  border: none;
  outline: none; }

.richtext {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .richtext {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .richtext {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .richtext {
    font-size: 21px; } }

@media print {
  .richtext {
    font-size: 16px;
    font-size: 1rem; } }

.richtext table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: 1px solid #F4F5F6;
  margin-bottom: 20px;
  margin-top: 20px; }

.richtext table thead > tr {
  font-size: 18px;
  line-height: 1.35; }

@media (min-width: 750px) {
  .richtext table thead > tr {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .richtext table thead > tr {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .richtext table thead > tr {
    font-size: 21px; } }

@media print {
  .richtext table thead > tr {
    font-size: 16px;
    font-size: 1rem; } }

.richtext table thead > tr > th, .richtext table thead > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.richtext table thead > tr:last-child > th, .richtext table thead > tr:last-child > td {
  border-bottom: 1px solid #000000; }

.richtext table thead > tr > th {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.richtext table tbody > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .richtext table tbody > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .richtext table tbody > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .richtext table tbody > tr {
    font-size: 21px; } }

@media print {
  .richtext table tbody > tr {
    font-size: 16px;
    font-size: 1rem; } }

.richtext table tbody > tr > th, .richtext table tbody > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.richtext table tbody > tr:first-child > th, .richtext table tbody > tr:first-child > td {
  border-top: 1px solid #F4F5F6; }

.richtext table tfoot > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .richtext table tfoot > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .richtext table tfoot > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .richtext table tfoot > tr {
    font-size: 21px; } }

@media print {
  .richtext table tfoot > tr {
    font-size: 16px;
    font-size: 1rem; } }

.richtext table tfoot > tr > th, .richtext table tfoot > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.richtext table tfoot > tr:first-child > th, .richtext table tfoot > tr:first-child > td {
  position: relative; }

.richtext table tfoot > tr:first-child > th:before, .richtext table tfoot > tr:first-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

.richtext table tfoot > tr:last-child > th, .richtext table tfoot > tr:last-child > td {
  border-bottom: 1px solid #F4F5F6;
  position: relative; }

.richtext table tfoot > tr:last-child > th:before, .richtext table tfoot > tr:last-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

@media screen and (max-width: 1000px) {
  .richtext table {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .richtext table {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .richtext table {
    margin-top: 20px; } }

@media screen and (max-width: 580px) {
  .richtext table {
    margin-top: 18px; } }

.richtext p {
  margin-top: 1em;
  margin-bottom: 1em; }

.richtext ul:not([class]) {
  margin: 1em 0;
  display: block; }

.richtext ul:not([class]) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

.richtext ul:not([class]) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

.richtext ul:not([class]) > li:before {
  content: '\2013'; }

.richtext ol:not([class]) {
  margin: 1em 0;
  display: block;
  counter-reset: ol--counter; }

.richtext ol:not([class]) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

.richtext ol:not([class]) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

.richtext ol:not([class]) > li:before {
  counter-increment: ol--counter;
  content: counter(ol--counter) "."; }

html.ie .richtext ol:not([class]) > li {
  list-style: decimal inside none; }

.richtext i {
  font-style: italic; }

.richtext strong {
  font-weight: bold; }

.richtext a:not([class]) {
  position: relative;
  max-width: 100%;
  transition: color 0.1s linear;
  text-decoration: none;
  border-bottom: 2px solid #FF0040; }

.richtext a:not([class]):active, html:not(.can-touch) .richtext a:not([class]):hover {
  color: #FF0040; }

a:active .richtext a:not([class]), html:not(.can-touch) a:hover .richtext a:not([class]) {
  color: #FF0040; }

html.js .is-hidden-js {
  display: none !important; }

html.no-js .is-visible-js {
  display: none !important; }

@media screen and (min-width: 1201px) {
  .is-hidden-desktop {
    display: none !important; }
  .is-visible-tablet {
    display: none !important; } }

@media screen and (max-width: 1200px) {
  .is-visible-desktop {
    display: none !important; }
  .is-hidden-tablet {
    display: none !important; } }

@media screen and (min-width: 1001px) {
  .is-visible-tablet-portrait {
    display: none !important; } }

@media screen and (max-width: 1000px) {
  .is-hidden-tablet-portrait {
    display: none !important; } }

@media screen and (min-width: 751px) {
  .is-visible-mobile {
    display: none !important; } }

@media screen and (max-width: 750px) {
  .is-hidden-mobile {
    display: none !important; } }

@media screen and (min-width: 401px) {
  .is-visible-mobile-portrait {
    display: none !important; } }

@media screen and (max-width: 400px) {
  .is-hidden-mobile-portrait {
    display: none !important; } }

@media only screen {
  .is-visible-print {
    display: none !important; } }

@media print {
  .is-hidden-print {
    display: none !important; } }

body:after {
  display: none;
  content: 'desktop'; }

@media screen and (max-width: 1200px) {
  body:after {
    content: 'tablet'; } }

@media screen and (max-width: 1000px) {
  body:after {
    content: 'tablet-portrait'; } }

@media screen and (max-width: 750px) {
  body:after {
    content: 'mobile'; } }

@media screen and (max-width: 400px) {
  body:after {
    content: 'mobile-portrait'; } }

.media-dispatcher-fallback {
  display: none;
  margin-bottom: 0px; }

@media screen and (max-width: 1200px) {
  .media-dispatcher-fallback {
    margin-bottom: 1px; } }

@media screen and (max-width: 1000px) {
  .media-dispatcher-fallback {
    margin-bottom: 2px; } }

@media screen and (max-width: 750px) {
  .media-dispatcher-fallback {
    margin-bottom: 3px; } }

@media screen and (max-width: 400px) {
  .media-dispatcher-fallback {
    margin-bottom: 4px; } }

body:before {
  display: none;
  content: ''; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  body:before {
    content: 'retina'; } }

.Button {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
  padding-left: 30px;
  padding-left: 1.875rem;
  padding-right: 30px;
  padding-right: 1.875rem;
  line-height: 3;
  background-color: #FF0040;
  color: #ffffff; }

@media (min-width: 750px) {
  .Button {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .Button {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .Button {
    font-size: 16px; } }

@media print {
  .Button {
    font-size: 14px;
    font-size: 0.875rem; } }

@media screen and (max-width: 750px) {
  .Button {
    font-size: 13px;
    line-height: 1.57;
    padding-left: 40px;
    padding-left: 2.5rem;
    padding-right: 40px;
    padding-right: 2.5rem;
    line-height: 3;
    padding: 0 1em; } }

@media screen and (max-width: 750px) and (min-width: 750px) {
  .Button {
    font-size: calc(0vw + 13px); } }

.Button:active, html:not(.can-touch) .Button:hover {
  background-color: #ffffff;
  color: #FF0040;
  box-shadow: inset 0 0 0 2px #FF0040; }

.Button.large {
  font-size: 16px;
  line-height: 1.25;
  line-height: 48px; }

@media (min-width: 750px) {
  .Button.large {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .Button.large {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .Button.large {
    font-size: 16px; } }

@media print {
  .Button.large {
    font-size: 14px;
    font-size: 0.875rem; } }

.Button.white {
  background-color: #ffffff;
  color: #FF0040;
  box-shadow: inset 0 0 0 2px #FF0040; }

.Button.white:active, html:not(.can-touch) .Button.white:hover {
  background-color: #FF0040;
  color: #ffffff; }

.Link {
  position: relative;
  max-width: 100%;
  transition: color 0.1s linear;
  text-decoration: none;
  border-bottom: 2px solid #FF0040; }

.Link:active, html:not(.can-touch) .Link:hover {
  color: #FF0040; }

a:active .Link, html:not(.can-touch) a:hover .Link {
  color: #FF0040; }

.Link.file {
  position: relative;
  margin-left: 30px;
  line-height: 37px; }

.Link.file:before {
  content: '';
  display: inline-block;
  background: url("img/icons/global/1x--sprite.png") no-repeat -26px -99px;
  width: 18px;
  height: 22px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -30px;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  transition: all 0.2s ease; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .Link.file:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -25px -96px;
    background-size: 159px 137px; } }

.Link.file:hover:before {
  -webkit-transform: translateY(3px) rotate(-10deg) scale(1.05);
  transform: translateY(3px) rotate(-10deg) scale(1.05); }

.List {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px; }

@media (min-width: 750px) {
  .List {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .List {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .List {
    font-size: 21px; } }

@media print {
  .List {
    font-size: 16px;
    font-size: 1rem; } }

@media screen and (max-width: 1000px) {
  .List {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .List {
    margin-bottom: 25px; } }

ul.List:not(.plain) {
  display: block; }

ul.List:not(.plain) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

ul.List:not(.plain) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

ul.List:not(.plain) > li:before {
  content: '\2013'; }

ol.List:not(.plain) {
  display: block;
  counter-reset: ol--counter; }

ol.List:not(.plain) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

ol.List:not(.plain) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

ol.List:not(.plain) > li:before {
  counter-increment: ol--counter;
  content: counter(ol--counter) "."; }

html.ie ol.List:not(.plain) > li {
  list-style: decimal inside none; }

img[data-sizes]:not([width]) {
  width: 100%; }

[data-sizes], .Image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  page-break-inside: avoid; }

.Image {
  z-index: 0;
  position: relative;
  overflow: hidden;
  background-color: #9E9E9E; }

.Image > [data-sizes] {
  background-size: inherit;
  background-repeat: inherit;
  background-position: inherit;
  display: block;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  opacity: 0; }

.Image > [data-sizes], .Image > [data-sizes] > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.Image > [data-sizes] > img {
  display: none; }

.Image > .is-loaded {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  transition: opacity 0.5s, -webkit-transform ease-out 0.8s;
  transition: opacity 0.5s, transform ease-out 0.8s;
  transition: opacity 0.5s, transform ease-out 0.8s, -webkit-transform ease-out 0.8s; }

html.printing .Image > [data-sizes] {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  transition: none; }

html.printing .Image > [data-sizes] > img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center; }

.Table {
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 60px; }

.Table.is-validate {
  overflow: auto; }

@media (min-width: 750px) {
  .Table {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .Table {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .Table {
    font-size: 16px; } }

@media print {
  .Table {
    font-size: 14px;
    font-size: 0.875rem; } }

.Table.Table-generous tbody th, .Table.Table-generous tbody td, .Table.Table-generous tfoot th, .Table.Table-generous tfoot td {
  padding-top: 6px;
  padding-top: 0.375rem;
  padding-bottom: 6px;
  padding-bottom: 0.375rem; }

.Table.Table-even table {
  table-layout: fixed; }

.Table.Table-inline {
  margin-bottom: 0; }

.Table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: 1px solid #F4F5F6;
  margin-bottom: 0; }

.Table table thead > tr {
  font-size: 18px;
  line-height: 1.35; }

@media (min-width: 750px) {
  .Table table thead > tr {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .Table table thead > tr {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .Table table thead > tr {
    font-size: 21px; } }

@media print {
  .Table table thead > tr {
    font-size: 16px;
    font-size: 1rem; } }

.Table table thead > tr > th, .Table table thead > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.Table table thead > tr:last-child > th, .Table table thead > tr:last-child > td {
  border-bottom: 1px solid #000000; }

.Table table thead > tr > th {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.Table table tbody > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .Table table tbody > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .Table table tbody > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .Table table tbody > tr {
    font-size: 21px; } }

@media print {
  .Table table tbody > tr {
    font-size: 16px;
    font-size: 1rem; } }

.Table table tbody > tr > th, .Table table tbody > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.Table table tbody > tr:first-child > th, .Table table tbody > tr:first-child > td {
  border-top: 1px solid #F4F5F6; }

.Table table tfoot > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .Table table tfoot > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .Table table tfoot > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .Table table tfoot > tr {
    font-size: 21px; } }

@media print {
  .Table table tfoot > tr {
    font-size: 16px;
    font-size: 1rem; } }

.Table table tfoot > tr > th, .Table table tfoot > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.Table table tfoot > tr:first-child > th, .Table table tfoot > tr:first-child > td {
  position: relative; }

.Table table tfoot > tr:first-child > th:before, .Table table tfoot > tr:first-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

.Table table tfoot > tr:last-child > th, .Table table tfoot > tr:last-child > td {
  border-bottom: 1px solid #F4F5F6;
  position: relative; }

.Table table tfoot > tr:last-child > th:before, .Table table tfoot > tr:last-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

.Table caption {
  caption-side: bottom;
  display: table-caption;
  text-align: left;
  margin-top: 20px;
  font-size: 13px;
  line-height: 1.57; }

@media (min-width: 750px) {
  .Table caption {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .Table caption {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .Table caption {
    font-size: 13px; } }

@media print {
  .Table caption {
    font-size: 12px;
    font-size: 0.75rem; } }

.Table .Table--legend {
  text-align: left;
  margin-top: 20px;
  font-size: 13px;
  line-height: 1.57; }

@media (min-width: 750px) {
  .Table .Table--legend {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .Table .Table--legend {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .Table .Table--legend {
    font-size: 13px; } }

@media print {
  .Table .Table--legend {
    font-size: 12px;
    font-size: 0.75rem; } }

.Table thead > tr > th {
  padding-bottom: 6px;
  padding-bottom: 0.375rem;
  padding-top: 6px;
  padding-top: 0.375rem;
  padding-left: 6px;
  padding-left: 0.375rem;
  vertical-align: bottom; }

.Table th, .Table td {
  line-height: 1.4;
  padding: 6px;
  padding: 0.375rem;
  vertical-align: top;
  text-align: left; }

.Table th:first-child, .Table td:first-child {
  padding-left: 0; }

.Table th:last-child, .Table td:last-child {
  padding-right: 0; }

.Table th.align-right, .Table td.align-right {
  text-align: right; }

.Table th.align-center, .Table td.align-center {
  text-align: center; }

.Table th.valign-top, .Table td.valign-top {
  vertical-align: top; }

.Table th.valign-middle, .Table td.valign-middle {
  vertical-align: middle; }

.Table th.valign-bottom, .Table td.valign-bottom {
  vertical-align: bottom; }

.Table .Table--title {
  display: none;
  color: #222222; }

.Table .is-screenreader, .Table .is-screenreader:focus, .Table .is-screenreader:active, .Table .is-screenreader.had-focus {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.Table.has-claustrophobia table, .Table.has-claustrophobia tbody, .Table.has-claustrophobia tfoot, .Table.has-claustrophobia tr, .Table.has-claustrophobia td, .Table.has-claustrophobia th {
  display: block;
  text-align: left; }

.Table.has-claustrophobia thead {
  display: none; }

.Table.has-claustrophobia th, .Table.has-claustrophobia td {
  -webkit-order: 1;
  order: 1;
  border-bottom: 0;
  word-break: break-word; }

.Table.has-claustrophobia td {
  display: table;
  width: 100%;
  margin: 5px 0; }

.Table.has-claustrophobia td .Table--title, .Table.has-claustrophobia td .Table--value {
  display: table-cell;
  vertical-align: top; }

.Table.has-claustrophobia td .Table--title {
  width: 40%; }

.Table.has-claustrophobia td .Table--value {
  text-align: right; }

.Table.has-claustrophobia td.as-notext {
  display: block;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 5px; }

.Table.has-claustrophobia td.as-notext + td.as-notext {
  margin-top: 0; }

.Table.has-claustrophobia tbody > tr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  border-top: 1px solid #CFCFCF;
  padding-bottom: 10px;
  padding-top: 10px; }

.Table.has-claustrophobia tbody > tr > th:first-of-type {
  -webkit-order: 0;
  order: 0;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold;
  color: inherit;
  padding: 6px 0px;
  padding: 0.375rem 0rem; }

.Table.has-claustrophobia tbody > tr > th, .Table.has-claustrophobia tbody > tr > td {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: none; }

.Table.has-claustrophobia tbody > tr > td {
  border-top: 1px solid #F4F5F6; }

.Table.has-claustrophobia tfoot tr:last-child > th, .Table.has-claustrophobia tfoot tr:last-child > td {
  border-bottom: none; }

.Table.has-claustrophobia tfoot tr:last-child > th {
  border-top: 1px solid #CFCFCF; }

.Table.has-claustrophobia.Table-inline td .Table--title, .Table.has-claustrophobia.Table-inline td .Table--value {
  padding-right: 0; }

.Table.has-claustrophobia.Table-inline td .Table--title {
  padding-left: 0; }

.Table.has-claustrophobia.Table-inline td .Table--action {
  padding: 0; }

.Table.has-claustrophobia.Table-inline tbody > tr > th:first-of-type {
  padding-left: 0;
  padding-right: 0; }

.Table.has-claustrophobia .Table--action {
  display: block;
  text-align: left;
  padding: 7px 0; }

.Table.has-claustrophobia .Table--action--icon {
  margin-right: 6px; }

.Table:not(.has-claustrophobia) .Table--action--text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.Table:not(.has-claustrophobia) .js-table--showtable {
  display: none; }

.Table.has-claustrophobia .is-hidden-collapsed {
  display: none !important; }

.Table:not(.has-claustrophobia) .is-visible-collapsed {
  display: none !important; }

/* @include ifEditMode { .Table b { @include strong; } }*/
.BasicInput > label, .BasicInput .FormLegend {
  display: block;
  position: relative;
  margin-bottom: 0.625em; }

.BasicInput input, .BasicInput textarea, .BasicInput .Select > select {
  text-align: left;
  width: 100%;
  background: #ffffff;
  border: 1px solid #000000;
  padding: 11px;
  line-height: 1.3;
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.38; }

@media (min-width: 750px) {
  .BasicInput input, .BasicInput textarea, .BasicInput .Select > select {
    font-size: calc(0.66667vw + 10px); } }

@media (min-width: 1200px) {
  .BasicInput input, .BasicInput textarea, .BasicInput .Select > select {
    font-size: calc(0vw + 18px); } }

@media (min-width: 1382px) {
  .BasicInput input, .BasicInput textarea, .BasicInput .Select > select {
    font-size: 18px; } }

@media print {
  .BasicInput input, .BasicInput textarea, .BasicInput .Select > select {
    font-size: 14px;
    font-size: 0.875rem; } }

.BasicInput input:focus, .BasicInput textarea:focus, .BasicInput .Select > select:focus {
  background: white;
  box-shadow: 0 0 0px 1px #000000;
  transition: box-shadow 0.2s, background-g 0.2s;
  border: 1px solid #000000; }

.BasicInput html.can-hover {
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px; }

.BasicInput .Select > select {
  border-radius: 0;
  border: 1px solid #000000;
  background-color: #ffffff;
  padding-right: 40px; }

.BasicInput.error .FormInputError {
  margin-top: 0.5em;
  color: #FF0040; }

input, select, textarea {
  padding: 5px;
  border: 1px solid #aaa;
  font-size: 16px;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  /* stylelint-disable-next-line selector-no-vendor-prefix */ }

input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #9E9E9E; }

input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder {
  color: #9E9E9E; }

input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #9E9E9E; }

input:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder {
  color: #9E9E9E; }

textarea {
  resize: vertical; }

input[type='number'], input[type='time'] {
  /* stylelint-disable */
  -moz-appearance: textfield;
  -webkit-appearance: none;
  /* stylelint-enable */ }

input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button, input[type='time']::-webkit-outer-spin-button, input[type='time']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='time'] {
  border-radius: 0; }

select {
  width: 100%;
  cursor: pointer; }

label > abbr {
  margin-left: 0.1ex;
  border: none; }

.FormInput {
  display: block;
  margin-bottom: 1.5ex; }

.FormInput > label {
  display: block;
  margin-bottom: 0.2ex; }

legend {
  margin: 1em 0; }

.FormCondition {
  display: none; }

.FormCondition.is-opened {
  display: block; }

.FormCondition--collapsible {
  display: none; }

.FormCondition--collapsible.is-opened {
  display: block; }

.FormRow {
  display: block;
  margin-bottom: 38px;
  margin-bottom: 2.375rem; }

@media screen and (max-width: 750px) {
  .FormRow {
    margin-bottom: 29px;
    margin-bottom: 1.78125rem; } }

.FormWrapper {
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto;
  background-color: #F4F5F6;
  margin-bottom: 80px;
  margin-top: 40px; }

@media screen and (max-width: 856px) {
  .FormWrapper {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .FormWrapper {
    width: calc(100% - 40px); } }

@media screen and (max-width: 1000px) {
  .FormWrapper {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .FormWrapper {
    margin-bottom: 36px; } }

@media screen and (max-width: 1000px) {
  .FormWrapper {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .FormWrapper {
    margin-top: 25px; } }

.FormWrapper--inner {
  padding-left: 40px;
  padding-right: 40px; }

@media screen and (max-width: 750px) {
  .FormWrapper--inner {
    padding-left: 20px;
    padding-right: 20px; } }

.FormWrapper--inner:before, .FormWrapper--inner:after {
  display: block;
  content: '';
  float: none;
  clear: both;
  height: 1px; }

.FormWrapper--inner:before {
  margin-bottom: 40px;
  margin-top: -1px; }

@media screen and (max-width: 1000px) {
  .FormWrapper--inner:before {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .FormWrapper--inner:before {
    margin-bottom: 25px; } }

.FormWrapper--inner:after {
  margin-top: 40px;
  margin-bottom: -1px; }

@media screen and (max-width: 1000px) {
  .FormWrapper--inner:after {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .FormWrapper--inner:after {
    margin-top: 25px; } }

.InputGroup {
  display: -webkit-flex;
  display: flex; }

.InputGroup:before, .InputGroup:after {
  content: "";
  display: table; }

.InputGroup:after {
  clear: both; }

.InputGroup > * {
  float: left;
  width: 50%; }

.Select {
  position: relative; }

.Select:after {
  content: '';
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  content: '';
  position: absolute;
  top: 50%;
  border-style: solid;
  border-width: 2px 2px 0 0;
  -webkit-transform: translateY(-0.459em) translateX(-0.3em) scaleY(0.75) rotate(135deg);
  transform: translateY(-0.459em) translateX(-0.3em) scaleY(0.75) rotate(135deg);
  top: 50%;
  right: 15px;
  pointer-events: none; }

.Select > select {
  outline: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 1px solid #000000;
  background-color: #ffffff;
  padding-right: 40px;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  /* stylelint-disable-next-line selector-no-vendor-prefix */ }

.Select > select:focus {
  outline: 0; }

.Select > select::-ms-expand {
  visibility: hidden; }

.Select > select:focus::-ms-value {
  color: inherit;
  background-color: inherit; }

.OptionInput {
  display: block;
  position: relative; }

.OptionInput > input {
  position: absolute;
  opacity: 0; }

.OptionInput > input[disabled] + label {
  cursor: default; }

.OptionInput > label {
  display: block;
  min-height: calc(1.5rem - 2px);
  padding-left: calc(1.5rem + 1.25ex);
  padding-top: 1px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  -webkit-tap-highlight-color: transparent; }

.OptionInput > label:after, .OptionInput > label:before {
  content: '';
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(1em - 2px);
  height: calc(1em - 2px);
  border: 1px solid; }

.OptionInput > input[type='checkbox'] + label:after {
  pointer-events: none;
  opacity: 0;
  top: calc(0.5em - 4px);
  left: calc(0.5em - 6px);
  width: 10px;
  height: 3px;
  border-width: 0 0 2px 2px;
  background: transparent;
  -webkit-transform: rotate(-45deg) scale(5);
  transform: rotate(-45deg) scale(5); }

.OptionInput > input[type='checkbox']:not([disabled]) + label:active:before {
  border-width: 2px;
  width: calc(1em - 4px);
  height: calc(1em - 4px);
  transition: color 0.4s, background 0.4s; }

.OptionInput > input[type='checkbox']:indeterminate + label:before, .OptionInput > input[type='checkbox']:checked + label:before {
  background: white;
  width: calc(1em - 4px);
  height: calc(1em - 4px);
  border-width: 2px;
  transition: color 0.4s, background 0.4s; }

.OptionInput > input[type='checkbox']:indeterminate + label:after, .OptionInput > input[type='checkbox']:checked + label:after {
  opacity: 1;
  background: transparent;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: opacity 0.05s linear, -webkit-transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.05s linear;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.05s linear, -webkit-transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.OptionInput > input[type='radio'] + label:before {
  border-radius: 100%; }

.OptionInput > input[type='radio'] + label:after {
  opacity: 0;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  background: currentColor;
  border-width: 0.5em;
  width: 0;
  height: 0;
  border-radius: 100%; }

.OptionInput > input[type='radio']:not([disabled]) + label:active:before {
  border-width: 2px;
  width: calc(1em - 4px);
  height: calc(1em - 4px);
  transition: color 0.4s, background 0.4s; }

.OptionInput > input[type='radio']:checked + label:before {
  background: white;
  width: calc(1em - 4px);
  height: calc(1em - 4px);
  border-width: 2px;
  transition: color 0.4s, background 0.4s; }

.OptionInput > input[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  transition: -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.2, 5.275);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.2, 5.275);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.2, 5.275), -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.2, 5.275); }

@media screen and (min-width: 751px) {
  .OptionInputGroup.small .OptionInput > label, .OptionInput.small > label {
    padding-left: calc(24px + 1.25ex); }
  .OptionInputGroup.small .OptionInput > label:after, .OptionInputGroup.small .OptionInput > label:before, .OptionInput.small > label:after, .OptionInput.small > label:before {
    font-size: 24px; } }

.OptionInputGroup.picto .OptionInput > label:before, .OptionInputGroup.picto .OptionInput > label:after, .OptionInput.picto > label:before, .OptionInput.picto > label:after {
  content: none; }

.OptionInputGroup.picto .OptionInput > label .Icon, .OptionInput.picto > label .Icon {
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(1em - 2px);
  height: calc(1em - 2px); }

.OptionInputGroup .OptionInput {
  display: block;
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.OptionInputGroup.inline .OptionInput {
  display: inline-block;
  margin-right: 1.5em; }

.OptionInputGroup.toggle {
  position: relative; }

.OptionInputGroup.toggle .OptionInput {
  position: static; }

.OptionInputGroup.toggle .OptionInput:first-of-type > label > span {
  left: 0;
  padding-right: 5px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.OptionInputGroup.toggle .OptionInput:first-of-type > label > span:before {
  content: '';
  display: inline-block; }

.OptionInputGroup.toggle .OptionInput:first-of-type > label::after {
  left: 2px;
  right: auto;
  -webkit-transform: translateX(80%);
  transform: translateX(80%); }

.OptionInputGroup.toggle .OptionInput:first-of-type > input:checked + label::after {
  -webkit-transform: none;
  transform: none; }

.OptionInputGroup.toggle .OptionInput > input:checked + label {
  z-index: 0;
  background-color: #ffffff; }

.OptionInputGroup.toggle .OptionInput > input:checked + label:after {
  border: 2px solid #000000;
  -webkit-transform: none;
  transform: none;
  transition: all ease-out 200ms;
  opacity: 1; }

.OptionInputGroup.toggle .OptionInput > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 52px;
  padding-left: 0;
  z-index: 1;
  border: 1px solid #000000;
  border-radius: 50px; }

.OptionInputGroup.toggle .OptionInput > label > span {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  left: 100%;
  padding-left: 5px; }

.OptionInputGroup.toggle .OptionInput > label:before {
  content: none; }

.OptionInputGroup.toggle .OptionInput > label:after {
  top: 2px;
  right: 2px;
  left: auto;
  height: 22px;
  width: 22px;
  background-color: #ffffff;
  border-radius: 100%;
  border: 2px solid #000000;
  -webkit-transform: translateX(-80%);
  transform: translateX(-80%);
  transition: all ease-out 200ms; }

.l-FlexGrid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  page-break-inside: avoid;
  margin-right: -40px;
  margin-top: -40px; }

.l-FlexGrid > * {
  float: left;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  /* stylelint-disable */
  width: 33.33333%;
  width: calc(100% / 3);
  /* stylelint-enable */
  margin-top: 40px;
  padding-right: 40px; }

.l-FlexGrid:after {
  content: '';
  display: table;
  clear: both; }

.l1of1 {
  width: 100%; }

.l1of2 {
  width: 50%; }

.l1of3 {
  width: 33.33333%; }

.l2of3 {
  width: 66.66667%; }

.l1of4 {
  width: 25%; }

.l3of4 {
  width: 75%; }

@media print {
  body .l1of1-print {
    width: 100%; }
  body .l1of2-print {
    width: 50%; }
  body .l1of3-print {
    width: 33.33333%; }
  body .l2of3-print {
    width: 66.66667%; }
  body .l1of4-print {
    width: 25%; }
  body .l3of4-print {
    width: 75%; } }

@media screen and (max-width: 1200px) {
  .l1of1-tablet {
    width: 100%; }
  .l1of2-tablet {
    width: 50%; }
  .l1of3-tablet {
    width: 33.33333%; }
  .l2of3-tablet {
    width: 66.66667%; }
  .l1of4-tablet {
    width: 25%; }
  .l3of4-tablet {
    width: 75%; } }

@media screen and (max-width: 1000px) {
  .l1of1-tablet-portrait {
    width: 100%; }
  .l1of2-tablet-portrait {
    width: 50%; }
  .l1of3-tablet-portrait {
    width: 33.33333%; }
  .l2of3-tablet-portrait {
    width: 66.66667%; }
  .l1of4-tablet-portrait {
    width: 25%; }
  .l3of4-tablet-portrait {
    width: 75%; } }

@media screen and (max-width: 750px) {
  .l1of1-mobile {
    width: 100%; }
  .l1of2-mobile {
    width: 50%; }
  .l1of3-mobile {
    width: 33.33333%; }
  .l2of3-mobile {
    width: 66.66667%; }
  .l1of4-mobile {
    width: 25%; }
  .l3of4-mobile {
    width: 75%; } }

@media screen and (max-width: 580px) {
  .l1of1-mobile-narrow {
    width: 100%; }
  .l1of2-mobile-narrow {
    width: 50%; }
  .l1of3-mobile-narrow {
    width: 33.33333%; }
  .l2of3-mobile-narrow {
    width: 66.66667%; }
  .l1of4-mobile-narrow {
    width: 25%; }
  .l3of4-mobile-narrow {
    width: 75%; } }

@media screen and (max-width: 400px) {
  .l1of1-mobile-portrait {
    width: 100%; }
  .l1of2-mobile-portrait {
    width: 50%; }
  .l1of3-mobile-portrait {
    width: 33.33333%; }
  .l2of3-mobile-portrait {
    width: 66.66667%; }
  .l1of4-mobile-portrait {
    width: 25%; }
  .l3of4-mobile-portrait {
    width: 75%; } }

.l-FlexGrid.left {
  -webkit-justify-content: flex-start;
  justify-content: flex-start; }

.l-FlexGrid.center {
  -webkit-justify-content: center;
  justify-content: center; }

.l-FlexGrid.right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.l-FlexGrid.nogutter {
  margin-right: 0; }

.l-FlexGrid.nogutter > * {
  padding-right: 0; }

.l-FlexGrid.gutter {
  margin-right: -40px; }

.l-FlexGrid.gutter > * {
  padding-right: 40px; }

.l-FlexGrid.gap {
  margin-top: -40px; }

.l-FlexGrid.gap > * {
  margin-top: 40px; }

.l-FlexGrid.nogap {
  margin-top: 0; }

.l-FlexGrid.nogap > * {
  margin-top: 0; }

.l-LeftRight {
  margin-top: -40px; }

.l-LeftRight:before, .l-LeftRight:after {
  content: "";
  display: table; }

.l-LeftRight:after {
  clear: both; }

.l-LeftRight .l-left {
  display: block;
  float: left;
  margin-top: 40px; }

.l-LeftRight .l-right {
  display: block;
  float: right;
  margin-top: 40px; }

@media screen and (max-width: 1200px) {
  .l-LeftRight.reset-tablet .l-left, .l-LeftRight.reset-tablet .l-right {
    float: none; } }

@media screen and (max-width: 1000px) {
  .l-LeftRight.reset-tablet-portrait .l-left, .l-LeftRight.reset-tablet-portrait .l-right {
    float: none; } }

@media screen and (max-width: 750px) {
  .l-LeftRight.reset-mobile .l-left, .l-LeftRight.reset-mobile .l-right {
    float: none; } }

@media screen and (max-width: 400px) {
  .l-LeftRight.reset-mobile-portrait .l-left, .l-LeftRight.reset-mobile-portrait .l-right {
    float: none; } }

.l-SiteWidth {
  position: relative;
  width: 73.75rem;
  margin: 0 auto; }

@media screen and (max-width: 1340px) {
  .l-SiteWidth {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .l-SiteWidth {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .l-SiteWidth {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .l-SiteWidth {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.l-SteadyGrid {
  margin-right: -40px;
  margin-top: -40px; }

.l-SteadyGrid:after {
  content: "";
  display: table;
  clear: both; }

.l-SteadyGrid > * {
  float: left;
  padding-right: 40px;
  padding-top: 40px;
  width: 50%; }

.l-SteadyGrid > *:nth-child(2n+1) {
  clear: left; }

.l-SteadyGrid.noGutter {
  margin-right: 0; }

.l-SteadyGrid.noGutter > * {
  padding-right: 0; }

.l-SteadyGrid.largeGap {
  margin-top: -80px; }

.l-SteadyGrid.largeGap > * {
  padding-top: 80px; }

.l-SteadyGrid.noGap {
  margin-top: 0; }

.l-SteadyGrid.noGap > * {
  padding-top: 0; }

.l-SteadyGrid.l1 > * {
  width: 100%; }

.l-SteadyGrid.l1 > *:nth-child(1n+1) {
  clear: left; }

.l-SteadyGrid.l2 > * {
  width: 50%; }

.l-SteadyGrid.l2 > *:nth-child(2n+1) {
  clear: left; }

.l-SteadyGrid.l3 > * {
  width: 33.33333%; }

.l-SteadyGrid.l3 > *:nth-child(3n+1) {
  clear: left; }

.l-SteadyGrid.l4 > * {
  width: 25%; }

.l-SteadyGrid.l4 > *:nth-child(4n+1) {
  clear: left; }

@media screen and (max-width: 1200px) {
  .l-SteadyGrid.l1-tablet > * {
    width: 100%; }
  .l-SteadyGrid.l1-tablet > *:nth-child(1n+1) {
    clear: left; }
  .l-SteadyGrid.l2-tablet > * {
    width: 50%; }
  .l-SteadyGrid.l2-tablet > *:nth-child(2n+1) {
    clear: left; }
  .l-SteadyGrid.l3-tablet > * {
    width: 33.33333%; }
  .l-SteadyGrid.l3-tablet > *:nth-child(3n+1) {
    clear: left; }
  .l-SteadyGrid.l4-tablet > * {
    width: 25%; }
  .l-SteadyGrid.l4-tablet > *:nth-child(4n+1) {
    clear: left; } }

@media screen and (max-width: 1000px) {
  .l-SteadyGrid.l1-tablet-portrait > * {
    width: 100%; }
  .l-SteadyGrid.l1-tablet-portrait > *:nth-child(1n+1) {
    clear: left; }
  .l-SteadyGrid.l2-tablet-portrait > * {
    width: 50%; }
  .l-SteadyGrid.l2-tablet-portrait > *:nth-child(2n+1) {
    clear: left; }
  .l-SteadyGrid.l3-tablet-portrait > * {
    width: 33.33333%; }
  .l-SteadyGrid.l3-tablet-portrait > *:nth-child(3n+1) {
    clear: left; }
  .l-SteadyGrid.l4-tablet-portrait > * {
    width: 25%; }
  .l-SteadyGrid.l4-tablet-portrait > *:nth-child(4n+1) {
    clear: left; } }

@media screen and (max-width: 750px) {
  .l-SteadyGrid.l1-mobile > * {
    width: 100%; }
  .l-SteadyGrid.l1-mobile > *:nth-child(1n+1) {
    clear: left; }
  .l-SteadyGrid.l2-mobile > * {
    width: 50%; }
  .l-SteadyGrid.l2-mobile > *:nth-child(2n+1) {
    clear: left; }
  .l-SteadyGrid.l3-mobile > * {
    width: 33.33333%; }
  .l-SteadyGrid.l3-mobile > *:nth-child(3n+1) {
    clear: left; }
  .l-SteadyGrid.l4-mobile > * {
    width: 25%; }
  .l-SteadyGrid.l4-mobile > *:nth-child(4n+1) {
    clear: left; } }

@media screen and (max-width: 580px) {
  .l-SteadyGrid.l1-mobile-narrow > * {
    width: 100%; }
  .l-SteadyGrid.l1-mobile-narrow > *:nth-child(1n+1) {
    clear: left; }
  .l-SteadyGrid.l2-mobile-narrow > * {
    width: 50%; }
  .l-SteadyGrid.l2-mobile-narrow > *:nth-child(2n+1) {
    clear: left; }
  .l-SteadyGrid.l3-mobile-narrow > * {
    width: 33.33333%; }
  .l-SteadyGrid.l3-mobile-narrow > *:nth-child(3n+1) {
    clear: left; }
  .l-SteadyGrid.l4-mobile-narrow > * {
    width: 25%; }
  .l-SteadyGrid.l4-mobile-narrow > *:nth-child(4n+1) {
    clear: left; } }

@media screen and (max-width: 400px) {
  .l-SteadyGrid.l1-mobile-portrait > * {
    width: 100%; }
  .l-SteadyGrid.l1-mobile-portrait > *:nth-child(1n+1) {
    clear: left; }
  .l-SteadyGrid.l2-mobile-portrait > * {
    width: 50%; }
  .l-SteadyGrid.l2-mobile-portrait > *:nth-child(2n+1) {
    clear: left; }
  .l-SteadyGrid.l3-mobile-portrait > * {
    width: 33.33333%; }
  .l-SteadyGrid.l3-mobile-portrait > *:nth-child(3n+1) {
    clear: left; }
  .l-SteadyGrid.l4-mobile-portrait > * {
    width: 25%; }
  .l-SteadyGrid.l4-mobile-portrait > *:nth-child(4n+1) {
    clear: left; } }

.l-Table {
  display: table;
  width: 100%; }

.l-Table.even {
  width: calc(100% + 40px);
  table-layout: fixed; }

.l-Table.even > * {
  width: 1%; }

.l-Table:not(.even) > *:last-child {
  border-right: 0; }

.l-Table.align-top > * {
  vertical-align: top; }

.l-Table.align-bottom > * {
  vertical-align: bottom; }

.l-Table.no-gutter {
  width: 100%; }

.l-Table.no-gutter > * {
  border-right: 0; }

.l-Table.no-gap > * {
  margin-bottom: 0; }

.l-Table > * {
  display: table-cell;
  vertical-align: middle;
  box-sizing: content-box;
  border-right: 40px solid transparent;
  margin-bottom: 40px; }

.l-Table > *.align-left {
  text-align: left; }

.l-Table > *.align-center {
  text-align: center; }

.l-Table > *.align-right {
  text-align: right; }

.l-Table > *.min {
  width: 1%; }

.l-Table.reset {
  display: block;
  width: auto; }

.l-Table.reset > * {
  display: block;
  width: auto;
  text-align: left;
  border-right: 0; }

.l-Table.reset > *:last-child {
  margin-bottom: 0; }

.l-Table.reset > *.min {
  width: auto; }

@media screen and (max-width: 1200px) {
  .l-Table.reset-tablet {
    display: block;
    width: auto; }
  .l-Table.reset-tablet > * {
    display: block;
    width: auto;
    text-align: left;
    border-right: 0; }
  .l-Table.reset-tablet > *:last-child {
    margin-bottom: 0; }
  .l-Table.reset-tablet > *.min {
    width: auto; } }

@media screen and (max-width: 1000px) {
  .l-Table.reset-tablet-portrait {
    display: block;
    width: auto; }
  .l-Table.reset-tablet-portrait > * {
    display: block;
    width: auto;
    text-align: left;
    border-right: 0; }
  .l-Table.reset-tablet-portrait > *:last-child {
    margin-bottom: 0; }
  .l-Table.reset-tablet-portrait > *.min {
    width: auto; } }

@media screen and (max-width: 750px) {
  .l-Table.reset-mobile {
    display: block;
    width: auto; }
  .l-Table.reset-mobile > * {
    display: block;
    width: auto;
    text-align: left;
    border-right: 0; }
  .l-Table.reset-mobile > *:last-child {
    margin-bottom: 0; }
  .l-Table.reset-mobile > *.min {
    width: auto; } }

@media screen and (max-width: 400px) {
  .l-Table.reset-mobile-portrait {
    display: block;
    width: auto; }
  .l-Table.reset-mobile-portrait > * {
    display: block;
    width: auto;
    text-align: left;
    border-right: 0; }
  .l-Table.reset-mobile-portrait > *:last-child {
    margin-bottom: 0; }
  .l-Table.reset-mobile-portrait > *.min {
    width: auto; } }

.ArticleImageGrid--inner {
  font-size: 0;
  margin-bottom: -10px;
  margin-right: -10px; }

.ArticleImageGrid--inner:before, .ArticleImageGrid--inner:after {
  content: "";
  display: table; }

.ArticleImageGrid--inner:after {
  clear: both; }

@media screen and (max-width: 856px) {
  .ArticleImageGrid--inner {
    margin-bottom: -1vw;
    margin-right: -1vw; } }

.ArticleImageGrid--inner .ArticleImageGrid--item {
  width: 50%; }

.ArticleMedia .ArticleImageGrid--inner {
  max-height: 600px;
  overflow: hidden;
  margin-bottom: 0; }

.ArticleImageGrid--item {
  float: left;
  display: inline-block;
  padding-bottom: 10px;
  padding-right: 10px; }

@media screen and (max-width: 856px) {
  .ArticleImageGrid--item {
    padding-bottom: 1vw;
    padding-right: 1vw; } }

.ArticleImageGrid.inverse .ArticleImageGrid--item:nth-child(2) {
  float: right; }

.ArticleLead {
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  line-height: 1.25;
  margin-bottom: 60px;
  margin-top: 60px;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }

@media screen and (max-width: 856px) {
  .ArticleLead {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .ArticleLead {
    width: calc(100% - 40px); } }

@media (min-width: 750px) {
  .ArticleLead {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .ArticleLead {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .ArticleLead {
    font-size: 32px; } }

@media print {
  .ArticleLead {
    font-size: 24px;
    font-size: 1.5rem; } }

@media screen and (max-width: 1200px) {
  .ArticleLead {
    line-height: 1.4; } }

@media screen and (max-width: 1000px) {
  .ArticleLead {
    margin-bottom: 46px; } }

@media screen and (max-width: 580px) {
  .ArticleLead {
    margin-bottom: 31px; } }

@media screen and (max-width: 1000px) {
  .ArticleLead {
    margin-top: 46px; } }

@media screen and (max-width: 580px) {
  .ArticleLead {
    margin-top: 31px; } }

.ArticleMedia {
  clear: both;
  page-break-inside: avoid; }

.ArticleMedia:not(.subcomponent) {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px; }

@media screen and (max-width: 1060px) {
  .ArticleMedia:not(.subcomponent) {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .ArticleMedia:not(.subcomponent) {
    width: calc(100% - 40px); } }

@media screen and (max-width: 1000px) {
  .ArticleMedia:not(.subcomponent) {
    margin-bottom: 46px; } }

@media screen and (max-width: 580px) {
  .ArticleMedia:not(.subcomponent) {
    margin-bottom: 31px; } }

@media screen and (max-width: 1060px) {
  .ArticleMedia:not(.subcomponent) {
    width: 100%;
    max-width: 776px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px; } }

@media screen and (max-width: 856px) {
  .ArticleMedia:not(.subcomponent) {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .ArticleMedia:not(.subcomponent) {
    width: calc(100% - 40px); } }

@media screen and (max-width: 1000px) {
  .ArticleMedia:not(.subcomponent) {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleMedia:not(.subcomponent) {
    margin-bottom: 25px; } }

.ArticleMedia--media {
  font-size: 0; }

.ArticleMedia--cation {
  font-size: 14px;
  font-size: 0.875rem;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: normal;
  line-height: 1.6;
  margin-top: 10px;
  position: relative;
  text-align: left;
  color: #9E9E9E; }

@media screen and (min-width: 857px) {
  .ArticleMedia--cation {
    width: 100%;
    max-width: 776px;
    margin-left: auto;
    margin-right: auto; } }

html.theme .ArticleMedia--cation {
  opacity: 0.6;
  color: currentColor; }

.ArticleMedia--cation:after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 0;
  display: inline-block;
  height: 0;
  width: 80px;
  border-top: 1px solid currentColor;
  opacity: 0.5; }

.ArticleMedia--Image, .ArticleMedia--Video {
  position: relative; }

.ArticleMedia--Image:not(.subcomponent) .ArticleMedia--media, .ArticleMedia--Video:not(.subcomponent) .ArticleMedia--media {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.ArticleMedia--Image:not(.subcomponent) .ArticleMedia--media:before, .ArticleMedia--Video:not(.subcomponent) .ArticleMedia--media:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #F4F5F6; }

html.theme .ArticleMedia--Image:not(.subcomponent) .ArticleMedia--media:before, html.theme .ArticleMedia--Video:not(.subcomponent) .ArticleMedia--media:before {
  opacity: 0.1;
  background-color: currentColor; }

.ArticleMedia--Image:not(.subcomponent) .ArticleMedia--media > img, .ArticleMedia--Video:not(.subcomponent) .ArticleMedia--media > img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  background-color: inherit; }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .ArticleMedia--Image:not(.subcomponent) .ArticleMedia--media > img, .ArticleMedia--Video:not(.subcomponent) .ArticleMedia--media > img {
    width: auto; } }

.ArticleMedia--Image {
  display: block; }

.ArticleMedia--Image:not(.subcomponent) {
  padding-bottom: 61.22449%;
  height: 0;
  overflow: hidden; }

.ArticleMedia--Image--copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  max-width: 100%;
  cursor: help;
  transition: all 0.2s ease-out;
  font-size: 16px;
  line-height: 1.25;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  padding: 0.4em 0.55em;
  vertical-align: middle; }

@media (min-width: 750px) {
  .ArticleMedia--Image--copyright {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .ArticleMedia--Image--copyright {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .ArticleMedia--Image--copyright {
    font-size: 16px; } }

@media print {
  .ArticleMedia--Image--copyright {
    font-size: 14px;
    font-size: 0.875rem; } }

.ArticleMedia--Image--copyright > span {
  font-size: 13px;
  line-height: 1.57;
  line-height: 1;
  display: inline-block;
  transition: all 0.2s ease-out; }

@media (min-width: 750px) {
  .ArticleMedia--Image--copyright > span {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .ArticleMedia--Image--copyright > span {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .ArticleMedia--Image--copyright > span {
    font-size: 13px; } }

@media print {
  .ArticleMedia--Image--copyright > span {
    font-size: 12px;
    font-size: 0.75rem; } }

.ArticleMedia--Image--copyright:hover {
  padding-right: 2em; }

.ArticleMedia--Image--copyright:hover > span {
  padding-left: 0.5em; }

.ArticleMedia--Image--copyright:not(:hover) {
  background-color: rgba(0, 0, 0, 0.5); }

.ArticleMedia--Image--copyright:not(:hover) > span {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ArticleMedia--Video {
  max-width: 100%; }

.ArticleMedia--Video.is-playing .ArticleMedia--Video--content {
  display: block; }

.ArticleMedia--Video:not(.subcomponent) .ArticleMedia--Image {
  height: auto;
  padding-bottom: 0; }

.ArticleMedia--Video:not(.subcomponent) .ArticleMedia--Image .ArticleMedia--media {
  position: relative;
  background-color: #000000; }

.ArticleMedia--Video--link {
  position: relative;
  display: block; }

.ArticleMedia--Video--link > img {
  display: block;
  width: 100%; }

.ArticleMedia--Video--link:hover .ArticleMedia--Video--play_button:before {
  opacity: 1; }

.ArticleMedia--Video--play_button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block; }

.ArticleMedia--Video--play_button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 70px;
  height: 70px;
  opacity: 0.7;
  background-color: #000000;
  border-radius: 4px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: opacity 350ms cubic-bezier(0.175, 0.885, 0.32, 1.6);
  box-shadow: 0px 0px 50px 0px rgba(255, 255, 255, 0.5); }

.ArticleMedia--Video--play_button:after {
  content: '';
  background: url("img/icons/global/1x--sprite.png") no-repeat -78px -47px;
  width: 16px;
  height: 20px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .ArticleMedia--Video--play_button:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -118px -75px;
    background-size: 159px 137px; } }

.ArticleMedia--Video--content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 5;
  background-color: #000000;
  text-align: center; }

.ArticleMedia--Video--content > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ArticleMetaInfo {
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 40px; }

@media screen and (max-width: 856px) {
  .ArticleMetaInfo {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .ArticleMetaInfo {
    width: calc(100% - 40px); } }

@media screen and (max-width: 1000px) {
  .ArticleMetaInfo {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleMetaInfo {
    margin-bottom: 25px; } }

@media screen and (max-width: 1000px) {
  .ArticleMetaInfo {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleMetaInfo {
    margin-top: 25px; } }

.SectionTitle .ArticleMetaInfo {
  width: 100%;
  margin-top: 0; }

.ArticleMetaInfo--inner {
  padding-bottom: 0.5rem; }

.ArticleMetaInfo--row {
  display: block;
  margin: 0.75em 0; }

.ArticleMetaInfo--content {
  padding-top: 0.5em;
  font-size: 13px;
  color: #9E9E9E; }

@media (min-width: 750px) {
  .ArticleMetaInfo--content {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .ArticleMetaInfo--content {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .ArticleMetaInfo--content {
    font-size: 13px; } }

@media print {
  .ArticleMetaInfo--content {
    font-size: 12px;
    font-size: 0.75rem; } }

html.theme .ArticleMetaInfo--content {
  opacity: 0.6;
  color: currentColor; }

.ArticleMetaInfo--content > em {
  color: #FF0040;
  padding-right: 10px; }

.ArticleNav {
  margin-bottom: 40px;
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto; }

@media screen and (max-width: 1000px) {
  .ArticleNav {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleNav {
    margin-bottom: 25px; } }

@media screen and (max-width: 856px) {
  .ArticleNav {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .ArticleNav {
    width: calc(100% - 40px); } }

@media screen and (min-width: 751px) {
  .ArticleNav--inner {
    display: table;
    border-collapse: collapse;
    width: 100%; } }

.ArticleNav--item {
  page-break-inside: avoid;
  position: relative;
  display: block;
  border: 1px solid #CFCFCF;
  padding: 40px;
  transition: background-color 0.3s;
  /* &.prev { @include mobile { padding-right: $gutter + ico-width(global, arrow-left); @include arrow(left, currentColor); @include hover { @include arrowBase(10px, 3px, 3px, 20px); @include arrowColor($c-red); } } @include above-mobile { padding-left: 2 * $gutter + ico-width(global, arrow-left); @include arrow(left, currentColor); @include hover { &:after { @include ico-global-arrow-left-red(left, middle); left: $gutter; } } } } &.next { padding-right: 2 * $gutter + ico-width(global, arrow-left); @include arrow(right, currentColor); @include hover { @include arrowBase(10px, 3px, 3px, 20px); @include arrowColor($c-red); } @include mobile { padding-right: $gutter + ico-width(global, arrow-left); &:after { right: $gutter / 2; } @include hover { &:after { right: $gutter / 2; } } } } */ }

@media screen and (min-width: 751px) {
  .ArticleNav--item {
    display: table-cell;
    width: 50%; } }

@media screen and (max-width: 750px) {
  .ArticleNav--item {
    padding: 20px; }
  .ArticleNav--item + .ArticleNav--item {
    border-top: 0; } }

.ArticleNav--item:active, html:not(.can-touch) .ArticleNav--item:hover {
  color: #222222;
  background-color: #F4F5F6; }

.ArticleNav--item:active .ArticleNav--icon, html:not(.can-touch) .ArticleNav--item:hover .ArticleNav--icon {
  -webkit-animation: ArticleNav-bounce 0.5s linear both;
  animation: ArticleNav-bounce 0.5s linear both; }

@media screen and (min-width: 751px) {
  .ArticleNav--item.prev {
    padding-left: 80px; } }

@media screen and (max-width: 750px) {
  .ArticleNav--item.prev {
    padding-right: 80px; } }

@media screen and (min-width: 751px) {
  .ArticleNav--item.prev .ArticleNav--icon {
    left: 30px; } }

@media screen and (max-width: 750px) {
  .ArticleNav--item.prev .ArticleNav--icon {
    right: 30px; } }

.ArticleNav--item.prev .ArticleNav--icon--ico {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg); }

.ArticleNav--item.prev .ArticleNav--icon--ico:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px; }

.ArticleNav--item.prev .ArticleNav--icon--ico:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 20px;
  width: 2px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: translateX(50%) rotate(-45deg);
  transform: translateX(50%) rotate(-45deg); }

.ArticleNav--item.prev .ArticleNav--icon--ico:after {
  border-color: currentColor; }

.ArticleNav--item.prev .ArticleNav--icon--ico:before {
  background-color: currentColor; }

.ArticleNav--item.prev:active .ArticleNav--icon--ico, html:not(.can-touch) .ArticleNav--item.prev:hover .ArticleNav--icon--ico {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px; }

.ArticleNav--item.prev:active .ArticleNav--icon--ico:after, html:not(.can-touch) .ArticleNav--item.prev:hover .ArticleNav--icon--ico:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-right-style: solid;
  border-right-width: 3px; }

.ArticleNav--item.prev:active .ArticleNav--icon--ico:before, html:not(.can-touch) .ArticleNav--item.prev:hover .ArticleNav--icon--ico:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 20px;
  width: 3px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: translateX(50%) rotate(-45deg);
  transform: translateX(50%) rotate(-45deg); }

.ArticleNav--item.prev:active .ArticleNav--icon--ico:after, html:not(.can-touch) .ArticleNav--item.prev:hover .ArticleNav--icon--ico:after {
  border-color: #FF0040; }

.ArticleNav--item.prev:active .ArticleNav--icon--ico:before, html:not(.can-touch) .ArticleNav--item.prev:hover .ArticleNav--icon--ico:before {
  background-color: #FF0040; }

.ArticleNav--item.next {
  padding-right: 80px; }

.ArticleNav--item.next .ArticleNav--icon {
  right: 30px; }

.ArticleNav--item.next .ArticleNav--icon--ico {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  -webkit-transform: translateY(-50%) rotate(315deg);
  transform: translateY(-50%) rotate(315deg); }

.ArticleNav--item.next .ArticleNav--icon--ico:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px; }

.ArticleNav--item.next .ArticleNav--icon--ico:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 20px;
  width: 2px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: translateX(50%) rotate(-45deg);
  transform: translateX(50%) rotate(-45deg); }

.ArticleNav--item.next .ArticleNav--icon--ico:after {
  border-color: currentColor; }

.ArticleNav--item.next .ArticleNav--icon--ico:before {
  background-color: currentColor; }

.ArticleNav--item.next:active .ArticleNav--icon--ico, html:not(.can-touch) .ArticleNav--item.next:hover .ArticleNav--icon--ico {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px; }

.ArticleNav--item.next:active .ArticleNav--icon--ico:after, html:not(.can-touch) .ArticleNav--item.next:hover .ArticleNav--icon--ico:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-right-style: solid;
  border-right-width: 3px; }

.ArticleNav--item.next:active .ArticleNav--icon--ico:before, html:not(.can-touch) .ArticleNav--item.next:hover .ArticleNav--icon--ico:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 20px;
  width: 3px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: translateX(50%) rotate(-45deg);
  transform: translateX(50%) rotate(-45deg); }

.ArticleNav--item.next:active .ArticleNav--icon--ico:after, html:not(.can-touch) .ArticleNav--item.next:hover .ArticleNav--icon--ico:after {
  border-color: #FF0040; }

.ArticleNav--item.next:active .ArticleNav--icon--ico:before, html:not(.can-touch) .ArticleNav--item.next:hover .ArticleNav--icon--ico:before {
  background-color: #FF0040; }

.ArticleNav--icon {
  position: absolute;
  top: 50%; }

@-webkit-keyframes ArticleNav-bounce {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); } }

@keyframes ArticleNav-bounce {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); } }

.ArticleNav--item--pretitle, .ArticleNav--item--title {
  display: inline-block;
  width: 100%; }

.ArticleNav--item--pretitle {
  display: inline-block;
  width: auto;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  color: #FF0040;
  max-width: 100%;
  margin-bottom: 4px;
  color: #9E9E9E; }

@media (min-width: 750px) {
  .ArticleNav--item--pretitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .ArticleNav--item--pretitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .ArticleNav--item--pretitle {
    font-size: 13px; } }

@media print {
  .ArticleNav--item--pretitle {
    font-size: 12px;
    font-size: 0.75rem; } }

html.theme .ArticleNav--item--pretitle {
  opacity: 0.6;
  color: currentColor; }

.ArticleNav--item--title {
  font-size: 16px;
  display: inline-block;
  width: 100%; }

@media (min-width: 750px) {
  .ArticleNav--item--title {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .ArticleNav--item--title {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .ArticleNav--item--title {
    font-size: 16px; } }

@media print {
  .ArticleNav--item--title {
    font-size: 14px;
    font-size: 0.875rem; } }

.ArticleSubmenu {
  margin-bottom: 40px; }

@media screen and (max-width: 1000px) {
  .ArticleSubmenu {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleSubmenu {
    margin-bottom: 25px; } }

@media screen and (max-width: 1340px) {
  .ArticleSubmenu {
    display: none; } }

.ArticleSubmenu--item {
  display: block;
  border-top: 1px solid #CFCFCF; }

.ArticleSubmenu--item:last-child {
  border-bottom: 1px solid #CFCFCF; }

.ArticleSubmenu--item--link {
  display: block;
  padding: 20px 10px 20px 0; }

.ArticleSubmenu--item--link.active .ArticleSubmenu--item--title {
  color: #FF0040; }

.ArticleSubmenu--item--link:active .ArticleSubmenu--item--title, html:not(.can-touch) .ArticleSubmenu--item--link:hover .ArticleSubmenu--item--title {
  color: #FF0040; }

.ArticleSubmenu--item--title, .ArticleSubmenu--item--posttitle {
  display: inline-block;
  width: 100%; }

.ArticleSubmenu--item--posttitle {
  font-size: 13px;
  display: inline-block;
  width: 100%;
  color: #9E9E9E;
  margin-top: 0.5em; }

@media (min-width: 750px) {
  .ArticleSubmenu--item--posttitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .ArticleSubmenu--item--posttitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .ArticleSubmenu--item--posttitle {
    font-size: 13px; } }

@media print {
  .ArticleSubmenu--item--posttitle {
    font-size: 12px;
    font-size: 0.75rem; } }

html.theme .ArticleSubmenu--item--posttitle {
  opacity: 0.6;
  color: currentColor; }

.ArticleTeaser.text .ArticleTeaser--inner {
  background-color: #F4F5F6;
  padding: 10px 40px 20px 40px; }

@media screen and (max-width: 750px) {
  .ArticleTeaser.text .ArticleTeaser--inner {
    padding: 20px; } }

.ArticleTeaser:not(.text) .ArticleTeaser--content {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.ArticleTeaser:not(.text) .ArticleTeaser--header--pretitle, .ArticleTeaser:not(.text) .ArticleTeaser--header--title {
  background-color: #ffffff; }

html.theme .ArticleTeaser:not(.text) .ArticleTeaser--header--pretitle, html.theme .ArticleTeaser:not(.text) .ArticleTeaser--header--title {
  background-color: var(--theme-background, var(--t-background, #ffffff)); }

@media screen and (max-width: 750px) {
  html.theme .ArticleTeaser:not(.text) .ArticleTeaser--header--pretitle, html.theme .ArticleTeaser:not(.text) .ArticleTeaser--header--title {
    background-color: transparent; } }

.ArticleTeaser:not(.text) .ArticleTeaser--header--pretitle {
  position: absolute;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  max-width: 80%; }

.ArticleTeaser:not(.text):active .ArticleTeaser--imagecontainer + .ArticleTeaser--content, html:not(.can-touch) .ArticleTeaser:not(.text):hover .ArticleTeaser--imagecontainer + .ArticleTeaser--content {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px); }

@media screen and (min-width: 751px) {
  .ArticleTeaser:not(.text):active .ArticleTeaser--image, html:not(.can-touch) .ArticleTeaser:not(.text):hover .ArticleTeaser--image {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); } }

.ArticleTeaser.full .ArticleTeaser--imagecontainer .ArticleTeaser--image {
  padding-top: 500px; }

@media screen and (max-width: 1060px) {
  .ArticleTeaser.full .ArticleTeaser--imagecontainer .ArticleTeaser--image {
    padding-top: 50%; } }

@media screen and (max-width: 750px) {
  .ArticleTeaser.full .ArticleTeaser--imagecontainer .ArticleTeaser--image {
    padding-top: 60%; } }

@media screen and (max-width: 580px) {
  .ArticleTeaser.full .ArticleTeaser--imagecontainer .ArticleTeaser--image {
    padding-top: 66.66%; } }

@media screen and (max-width: 750px) {
  .ArticleTeaser.full .ArticleTeaser--header--pretitle {
    font-size: 14px;
    font-size: 0.875rem; } }

.ArticleTeaser.full .ArticleTeaser--header--title {
  font-size: 24px;
  display: inline-block;
  width: 100%;
  padding-right: 20%; }

@media (min-width: 750px) {
  .ArticleTeaser.full .ArticleTeaser--header--title {
    font-size: calc(3.11111vw + 0.66667px); } }

@media (min-width: 1200px) {
  .ArticleTeaser.full .ArticleTeaser--header--title {
    font-size: calc(5.49451vw - 27.93407px); } }

@media (min-width: 1382px) {
  .ArticleTeaser.full .ArticleTeaser--header--title {
    font-size: 48px; } }

@media print {
  .ArticleTeaser.full .ArticleTeaser--header--title {
    font-size: 28px;
    font-size: 1.75rem; } }

@media screen and (max-width: 750px) {
  .ArticleTeaser.full .ArticleTeaser--header--title {
    padding-right: 10%; } }

.ArticleTeaser.full:active .ArticleTeaser--imagecontainer + .ArticleTeaser--content, html:not(.can-touch) .ArticleTeaser.full:hover .ArticleTeaser--imagecontainer + .ArticleTeaser--content {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px); }

@media screen and (min-width: 751px) {
  .ArticleTeaser.full:active .ArticleTeaser--image, html:not(.can-touch) .ArticleTeaser.full:hover .ArticleTeaser--image {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); } }

@media screen and (max-width: 1200px) {
  .ArticleTeaser .ArticleTeaser--header--title {
    padding-right: 5%; } }

@media screen and (max-width: 750px) {
  .ArticleTeaser:not(.full):not(.text) {
    position: relative; }
  .ArticleTeaser:not(.full):not(.text):after, .ArticleTeaser:not(.full):not(.text):before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-top: 1px solid #e9e9e9; }
  html.theme .ArticleTeaser:not(.full):not(.text):after, html.theme .ArticleTeaser:not(.full):not(.text):before {
    border-top: 1px solid currentColor;
    opacity: 0.3; }
  .ArticleTeaser:not(.full):not(.text):before {
    bottom: 0;
    top: auto; }
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--inner {
    padding: 20px 0;
    display: table;
    table-layout: fixed; }
  .ArticleTeaser:not(.full):not(.text):before, .ArticleTeaser:not(.full):not(.text):after, .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--inner {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 10px; }
  .ArticleTeaser:not(.full):not(.text) + .ArticleTeaser:not(.full):not(.text) {
    margin-top: -1px; }
  .ArticleTeaser:not(.full):not(.text) + .ArticleTeaser:not(.full):not(.text).has-scrollsighter:after {
    opacity: 0; }
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--imagecontainer {
    display: table-cell;
    width: 150px;
    padding-top: 0%; } }

@media screen and (max-width: 580px) {
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--imagecontainer {
    width: 80px;
    height: 100%; } }

@media screen and (max-width: 750px) and (-ms-high-contrast: active) {
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--imagecontainer .ArticleTeaser--image {
    min-height: 90px; } }

@media screen and (max-width: 750px) {
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--image {
    height: 100%; }
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--content {
    display: table-cell;
    vertical-align: top;
    padding-left: 20px; }
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--header--pretitle {
    background-color: transparent;
    padding-top: 0;
    margin-bottom: 0;
    position: relative;
    -webkit-transform: none;
    transform: none;
    font-size: 13px;
    line-height: 1.57; } }

@media screen and (max-width: 750px) and (min-width: 750px) {
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--header--pretitle {
    font-size: calc(0vw + 13px); } }

@media screen and (max-width: 750px) {
  .ArticleTeaser:not(.full):not(.text) .ArticleTeaser--header--title {
    background-color: transparent;
    padding-top: 0.1em; }
  .ArticleTeaser:not(.full):not(.text):active .ArticleTeaser--inner, html:not(.can-touch) .ArticleTeaser:not(.full):not(.text):hover .ArticleTeaser--inner {
    background-color: #F4F5F6; }
  .ArticleTeaser:not(.full):not(.text):active .ArticleTeaser--imagecontainer + .ArticleTeaser--content, html:not(.can-touch) .ArticleTeaser:not(.full):not(.text):hover .ArticleTeaser--imagecontainer + .ArticleTeaser--content {
    -webkit-transform: none;
    transform: none; }
  html.can-hover.theme .ArticleTeaser:not(.full):not(.text):hover .ArticleTeaser--inner {
    background-color: transparent; }
  html.can-hover.theme .ArticleTeaser:not(.full):not(.text):hover:before {
    content: '';
    opacity: 0.3;
    background-color: currentColor;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; } }

.ArticleTeaser--inner {
  display: block;
  height: 100%; }

.ArticleTeaser--imagecontainer {
  overflow: hidden;
  position: relative; }

.ArticleTeaser--image {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

@media screen and (min-width: 751px) {
  .ArticleTeaser--image {
    padding-top: 61.2245%; } }

.ArticleTeaser--content {
  position: relative;
  z-index: 3; }

.ArticleTeaser--header {
  page-break-inside: avoid;
  margin-bottom: 1em; }

@media screen and (max-width: 750px) {
  .ArticleTeaser--header {
    margin-bottom: 0.5em; } }

.ArticleTeaser--header--pretitle {
  display: inline-block;
  width: auto;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  color: #FF0040;
  max-width: 100%;
  margin-bottom: 4px;
  padding-right: 2em;
  padding-top: 1em; }

@media (min-width: 750px) {
  .ArticleTeaser--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .ArticleTeaser--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .ArticleTeaser--header--pretitle {
    font-size: 16px; } }

@media print {
  .ArticleTeaser--header--pretitle {
    font-size: 14px;
    font-size: 0.875rem; } }

.ArticleTeaser--header--title {
  font-size: 20px;
  display: inline-block;
  width: 100%;
  padding-top: 0.375em;
  position: relative;
  z-index: 1; }

@media (min-width: 750px) {
  .ArticleTeaser--header--title {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .ArticleTeaser--header--title {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .ArticleTeaser--header--title {
    font-size: 32px; } }

@media print {
  .ArticleTeaser--header--title {
    font-size: 24px;
    font-size: 1.5rem; } }

.ArticleTeaser--posttitle {
  font-size: 13px;
  line-height: 1.57;
  color: #9E9E9E; }

@media (min-width: 750px) {
  .ArticleTeaser--posttitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .ArticleTeaser--posttitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .ArticleTeaser--posttitle {
    font-size: 13px; } }

@media print {
  .ArticleTeaser--posttitle {
    font-size: 12px;
    font-size: 0.75rem; } }

@media screen and (max-width: 750px) {
  .ArticleTeaser--posttitle {
    font-size: 12px;
    font-size: 0.75rem; } }

.ArticleTeaser--preview {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 80px;
  margin-bottom: 5rem; }

@media (min-width: 750px) {
  .ArticleTeaser--preview {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ArticleTeaser--preview {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ArticleTeaser--preview {
    font-size: 21px; } }

@media print {
  .ArticleTeaser--preview {
    font-size: 16px;
    font-size: 1rem; } }

.ArticleText {
  margin-bottom: 80px;
  margin-top: 20px;
  position: relative; }

@media screen and (max-width: 1000px) {
  .ArticleText {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .ArticleText {
    margin-bottom: 36px; } }

@media screen and (max-width: 1000px) {
  .ArticleText {
    margin-top: 20px; } }

@media screen and (max-width: 580px) {
  .ArticleText {
    margin-top: 18px; } }

.ArticleText.gray {
  padding-top: 40px;
  padding-bottom: 40px; }

.ArticleText.gray:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  display: block;
  background-color: #F4F5F6;
  width: 980px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

@media screen and (max-width: 1000px) {
  .ArticleText.gray {
    padding-top: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleText.gray {
    padding-top: 25px; } }

@media screen and (max-width: 1000px) {
  .ArticleText.gray {
    padding-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleText.gray {
    padding-bottom: 25px; } }

.ArticleText.large .ArticleText--inner {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .ArticleText.large .ArticleText--inner {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ArticleText.large .ArticleText--inner {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ArticleText.large .ArticleText--inner {
    font-size: 21px; } }

@media print {
  .ArticleText.large .ArticleText--inner {
    font-size: 16px;
    font-size: 1rem; } }

.ArticleText--inner {
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto;
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6;
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
  position: relative;
  z-index: 1; }

@media screen and (max-width: 856px) {
  .ArticleText--inner {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .ArticleText--inner {
    width: calc(100% - 40px); } }

@media (min-width: 750px) {
  .ArticleText--inner {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ArticleText--inner {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ArticleText--inner {
    font-size: 21px; } }

@media print {
  .ArticleText--inner {
    font-size: 16px;
    font-size: 1rem; } }

@media (min-width: 750px) {
  .ArticleText--inner {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ArticleText--inner {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ArticleText--inner {
    font-size: 21px; } }

@media print {
  .ArticleText--inner {
    font-size: 16px;
    font-size: 1rem; } }

.ArticleText--inner h1, .ArticleText--inner h2, .ArticleText--inner h3, .ArticleText--inner h4 {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.ArticleText--inner h2 {
  font-size: 20px;
  line-height: 1.25;
  margin-bottom: 20px;
  margin-top: 60px; }

@media (min-width: 750px) {
  .ArticleText--inner h2 {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .ArticleText--inner h2 {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .ArticleText--inner h2 {
    font-size: 32px; } }

@media print {
  .ArticleText--inner h2 {
    font-size: 24px;
    font-size: 1.5rem; } }

@media screen and (max-width: 1200px) {
  .ArticleText--inner h2 {
    line-height: 1.4; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner h2 {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner h2 {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner h2 {
    margin-top: 46px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner h2 {
    margin-top: 31px; } }

.ArticleText--inner h3 {
  font-size: 18px;
  line-height: 1.35;
  margin-bottom: 20px;
  margin-top: 40px; }

@media (min-width: 750px) {
  .ArticleText--inner h3 {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .ArticleText--inner h3 {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .ArticleText--inner h3 {
    font-size: 21px; } }

@media print {
  .ArticleText--inner h3 {
    font-size: 16px;
    font-size: 1rem; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner h3 {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner h3 {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner h3 {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner h3 {
    margin-top: 25px; } }

.ArticleText--inner h4 {
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 20px;
  margin-top: 40px; }

@media (min-width: 750px) {
  .ArticleText--inner h4 {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .ArticleText--inner h4 {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .ArticleText--inner h4 {
    font-size: 16px; } }

@media print {
  .ArticleText--inner h4 {
    font-size: 14px;
    font-size: 0.875rem; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner h4 {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner h4 {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner h4 {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner h4 {
    margin-top: 25px; } }

.ArticleText--inner pre {
  margin: 1em 0;
  padding: 20px;
  background: #ffffff; }

.ArticleText--inner code {
  background: white; }

.ArticleText--inner pre code, .ArticleText--inner pre pre, .ArticleText--inner code pre {
  background: transparent; }

.ArticleText--inner table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: 1px solid #F4F5F6;
  margin-bottom: 20px;
  margin-top: 20px; }

.ArticleText--inner table thead > tr {
  font-size: 18px;
  line-height: 1.35; }

@media (min-width: 750px) {
  .ArticleText--inner table thead > tr {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .ArticleText--inner table thead > tr {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .ArticleText--inner table thead > tr {
    font-size: 21px; } }

@media print {
  .ArticleText--inner table thead > tr {
    font-size: 16px;
    font-size: 1rem; } }

.ArticleText--inner table thead > tr > th, .ArticleText--inner table thead > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.ArticleText--inner table thead > tr:last-child > th, .ArticleText--inner table thead > tr:last-child > td {
  border-bottom: 1px solid #000000; }

.ArticleText--inner table thead > tr > th {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.ArticleText--inner table tbody > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .ArticleText--inner table tbody > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ArticleText--inner table tbody > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ArticleText--inner table tbody > tr {
    font-size: 21px; } }

@media print {
  .ArticleText--inner table tbody > tr {
    font-size: 16px;
    font-size: 1rem; } }

.ArticleText--inner table tbody > tr > th, .ArticleText--inner table tbody > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.ArticleText--inner table tbody > tr:first-child > th, .ArticleText--inner table tbody > tr:first-child > td {
  border-top: 1px solid #F4F5F6; }

.ArticleText--inner table tfoot > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .ArticleText--inner table tfoot > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ArticleText--inner table tfoot > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ArticleText--inner table tfoot > tr {
    font-size: 21px; } }

@media print {
  .ArticleText--inner table tfoot > tr {
    font-size: 16px;
    font-size: 1rem; } }

.ArticleText--inner table tfoot > tr > th, .ArticleText--inner table tfoot > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.ArticleText--inner table tfoot > tr:first-child > th, .ArticleText--inner table tfoot > tr:first-child > td {
  position: relative; }

.ArticleText--inner table tfoot > tr:first-child > th:before, .ArticleText--inner table tfoot > tr:first-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

.ArticleText--inner table tfoot > tr:last-child > th, .ArticleText--inner table tfoot > tr:last-child > td {
  border-bottom: 1px solid #F4F5F6;
  position: relative; }

.ArticleText--inner table tfoot > tr:last-child > th:before, .ArticleText--inner table tfoot > tr:last-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

@media screen and (max-width: 1000px) {
  .ArticleText--inner table {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner table {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner table {
    margin-top: 20px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner table {
    margin-top: 18px; } }

.ArticleText--inner p {
  margin-top: 1em;
  margin-bottom: 1em; }

.ArticleText--inner ul:not([class]) {
  margin: 1em 0;
  display: block; }

.ArticleText--inner ul:not([class]) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

.ArticleText--inner ul:not([class]) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

.ArticleText--inner ul:not([class]) > li:before {
  content: '\2013'; }

.ArticleText--inner ol:not([class]) {
  margin: 1em 0;
  display: block;
  counter-reset: ol--counter; }

.ArticleText--inner ol:not([class]) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

.ArticleText--inner ol:not([class]) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

.ArticleText--inner ol:not([class]) > li:before {
  counter-increment: ol--counter;
  content: counter(ol--counter) "."; }

html.ie .ArticleText--inner ol:not([class]) > li {
  list-style: decimal inside none; }

.ArticleText--inner i {
  font-style: italic; }

.ArticleText--inner strong {
  font-weight: bold; }

.ArticleText--inner a:not([class]) {
  position: relative;
  max-width: 100%;
  transition: color 0.1s linear;
  text-decoration: none;
  border-bottom: 2px solid #FF0040; }

.ArticleText--inner a:not([class]):active, html:not(.can-touch) .ArticleText--inner a:not([class]):hover {
  color: #FF0040; }

a:active .ArticleText--inner a:not([class]), html:not(.can-touch) a:hover .ArticleText--inner a:not([class]) {
  color: #FF0040; }

@media screen and (max-width: 1000px) {
  .ArticleText--inner {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner {
    margin-bottom: 25px; } }

.ArticleText--inner blockquote, .ArticleText--inner q {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 20px;
  font-size: 24px;
  margin-bottom: 80px;
  margin-top: 80px;
  color: #FF0040; }

@media (min-width: 750px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    font-size: 32px; } }

@media print {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    font-size: 24px;
    font-size: 1.5rem; } }

@media (min-width: 750px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    font-size: calc(3.11111vw + 0.66667px); } }

@media (min-width: 1200px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    font-size: calc(5.49451vw - 27.93407px); } }

@media (min-width: 1382px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    font-size: 48px; } }

@media print {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    font-size: 28px;
    font-size: 1.75rem; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    margin-bottom: 36px; } }

@media screen and (max-width: 1000px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    margin-top: 36px; } }

@media screen and (min-width: 1061px) {
  .ArticleText--inner blockquote, .ArticleText--inner q {
    margin-left: -102px; } }

.ArticleText--inner .Button + .Button {
  margin-left: 20px; }

.CategoryMenu {
  background: #ffffff;
  color: #222222;
  text-align: center;
  overflow: hidden; }

.CategoryMenu .scrollslider {
  min-height: 40px; }

.CategoryMenu .scrollslider--scrollbutton {
  opacity: 0;
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.5s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.5s; }

.CategoryMenu .scrollslider--scrollbutton.at-left {
  -webkit-transform: translateX(100px);
  transform: translateX(100px); }

.CategoryMenu .scrollslider--scrollbutton.at-right {
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px); }

html.loaded .CategoryMenu .scrollslider--scrollbutton {
  -webkit-transform: none;
  transform: none;
  opacity: 1; }

html.loaded .CategoryMenu {
  overflow: initial; }

.CategoryMenu--list {
  white-space: nowrap; }

.CategoryMenu--list--item {
  display: inline-block;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 0;
  will-change: transform; }

html.loaded .CategoryMenu--list--item {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }

html.loaded .CategoryMenu--list--item:nth-child(1) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.2s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.2s; }

html.loaded .CategoryMenu--list--item:nth-child(2) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.25s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.25s; }

html.loaded .CategoryMenu--list--item:nth-child(3) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.3s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.3s; }

html.loaded .CategoryMenu--list--item:nth-child(4) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.35s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.35s; }

html.loaded .CategoryMenu--list--item:nth-child(5) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.4s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.4s; }

html.loaded .CategoryMenu--list--item:nth-child(6) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.45s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.45s; }

html.loaded .CategoryMenu--list--item:nth-child(7) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.5s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.5s; }

html.loaded .CategoryMenu--list--item:nth-child(8) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.55s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.55s; }

html.loaded .CategoryMenu--list--item:nth-child(9) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.6s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.6s; }

html.loaded .CategoryMenu--list--item:nth-child(10) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.65s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.65s; }

html.loaded .CategoryMenu--list--item:nth-child(11) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.7s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.7s; }

html.loaded .CategoryMenu--list--item:nth-child(12) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.75s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.75s; }

html.loaded .CategoryMenu--list--item:nth-child(13) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.8s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.8s; }

html.loaded .CategoryMenu--list--item:nth-child(14) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.85s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.85s; }

html.loaded .CategoryMenu--list--item:nth-child(15) {
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1) 0.9s, opacity 200ms cubic-bezier(0.42, 0, 0.175, 1) 0.9s; }

.CategoryMenu--list--item + .CategoryMenu--list--item {
  border-left: 1px solid rgba(0, 0, 0, 0.3); }

.CategoryMenu--list--link {
  padding: 5px 15px; }

@media screen and (max-width: 750px) {
  .CategoryMenu--list--link {
    padding: 5px 10px; } }

.CategoryMenu--list--link:focus, .CategoryMenu--list--link:hover {
  color: #FF0040; }

.CommentTeaser {
  padding-bottom: 10px;
  margin-bottom: -10px; }

@media screen and (min-width: 751px) {
  .CommentTeaser.full .CommentTeaser--inner {
    width: 100%;
    max-width: 776px;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (min-width: 751px) and (max-width: 856px) {
  .CommentTeaser.full .CommentTeaser--inner {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (min-width: 751px) {
  .CommentTeaser.full.author-image .CommentTeaser--text {
    float: left;
    width: calc(100% - 165px); }
  .CommentTeaser.full.author-image .CommentTeaser--image {
    float: right; } }

.CommentTeaser.author-image .CommentTeaser--quote {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 20px;
  font-weight: bold; }

@media (min-width: 750px) {
  .CommentTeaser.author-image .CommentTeaser--quote {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .CommentTeaser.author-image .CommentTeaser--quote {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .CommentTeaser.author-image .CommentTeaser--quote {
    font-size: 32px; } }

@media print {
  .CommentTeaser.author-image .CommentTeaser--quote {
    font-size: 24px;
    font-size: 1.5rem; } }

.CommentTeaser--inner {
  position: relative;
  display: block;
  border: 6px solid #FF0040;
  padding: 40px 40px 60px; }

.CommentTeaser--inner:before, .CommentTeaser--inner:after {
  content: "";
  display: table; }

.CommentTeaser--inner:after {
  clear: both; }

@media screen and (max-width: 750px) {
  .CommentTeaser--inner {
    border-width: 4px; } }

.CommentTeaser--inner:before {
  content: '';
  position: absolute;
  right: 10px;
  bottom: -14px;
  display: inline-block;
  width: 125px;
  height: 41.01562px;
  z-index: 1;
  background: url("img/graphics/logo_publiceye_raw_red.png") no-repeat center center;
  background-size: 100%; }

@media screen and (max-width: 750px) {
  .CommentTeaser--inner:before {
    width: 93.75px;
    height: 30.76172px;
    bottom: -10.5px; } }

@media screen and (max-width: 750px) {
  .CommentTeaser--inner {
    padding: 20px 20px 40px; } }

.CommentTeaser--text {
  overflow: hidden; }

.CommentTeaser--header {
  margin-bottom: 20px; }

.CommentTeaser--section {
  color: #FF0040; }

.CommentTeaser--author {
  color: #9E9E9E; }

.CommentTeaser--quote {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 20px; }

@media (min-width: 750px) {
  .CommentTeaser--quote {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .CommentTeaser--quote {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .CommentTeaser--quote {
    font-size: 32px; } }

@media print {
  .CommentTeaser--quote {
    font-size: 24px;
    font-size: 1.5rem; } }

blockquote.CommentTeaser--quote:before {
  content: '\201C';
  padding-right: 0.25ex; }

blockquote.CommentTeaser--quote:after {
  content: '\201D';
  padding-left: 0.25ex; }

.CommentTeaser--image {
  max-width: 130px; }

.ContentAccordion {
  margin-bottom: 80px;
  margin-top: 40px; }

@media screen and (max-width: 1000px) {
  .ContentAccordion {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .ContentAccordion {
    margin-bottom: 36px; } }

@media screen and (max-width: 1000px) {
  .ContentAccordion {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .ContentAccordion {
    margin-top: 25px; } }

.ContentAccordion .ContentAccordion--inner:before, .ContentAccordion .accordion--item:after {
  content: '';
  display: block;
  border-top: 1px solid currentColor;
  opacity: 0.3; }

.ContentAccordion--inner {
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto; }

@media screen and (max-width: 856px) {
  .ContentAccordion--inner {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .ContentAccordion--inner {
    width: calc(100% - 40px); } }

.ContentAccordion--Item--Trigger {
  font-size: 18px;
  line-height: 1.35;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  padding: 20px 40px 20px 0;
  transition: color 0.1s ease-out; }

@media (min-width: 750px) {
  .ContentAccordion--Item--Trigger {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .ContentAccordion--Item--Trigger {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .ContentAccordion--Item--Trigger {
    font-size: 21px; } }

@media print {
  .ContentAccordion--Item--Trigger {
    font-size: 16px;
    font-size: 1rem; } }

.accordion--item.is-opened .ContentAccordion--Item--Trigger, html.can-hover .accordion--header:hover .ContentAccordion--Item--Trigger {
  color: #FF0040; }

.accordion--item.is-opened .ContentAccordion--Item--Trigger + .accordion--trigger:before, html.can-hover .accordion--header:hover .ContentAccordion--Item--Trigger + .accordion--trigger:before {
  color: currentColor; }

html.can-hover .accordion--item.is-opened .accordion--header:hover .ContentAccordion--Item--Trigger + .accordion--trigger:before {
  color: #FF0040; }

.ContentAccordion--Item--Trigger + .accordion--trigger:before {
  content: '+';
  display: inline-block;
  line-height: 0px;
  font-size: 50px;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: normal;
  transition: color 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out, color 0.1s ease-out;
  transition: transform 0.1s ease-out, color 0.1s ease-out, -webkit-transform 0.1s ease-out;
  position: absolute;
  top: 50%;
  right: 10px;
  color: #FF0040;
  font-size: 40px; }

.accordion--item.is-opened .ContentAccordion--Item--Trigger + .accordion--trigger:before {
  -webkit-transform: translate(1px, 2px) rotate(135deg);
  transform: translate(1px, 2px) rotate(135deg); }

.ContentAccordion--Item--Content:before {
  display: block;
  content: '';
  float: none;
  clear: both;
  height: 1px;
  margin-bottom: 20px;
  margin-top: -1px; }

@media screen and (max-width: 1000px) {
  .ContentAccordion--Item--Content:before {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ContentAccordion--Item--Content:before {
    margin-bottom: 18px; } }

.CTATeaser {
  margin-bottom: 80px;
  margin-top: 80px; }

@media screen and (max-width: 1000px) {
  .CTATeaser {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .CTATeaser {
    margin-bottom: 36px; } }

@media screen and (max-width: 1000px) {
  .CTATeaser {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .CTATeaser {
    margin-top: 36px; } }

.CTATeaser.no-gutter {
  margin-bottom: 0; }

.CTATeaser.FullImageTeaser {
  height: 50vh;
  max-height: auto;
  min-height: 400px; }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .CTATeaser.FullImageTeaser {
    height: auto; } }

.CTATeaser.FullImageTeaser .CTATeaser--header--title, .CTATeaser.FullImageTeaser .CTATeaser--header--pretitle {
  color: #ffffff; }

.CTATeaser.FullImageTeaser .CTATeaser--content {
  position: relative;
  width: 73.75rem;
  margin: 0 auto; }

@media screen and (max-width: 1340px) {
  .CTATeaser.FullImageTeaser .CTATeaser--content {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .CTATeaser.FullImageTeaser .CTATeaser--content {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .CTATeaser.FullImageTeaser .CTATeaser--content {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .CTATeaser.FullImageTeaser .CTATeaser--content {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.CTATeaser:not(.FullImageTeaser) .CTATeaser--content {
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto; }

@media screen and (max-width: 856px) {
  .CTATeaser:not(.FullImageTeaser) .CTATeaser--content {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .CTATeaser:not(.FullImageTeaser) .CTATeaser--content {
    width: calc(100% - 40px); } }

.CTATeaser .FullImageTeaser--imagecontainer {
  overflow: hidden; }

.CTATeaser .FullImageTeaser--imagecontainer:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -100px;
  bottom: -100px;
  background-color: rgba(0, 0, 0, 0.6); }

.CTATeaser--header {
  page-break-inside: avoid; }

.CTATeaser--header--pretitle {
  display: inline-block;
  width: auto;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  color: #FF0040;
  max-width: 100%;
  margin-bottom: 4px;
  color: currentColor; }

@media (min-width: 750px) {
  .CTATeaser--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .CTATeaser--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .CTATeaser--header--pretitle {
    font-size: 16px; } }

@media print {
  .CTATeaser--header--pretitle {
    font-size: 14px;
    font-size: 0.875rem; } }

.CTATeaser--header--title {
  font-size: 32px;
  display: inline-block;
  width: 100%;
  font-size: 24px;
  max-width: 100%;
  margin-bottom: 1em; }

@media (min-width: 750px) {
  .CTATeaser--header--title {
    font-size: calc(6.22222vw - 14.66667px); } }

@media (min-width: 1200px) {
  .CTATeaser--header--title {
    font-size: calc(0vw + 60px); } }

@media (min-width: 1382px) {
  .CTATeaser--header--title {
    font-size: 60px; } }

@media print {
  .CTATeaser--header--title {
    font-size: 46px;
    font-size: 2.875rem; } }

@media (min-width: 750px) {
  .CTATeaser--header--title {
    font-size: calc(3.11111vw + 0.66667px); } }

@media (min-width: 1200px) {
  .CTATeaser--header--title {
    font-size: calc(5.49451vw - 27.93407px); } }

@media (min-width: 1382px) {
  .CTATeaser--header--title {
    font-size: 48px; } }

@media print {
  .CTATeaser--header--title {
    font-size: 28px;
    font-size: 1.75rem; } }

.CTATeaser--buttons {
  margin-left: -0.5em;
  margin-right: -0.5em;
  margin-bottom: -0.5em; }

.CTATeaser--buttons .CTATeaser--button {
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 0.5em; }

.Filter {
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

@media screen and (max-width: 1000px) {
  .Filter {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .Filter {
    margin-bottom: 25px; } }

@media screen and (max-width: 1000px) {
  .Filter {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .Filter {
    margin-top: 25px; } }

@media screen and (max-width: 856px) {
  .Filter {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .Filter {
    width: calc(100% - 40px); } }

.Filter--inner {
  margin: -10px -20px;
  display: inline-block;
  text-align: left; }

.Filter--section {
  margin: 10px 20px;
  float: left; }

.Filter-Select--label, .Filter-Select--select {
  display: inline-block; }

.Filter-Select--label {
  margin-right: 0.125em; }

.Filter-Select--select.DropDown .DropDown--trigger--label {
  color: #FF0040;
  border-bottom: 2px solid #FF0040; }

.Filter-Link {
  border-bottom: 0; }

.Filter-Link .Icon {
  vertical-align: middle;
  margin-left: 0.125em; }

.SectionTitle .FollowUs {
  margin-top: 5px; }

.FollowUs .SocialLinks {
  margin-top: -6px; }

.FollowUs--label, .FollowUs--content {
  display: inline-block;
  vertical-align: middle; }

.FollowUs--label {
  font-size: 16px;
  line-height: 1.25; }

@media (min-width: 750px) {
  .FollowUs--label {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .FollowUs--label {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .FollowUs--label {
    font-size: 16px; } }

@media print {
  .FollowUs--label {
    font-size: 14px;
    font-size: 0.875rem; } }

.FullImageTeaser {
  clear: both;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  margin-bottom: 120px;
  margin-top: 120px;
  height: 80vh;
  max-height: 100vw;
  page-break-inside: avoid; }

@media screen and (max-width: 1000px) {
  .FullImageTeaser {
    margin-bottom: 66px; } }

@media screen and (max-width: 580px) {
  .FullImageTeaser {
    margin-bottom: 44px; } }

@media screen and (max-width: 1000px) {
  .FullImageTeaser {
    margin-top: 66px; } }

@media screen and (max-width: 580px) {
  .FullImageTeaser {
    margin-top: 44px; } }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .FullImageTeaser {
    padding-top: 160px;
    padding-bottom: 160px; } }

.FullImageTeaser:before, .FullImageTeaser:after {
  display: block;
  content: '';
  float: none;
  clear: both;
  height: 1px; }

.FullImageTeaser:before {
  margin-top: 200px; }

@media screen and (max-width: 1000px) {
  .FullImageTeaser:before {
    margin-top: 85px; } }

@media screen and (max-width: 580px) {
  .FullImageTeaser:before {
    margin-top: 57px; } }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .FullImageTeaser:before {
    margin-top: 0; } }

.FullImageTeaser:after {
  margin-bottom: 200px; }

@media screen and (max-width: 1000px) {
  .FullImageTeaser:after {
    margin-bottom: 85px; } }

@media screen and (max-width: 580px) {
  .FullImageTeaser:after {
    margin-bottom: 57px; } }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .FullImageTeaser:after {
    margin-bottom: 0; } }

.FullImageTeaser--imagecontainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  clip: rect(0, auto, auto, 0);
  overflow: hidden; }

.FullImageTeaser--image {
  background-color: #CFCFCF;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform; }

@media print {
  .FullImageTeaser--image {
    position: absolute;
    background: transparent; } }

.FullImageTeaser--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  position: relative; }

@media screen and (max-width: 1340px) {
  .FullImageTeaser--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .FullImageTeaser--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .FullImageTeaser--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .FullImageTeaser--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.FullImageTeaser--copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  max-width: 100%;
  cursor: help;
  transition: all 0.2s ease-out;
  font-size: 16px;
  line-height: 1.25;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  padding: 0.4em 0.55em;
  vertical-align: middle; }

@media (min-width: 750px) {
  .FullImageTeaser--copyright {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .FullImageTeaser--copyright {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .FullImageTeaser--copyright {
    font-size: 16px; } }

@media print {
  .FullImageTeaser--copyright {
    font-size: 14px;
    font-size: 0.875rem; } }

.FullImageTeaser--copyright > span {
  font-size: 13px;
  line-height: 1.57;
  line-height: 1;
  display: inline-block;
  transition: all 0.2s ease-out; }

@media (min-width: 750px) {
  .FullImageTeaser--copyright > span {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .FullImageTeaser--copyright > span {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .FullImageTeaser--copyright > span {
    font-size: 13px; } }

@media print {
  .FullImageTeaser--copyright > span {
    font-size: 12px;
    font-size: 0.75rem; } }

.FullImageTeaser--copyright:hover {
  padding-right: 2em; }

.FullImageTeaser--copyright:hover > span {
  padding-left: 0.5em; }

.FullImageTeaser--copyright:not(:hover) {
  background-color: rgba(0, 0, 0, 0.5); }

.FullImageTeaser--copyright:not(:hover) > span {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.HeroHeader {
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  min-height: 110vh;
  margin-bottom: 60px; }

@media screen and (max-width: 1000px) {
  .HeroHeader {
    margin-bottom: 46px; } }

@media screen and (max-width: 580px) {
  .HeroHeader {
    margin-bottom: 31px; } }

.HeroHeader.no-gap {
  margin-bottom: 0; }

.HeroHeader:after {
  content: '';
  position: absolute;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
  border: 6px solid #FF0040;
  transition: all 0.2s ease; }

@media screen and (max-width: 750px) {
  .HeroHeader:after {
    border-width: 4px; } }

.HeroHeader.js-heroheader .HeroHeader--image, .HeroHeader.js-heroheader .HeroHeader--inner, .HeroHeader.js-heroheader:after {
  opacity: 0;
  transition: all 0.2s ease; }

.HeroHeader.js-heroheader.is-initialized .HeroHeader--image, .HeroHeader.js-heroheader.is-initialized .HeroHeader--inner, .HeroHeader.js-heroheader.is-initialized:after {
  opacity: 1; }

.HeroHeader--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  position: relative;
  padding: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  z-index: 1;
  transition: all 0.2s ease; }

@media screen and (max-width: 1340px) {
  .HeroHeader--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .HeroHeader--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .HeroHeader--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .HeroHeader--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .HeroHeader--inner {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-right: auto;
    margin-left: auto; } }

.HeroHeader--imagecontainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #000000; }

.HeroHeader--imagecontainer:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.75) 100%); }

.HeroHeader--image {
  background-color: #CFCFCF;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center; }

.HeroHeader--header {
  text-align: center;
  page-break-inside: avoid; }

.HeroHeader--header--pretitle {
  display: inline-block;
  width: auto;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  color: #FF0040;
  max-width: 100%;
  margin-bottom: 4px;
  margin-bottom: 1em; }

@media (min-width: 750px) {
  .HeroHeader--header--pretitle {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .HeroHeader--header--pretitle {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .HeroHeader--header--pretitle {
    font-size: 21px; } }

@media print {
  .HeroHeader--header--pretitle {
    font-size: 16px;
    font-size: 1rem; } }

.HeroHeader--header--title {
  font-size: 32px;
  display: inline-block;
  width: 100%;
  font-size: 32px;
  line-height: 1.17;
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1em; }

@media (min-width: 750px) {
  .HeroHeader--header--title {
    font-size: calc(6.22222vw - 14.66667px); } }

@media (min-width: 1200px) {
  .HeroHeader--header--title {
    font-size: calc(0vw + 60px); } }

@media (min-width: 1382px) {
  .HeroHeader--header--title {
    font-size: 60px; } }

@media print {
  .HeroHeader--header--title {
    font-size: 46px;
    font-size: 2.875rem; } }

@media (min-width: 750px) {
  .HeroHeader--header--title {
    font-size: calc(6.22222vw - 14.66667px); } }

@media (min-width: 1200px) {
  .HeroHeader--header--title {
    font-size: calc(0vw + 60px); } }

@media (min-width: 1382px) {
  .HeroHeader--header--title {
    font-size: 60px; } }

@media print {
  .HeroHeader--header--title {
    font-size: 46px;
    font-size: 2.875rem; } }

.HeroHeader--scroll {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 13px;
  line-height: 1.57;
  text-align: center;
  color: #ffffff;
  padding-bottom: 15px; }

@media (min-width: 750px) {
  .HeroHeader--scroll {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .HeroHeader--scroll {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .HeroHeader--scroll {
    font-size: 13px; } }

@media print {
  .HeroHeader--scroll {
    font-size: 12px;
    font-size: 0.75rem; } }

@media print {
  .HeroHeader--scroll {
    display: none; } }

.HeroHeader--scroll--label {
  display: block; }

.HeroHeader--scroll--arrow {
  margin-top: 5px;
  position: relative;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  display: block; }

.HeroHeader--scroll--arrow:after {
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  content: '';
  position: absolute;
  top: 50%;
  border-style: solid;
  border-width: 2px 2px 0 0;
  -webkit-transform: translateY(-0.459em) translateX(-0.3em) scaleY(0.75) rotate(135deg);
  transform: translateY(-0.459em) translateX(-0.3em) scaleY(0.75) rotate(135deg);
  left: 50%;
  top: 50%; }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  40% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  40% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px); } }

.HeroTeaser {
  margin-bottom: 80px;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  min-height: 600px; }

@media screen and (max-width: 1000px) {
  .HeroTeaser {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .HeroTeaser {
    margin-bottom: 36px; } }

@media screen and (max-width: 1200px) {
  .HeroTeaser {
    min-height: 500px; } }

@media screen and (max-width: 750px) {
  .HeroTeaser {
    min-height: 400px; } }

.HeroTeaser:after {
  content: '';
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
  border: 6px solid #FF0040; }

@media screen and (max-width: 750px) {
  .HeroTeaser:after {
    border-width: 4px; } }

@media screen and (max-width: 750px) {
  .HeroTeaser:after {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border: 6px solid #FF0040; } }

@media screen and (max-width: 750px) {
  .HeroTeaser:after {
    border-width: 4px; } }

.HeroTeaser:before {
  content: '';
  position: absolute;
  right: 38px;
  bottom: 12px;
  display: inline-block;
  width: 154px;
  height: 50.53125px;
  z-index: 1;
  background: url("img/graphics/logo_publiceye_raw_red.png") no-repeat center center;
  background-size: 100%; }

@media screen and (max-width: 750px) {
  .HeroTeaser:before {
    width: 115.5px;
    height: 37.89844px;
    bottom: 9px; } }

@media screen and (max-width: 750px) {
  .HeroTeaser:before {
    position: absolute;
    right: 25px;
    bottom: 6px;
    display: inline-block;
    width: 125px;
    height: 41.01562px;
    z-index: 1;
    background: url("img/graphics/logo_publiceye_raw_red.png") no-repeat center center;
    background-size: 100%; } }

@media screen and (max-width: 750px) {
  .HeroTeaser:before {
    width: 93.75px;
    height: 30.76172px;
    bottom: 4.5px; } }

@media screen and (min-width: 1201px) {
  .HeroTeaser:hover .HeroTeaser--image {
    -webkit-transform: scale(1.04) rotate(0.5deg);
    transform: scale(1.04) rotate(0.5deg);
    opacity: 0.5; }
  .HeroTeaser:hover .HeroTeaser--CTA {
    background-color: #ffffff;
    color: #FF0040;
    box-shadow: inset 0 0 0 2px #FF0040; }
  .HeroTeaser:hover:after {
    border-width: 8px; } }

.HeroTeaser--inner {
  position: relative;
  padding: 60px;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  z-index: 1;
  transition: all 0.2s ease-out;
  text-align: left; }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .HeroTeaser--inner {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-right: auto;
    margin-left: auto; } }

@media screen and (max-width: 750px) {
  .HeroTeaser--inner {
    padding: 40px 40px 30px 30px; } }

.HeroTeaser--imagecontainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #000000; }

.HeroTeaser--imagecontainer:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.75) 100%); }

.HeroTeaser--image {
  transition: all 5s ease-out, opacity 5s ease-out;
  background-color: #CFCFCF;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center; }

.HeroTeaser--header {
  page-break-inside: avoid;
  text-align: left;
  width: 80%;
  max-width: 630px; }

.HeroTeaser--header--pretitle {
  display: inline-block;
  width: auto;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  color: #FF0040;
  max-width: 100%;
  margin-bottom: 4px; }

@media (min-width: 750px) {
  .HeroTeaser--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .HeroTeaser--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .HeroTeaser--header--pretitle {
    font-size: 16px; } }

@media print {
  .HeroTeaser--header--pretitle {
    font-size: 14px;
    font-size: 0.875rem; } }

@media screen and (max-width: 750px) {
  .HeroTeaser--header--pretitle {
    display: inline-block;
    width: auto;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    white-space: normal;
    color: #FF0040;
    max-width: 100%;
    margin-bottom: 4px; } }

@media screen and (max-width: 750px) and (min-width: 750px) {
  .HeroTeaser--header--pretitle {
    font-size: calc(0vw + 13px); } }

.HeroTeaser--header--title {
  font-size: 24px;
  display: inline-block;
  width: 100%;
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 40px; }

@media (min-width: 750px) {
  .HeroTeaser--header--title {
    font-size: calc(3.11111vw + 0.66667px); } }

@media (min-width: 1200px) {
  .HeroTeaser--header--title {
    font-size: calc(5.49451vw - 27.93407px); } }

@media (min-width: 1382px) {
  .HeroTeaser--header--title {
    font-size: 48px; } }

@media print {
  .HeroTeaser--header--title {
    font-size: 28px;
    font-size: 1.75rem; } }

.ImageText {
  margin-bottom: 40px;
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto; }

@media screen and (max-width: 1000px) {
  .ImageText {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .ImageText {
    margin-bottom: 25px; } }

@media screen and (max-width: 856px) {
  .ImageText {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .ImageText {
    width: calc(100% - 40px); } }

@media screen and (max-width: 580px) {
  .ImageText.grid {
    margin-bottom: 80px; } }

@media screen and (max-width: 580px) {
  .ImageText.grid {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .ImageText.grid {
    margin-bottom: 36px; } }

.ImageText.grid .ImageText--inner {
  display: table; }

.ImageText.grid .ImageText--left, .ImageText.grid .ImageText--right, .ImageText.grid .ImageText--content {
  display: table-cell;
  float: none;
  vertical-align: top; }

@media screen and (max-width: 580px) {
  .ImageText.grid .ImageText--left, .ImageText.grid .ImageText--right, .ImageText.grid .ImageText--content {
    display: block; } }

.ImageText.grid .ImageText--left, .ImageText.grid .ImageText--right {
  width: 204px;
  min-width: 0;
  padding-right: 40px; }

@media screen and (max-width: 750px) {
  .ImageText.grid .ImageText--left, .ImageText.grid .ImageText--right {
    padding-right: 20px; } }

.ImageText--inner .ImageText--left, .ImageText--inner .ImageText--right {
  display: block;
  margin-bottom: 20px; }

@media screen and (min-width: 581px) {
  .ImageText--inner:after {
    content: '';
    display: block;
    clear: left; }
  .ImageText--inner .ImageText--left, .ImageText--inner .ImageText--right {
    min-width: 250px;
    margin-bottom: 10px; }
  .ImageText--inner .ImageText--left {
    margin-right: 40px;
    float: left; }
  .ImageText--inner .ImageText--right {
    margin-left: 40px;
    float: right; } }

@media print {
  .ImageText--inner:after {
    content: '';
    display: block;
    clear: left; }
  .ImageText--inner .ImageText--left, .ImageText--inner .ImageText--right {
    min-width: 250px;
    margin-bottom: 10px; }
  .ImageText--inner .ImageText--left {
    margin-right: 40px;
    float: left; }
  .ImageText--inner .ImageText--right {
    margin-left: 40px;
    float: right; } }

.ImageText--content {
  font-size: 16px;
  line-height: 1.25;
  padding: 0 0 20px 0;
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .ImageText--content {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .ImageText--content {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .ImageText--content {
    font-size: 16px; } }

@media print {
  .ImageText--content {
    font-size: 14px;
    font-size: 0.875rem; } }

@media (min-width: 750px) {
  .ImageText--content {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ImageText--content {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ImageText--content {
    font-size: 21px; } }

@media print {
  .ImageText--content {
    font-size: 16px;
    font-size: 1rem; } }

.ImageText--content h1, .ImageText--content h2, .ImageText--content h3, .ImageText--content h4 {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.ImageText--content h2 {
  font-size: 20px;
  line-height: 1.25;
  margin-bottom: 20px;
  margin-top: 60px; }

@media (min-width: 750px) {
  .ImageText--content h2 {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .ImageText--content h2 {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .ImageText--content h2 {
    font-size: 32px; } }

@media print {
  .ImageText--content h2 {
    font-size: 24px;
    font-size: 1.5rem; } }

@media screen and (max-width: 1200px) {
  .ImageText--content h2 {
    line-height: 1.4; } }

@media screen and (max-width: 1000px) {
  .ImageText--content h2 {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ImageText--content h2 {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .ImageText--content h2 {
    margin-top: 46px; } }

@media screen and (max-width: 580px) {
  .ImageText--content h2 {
    margin-top: 31px; } }

.ImageText--content h3 {
  font-size: 18px;
  line-height: 1.35;
  margin-bottom: 20px;
  margin-top: 40px; }

@media (min-width: 750px) {
  .ImageText--content h3 {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .ImageText--content h3 {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .ImageText--content h3 {
    font-size: 21px; } }

@media print {
  .ImageText--content h3 {
    font-size: 16px;
    font-size: 1rem; } }

@media screen and (max-width: 1000px) {
  .ImageText--content h3 {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ImageText--content h3 {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .ImageText--content h3 {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .ImageText--content h3 {
    margin-top: 25px; } }

.ImageText--content h4 {
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 20px;
  margin-top: 40px; }

@media (min-width: 750px) {
  .ImageText--content h4 {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .ImageText--content h4 {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .ImageText--content h4 {
    font-size: 16px; } }

@media print {
  .ImageText--content h4 {
    font-size: 14px;
    font-size: 0.875rem; } }

@media screen and (max-width: 1000px) {
  .ImageText--content h4 {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ImageText--content h4 {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .ImageText--content h4 {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .ImageText--content h4 {
    margin-top: 25px; } }

.ImageText--content pre {
  margin: 1em 0;
  padding: 20px;
  background: #ffffff; }

.ImageText--content code {
  background: white; }

.ImageText--content pre code, .ImageText--content pre pre, .ImageText--content code pre {
  background: transparent; }

.ImageText--content table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: 1px solid #F4F5F6;
  margin-bottom: 20px;
  margin-top: 20px; }

.ImageText--content table thead > tr {
  font-size: 18px;
  line-height: 1.35; }

@media (min-width: 750px) {
  .ImageText--content table thead > tr {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .ImageText--content table thead > tr {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .ImageText--content table thead > tr {
    font-size: 21px; } }

@media print {
  .ImageText--content table thead > tr {
    font-size: 16px;
    font-size: 1rem; } }

.ImageText--content table thead > tr > th, .ImageText--content table thead > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.ImageText--content table thead > tr:last-child > th, .ImageText--content table thead > tr:last-child > td {
  border-bottom: 1px solid #000000; }

.ImageText--content table thead > tr > th {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.ImageText--content table tbody > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .ImageText--content table tbody > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ImageText--content table tbody > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ImageText--content table tbody > tr {
    font-size: 21px; } }

@media print {
  .ImageText--content table tbody > tr {
    font-size: 16px;
    font-size: 1rem; } }

.ImageText--content table tbody > tr > th, .ImageText--content table tbody > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.ImageText--content table tbody > tr:first-child > th, .ImageText--content table tbody > tr:first-child > td {
  border-top: 1px solid #F4F5F6; }

.ImageText--content table tfoot > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .ImageText--content table tfoot > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .ImageText--content table tfoot > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .ImageText--content table tfoot > tr {
    font-size: 21px; } }

@media print {
  .ImageText--content table tfoot > tr {
    font-size: 16px;
    font-size: 1rem; } }

.ImageText--content table tfoot > tr > th, .ImageText--content table tfoot > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.ImageText--content table tfoot > tr:first-child > th, .ImageText--content table tfoot > tr:first-child > td {
  position: relative; }

.ImageText--content table tfoot > tr:first-child > th:before, .ImageText--content table tfoot > tr:first-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

.ImageText--content table tfoot > tr:last-child > th, .ImageText--content table tfoot > tr:last-child > td {
  border-bottom: 1px solid #F4F5F6;
  position: relative; }

.ImageText--content table tfoot > tr:last-child > th:before, .ImageText--content table tfoot > tr:last-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

@media screen and (max-width: 1000px) {
  .ImageText--content table {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .ImageText--content table {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .ImageText--content table {
    margin-top: 20px; } }

@media screen and (max-width: 580px) {
  .ImageText--content table {
    margin-top: 18px; } }

.ImageText--content p {
  margin-top: 1em;
  margin-bottom: 1em; }

.ImageText--content ul:not([class]) {
  margin: 1em 0;
  display: block; }

.ImageText--content ul:not([class]) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

.ImageText--content ul:not([class]) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

.ImageText--content ul:not([class]) > li:before {
  content: '\2013'; }

.ImageText--content ol:not([class]) {
  margin: 1em 0;
  display: block;
  counter-reset: ol--counter; }

.ImageText--content ol:not([class]) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

.ImageText--content ol:not([class]) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

.ImageText--content ol:not([class]) > li:before {
  counter-increment: ol--counter;
  content: counter(ol--counter) "."; }

html.ie .ImageText--content ol:not([class]) > li {
  list-style: decimal inside none; }

.ImageText--content i {
  font-style: italic; }

.ImageText--content strong {
  font-weight: bold; }

.ImageText--content a:not([class]) {
  position: relative;
  max-width: 100%;
  transition: color 0.1s linear;
  text-decoration: none;
  border-bottom: 2px solid #FF0040; }

.ImageText--content a:not([class]):active, html:not(.can-touch) .ImageText--content a:not([class]):hover {
  color: #FF0040; }

a:active .ImageText--content a:not([class]), html:not(.can-touch) a:hover .ImageText--content a:not([class]) {
  color: #FF0040; }

.ImageText--content > *:first-child {
  margin-top: 0; }

.ImageText--media {
  margin-bottom: 0; }

.MediaGallery {
  margin-bottom: 80px;
  margin-top: 80px;
  overflow: hidden; }

@media screen and (max-width: 1000px) {
  .MediaGallery {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .MediaGallery {
    margin-bottom: 36px; } }

@media screen and (max-width: 1000px) {
  .MediaGallery {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .MediaGallery {
    margin-top: 36px; } }

.MediaGallery .flickity-page-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  text-align: center; }

.MediaGallery .flickity-page-dots .dot {
  display: inline-block;
  cursor: pointer;
  padding: 0 4px; }

.MediaGallery .flickity-page-dots .dot.is-selected:after, html.can-hover .MediaGallery .flickity-page-dots .dot:hover:after {
  background-color: currentColor;
  opacity: 1; }

.MediaGallery .flickity-page-dots .dot:after {
  content: '';
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid currentColor;
  opacity: 0.5;
  background-color: transparent; }

.MediaGallery .ArticleMedia--cation {
  text-align: center; }

.MediaGallery .ArticleMedia--cation:after {
  display: none; }

.MediaGallery .ArticleMedia--media {
  background-color: transparent !important; }

@media print {
  .MediaGallery .flickity-page-dots, .MediaGallery .MediaGallery--nav {
    display: none; } }

.MediaGallery--inner {
  position: relative;
  padding-bottom: 60px; }

.MediaGallery:not(.wide) .MediaGallery--inner {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto; }

@media screen and (max-width: 1060px) {
  .MediaGallery:not(.wide) .MediaGallery--inner {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .MediaGallery:not(.wide) .MediaGallery--inner {
    width: calc(100% - 40px); } }

.MediaGallery.wide .MediaGallery--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto; }

@media screen and (max-width: 1340px) {
  .MediaGallery.wide .MediaGallery--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .MediaGallery.wide .MediaGallery--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .MediaGallery.wide .MediaGallery--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .MediaGallery.wide .MediaGallery--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.MediaGallery--inner:not(.is-slider-ready) .MediaGallery--slide {
  visibility: hidden;
  opacity: 0; }

.MediaGallery--wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden; }

@media print {
  .MediaGallery--wrapper {
    height: auto !important; } }

.MediaGallery--slider {
  display: -webkit-flex;
  display: flex;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.MediaGallery--slide {
  position: relative;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 20px;
  text-align: center;
  width: 100%;
  z-index: 1;
  transition: opacity 0.3s, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s;
  transition: transform 0.3s ease-out, opacity 0.3s, -webkit-transform 0.3s ease-out; }

.MediaGallery--slide > * {
  margin-bottom: 0; }

.MediaGallery--slide.is-before-visible a, .MediaGallery--slide.is-before-visible button, .MediaGallery--slide.is-after-visible a, .MediaGallery--slide.is-after-visible button {
  pointer-events: none; }

.MediaGallery--slide.small {
  width: 33.3%; }

@media screen and (max-width: 1200px) {
  .MediaGallery--slide.small {
    width: 50%; } }

@media screen and (max-width: 750px) {
  .MediaGallery--slide.small {
    width: 100%; } }

@media print {
  .MediaGallery--slider, .MediaGallery--slide {
    display: block !important;
    position: relative !important;
    -webkit-transform: none !important;
    transform: none !important;
    opacity: 1 !important;
    left: 0 !important;
    margin: 40px 0; } }

.MediaGallery.publications .MediaGallery--slide {
  width: 25%;
  margin: 0;
  padding: 0 20px; }

@media screen and (max-width: 1200px) {
  .MediaGallery.publications .MediaGallery--slide {
    width: 33.33%; } }

@media screen and (max-width: 750px) {
  .MediaGallery.publications .MediaGallery--slide {
    width: 50%; } }

@media screen and (max-width: 580px) {
  .MediaGallery.publications .MediaGallery--slide {
    width: 100%; } }

@media print {
  .MediaGallery.publications .MediaGallery--slide {
    display: inline-block !important;
    width: 32%;
    text-align: left;
    float: left;
    margin-bottom: 40px; }
  .MediaGallery.publications .MediaGallery--slide:nth-child(3n - 2) {
    clear: both; } }

.MediaGallery.publications .MediaGallery--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto; }

@media screen and (max-width: 1340px) {
  .MediaGallery.publications .MediaGallery--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .MediaGallery.publications .MediaGallery--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .MediaGallery.publications .MediaGallery--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .MediaGallery.publications .MediaGallery--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.MediaGallery.fancy .MediaGallery--slide {
  opacity: 0; }

.MediaGallery.fancy .MediaGallery--slide.is-visible {
  opacity: 0.75; }

.MediaGallery.fancy .MediaGallery--slide.is-selected {
  opacity: 1; }

.MediaGallery.fancy .MediaGallery--slide.is-visible:not(.is-selected), .MediaGallery.fancy .MediaGallery--slide.is-before-visible, .MediaGallery.fancy .MediaGallery--slide.is-after-visible {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.75; }

.MediaGallery.fancy .MediaGallery--wrapper {
  overflow: visible; }

.MediaGallery--nav {
  position: absolute;
  left: -40px;
  right: -40px;
  top: 0;
  bottom: 0; }

@media screen and (max-width: 1340px) {
  .MediaGallery--nav {
    left: 40px;
    right: 40px; } }

@media screen and (max-width: 1200px) {
  .MediaGallery--nav {
    left: 40px;
    right: 40px;
    top: auto;
    bottom: 0; } }

@media screen and (max-width: 750px) {
  .MediaGallery--nav {
    left: 20px;
    right: 20px; } }

.MediaGallery--prev, .MediaGallery--next {
  position: absolute;
  bottom: 0;
  width: 40px;
  height: 100%;
  min-height: 40px;
  z-index: 2;
  transition: opacity 0.2s ease-out 0.2s; }

@media screen and (min-width: 1201px) {
  .MediaGallery--prev:after, .MediaGallery--next:after {
    z-index: -1; } }

.MediaGallery--inner.is-dragging .MediaGallery--prev, .MediaGallery--inner.is-dragging .MediaGallery--next {
  opacity: 0.25; }

html.can-hover .MediaGallery--prev:hover, html.can-hover .MediaGallery--next:hover {
  -webkit-animation: ArticleNav-bounce 0.5s linear both;
  animation: ArticleNav-bounce 0.5s linear both; }

@keyframes ArticleNav-bounce {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); } }

.MediaGallery--prev {
  left: -8px; }

.MediaGallery--prev .MediaGallery--nav--icon {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg); }

.MediaGallery--prev .MediaGallery--nav--icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px; }

.MediaGallery--prev .MediaGallery--nav--icon:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 20px;
  width: 2px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: translateX(50%) rotate(-45deg);
  transform: translateX(50%) rotate(-45deg); }

.MediaGallery--prev .MediaGallery--nav--icon:after {
  border-color: currentColor; }

.MediaGallery--prev .MediaGallery--nav--icon:before {
  background-color: currentColor; }

html.can-hover .MediaGallery--prev:hover .MediaGallery--nav--icon {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px; }

html.can-hover .MediaGallery--prev:hover .MediaGallery--nav--icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-right-style: solid;
  border-right-width: 3px; }

html.can-hover .MediaGallery--prev:hover .MediaGallery--nav--icon:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 20px;
  width: 3px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: translateX(50%) rotate(-45deg);
  transform: translateX(50%) rotate(-45deg); }

html.can-hover .MediaGallery--prev:hover .MediaGallery--nav--icon:after {
  border-color: #FF0040; }

html.can-hover .MediaGallery--prev:hover .MediaGallery--nav--icon:before {
  background-color: #FF0040; }

.MediaGallery--next {
  right: -8px; }

.MediaGallery--next .MediaGallery--nav--icon {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  -webkit-transform: translateY(-50%) rotate(315deg);
  transform: translateY(-50%) rotate(315deg); }

.MediaGallery--next .MediaGallery--nav--icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px; }

.MediaGallery--next .MediaGallery--nav--icon:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 20px;
  width: 2px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: translateX(50%) rotate(-45deg);
  transform: translateX(50%) rotate(-45deg); }

.MediaGallery--next .MediaGallery--nav--icon:after {
  border-color: currentColor; }

.MediaGallery--next .MediaGallery--nav--icon:before {
  background-color: currentColor; }

html.can-hover .MediaGallery--next:hover .MediaGallery--nav--icon {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px; }

html.can-hover .MediaGallery--next:hover .MediaGallery--nav--icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-right-style: solid;
  border-right-width: 3px; }

html.can-hover .MediaGallery--next:hover .MediaGallery--nav--icon:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 20px;
  width: 3px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: translateX(50%) rotate(-45deg);
  transform: translateX(50%) rotate(-45deg); }

html.can-hover .MediaGallery--next:hover .MediaGallery--nav--icon:after {
  border-color: #FF0040; }

html.can-hover .MediaGallery--next:hover .MediaGallery--nav--icon:before {
  background-color: #FF0040; }

.OnPagePanel {
  margin-bottom: 80px;
  text-align: left;
  /*.accordion_inline--item--outer { &:before { content: ''; background-color: $c-gray-superlight; position: absolute; top: 0; bottom: 0; left: -50vw; right: -50vw; } }*/ }

@media screen and (max-width: 1000px) {
  .OnPagePanel {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .OnPagePanel {
    margin-bottom: 36px; } }

.OnPagePanel .accordion_inline--item {
  width: calc(33.333% - 40px);
  page-break-inside: avoid; }

@media screen and (max-width: 1200px) {
  .OnPagePanel .accordion_inline--item {
    width: calc(50% - 40px); } }

@media screen and (max-width: 750px) {
  .OnPagePanel .accordion_inline--item {
    width: calc(100% - 40px);
    margin-top: -4px; } }

@media print {
  .OnPagePanel .accordion_inline--item {
    width: calc(50% - 40px); } }

.OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser {
  color: #ffffff; }

.OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  opacity: 0; }

.OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser .SectionTeaser--link.Link {
  color: #ffffff;
  border-bottom-color: #ffffff; }

.OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser .SectionTeaser--link.Link, .OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser .SectionTeaser--link.SectionTeaser--link-plus, .OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser .SectionTeaser--link.accordion_inline--item--close {
  color: #ffffff; }

.OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser .SectionTeaser--link.SectionTeaser--link-plus:before, .OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser .SectionTeaser--link.accordion_inline--item--close:before {
  -webkit-transform: translate(1px, 2px) rotate(135deg);
  transform: translate(1px, 2px) rotate(135deg); }

.OnPagePanel .accordion_inline--item.selected .accordion_inline--item--inner .SectionTeaser:after {
  height: 1em;
  width: 1em; }

.OnPagePanel .accordion_inline--item--inner .SectionTeaser {
  width: 100%;
  height: 100%; }

.OnPagePanel .accordion_inline--item--inner .SectionTeaser:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 0;
  -webkit-transform: translateY(50%) translateX(-50%) rotate(135deg);
  transform: translateY(50%) translateX(-50%) rotate(135deg);
  background: #FF0040;
  font-size: 18px;
  transition: all 0.1s ease-out; }

.OnPagePanel--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto; }

@media screen and (max-width: 1340px) {
  .OnPagePanel--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .OnPagePanel--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .OnPagePanel--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .OnPagePanel--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.OnPagePanel--Item--Content {
  position: relative;
  z-index: 1; }

.OnPagePanel--Item--Content:before {
  display: block;
  content: '';
  clear: both;
  height: 1px;
  margin-bottom: 80px;
  margin-top: -1px; }

@media screen and (max-width: 1000px) {
  .OnPagePanel--Item--Content:before {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .OnPagePanel--Item--Content:before {
    margin-bottom: 36px; } }

.OnPagePanel--Item--Content > *:first-child {
  margin-top: 0; }

.OnPagePanel .accordion_inline--item--close {
  display: block;
  margin: 40px auto; }

.OnPagePanel .accordion_inline--item--close:before {
  -webkit-transform: translate(1px, 2px) rotate(135deg);
  transform: translate(1px, 2px) rotate(135deg);
  vertical-align: middle;
  margin-right: 5px; }

.OnPagePanel .accordion_inline--item--close:hover {
  color: #FF0040; }

.OnPagePanel .accordion_inline--item--close .accordion_inline--item--close--label {
  display: block;
  margin-top: 10px;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.25; }

@media (min-width: 750px) {
  .OnPagePanel .accordion_inline--item--close .accordion_inline--item--close--label {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .OnPagePanel .accordion_inline--item--close .accordion_inline--item--close--label {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .OnPagePanel .accordion_inline--item--close .accordion_inline--item--close--label {
    font-size: 16px; } }

@media print {
  .OnPagePanel .accordion_inline--item--close .accordion_inline--item--close--label {
    font-size: 14px;
    font-size: 0.875rem; } }

.Pagination {
  margin-bottom: 120px;
  font-size: 0; }

@media screen and (max-width: 1000px) {
  .Pagination {
    margin-bottom: 66px; } }

@media screen and (max-width: 580px) {
  .Pagination {
    margin-bottom: 44px; } }

.Pagination--list {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  text-align: center;
  margin: -3px; }

@media screen and (max-width: 1340px) {
  .Pagination--list {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .Pagination--list {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .Pagination--list {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .Pagination--list {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.Pagination--list > li {
  display: inline-block;
  margin: 3px;
  font-size: 1rem; }

.Pagination--link {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  background-color: #F4F5F6; }

.Pagination--link:not(.is-active):hover {
  background-color: #CFCFCF; }

.Pagination--link.is-active {
  background-color: #FF0040;
  color: #ffffff; }

.PublicationTeaserList {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  margin-bottom: 120px;
  margin-top: 120px; }

@media screen and (max-width: 1340px) {
  .PublicationTeaserList {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .PublicationTeaserList {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .PublicationTeaserList {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .PublicationTeaserList {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 1000px) {
  .PublicationTeaserList {
    margin-bottom: 66px; } }

@media screen and (max-width: 580px) {
  .PublicationTeaserList {
    margin-bottom: 44px; } }

@media screen and (max-width: 1000px) {
  .PublicationTeaserList {
    margin-top: 66px; } }

@media screen and (max-width: 580px) {
  .PublicationTeaserList {
    margin-top: 44px; } }

.PublicationTeaserList.small-gap {
  margin-bottom: 40px;
  margin-top: 40px; }

@media screen and (max-width: 1000px) {
  .PublicationTeaserList.small-gap {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .PublicationTeaserList.small-gap {
    margin-bottom: 25px; } }

@media screen and (max-width: 1000px) {
  .PublicationTeaserList.small-gap {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .PublicationTeaserList.small-gap {
    margin-top: 25px; } }

.PublicationTeaserList--list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -20px -80px -20px;
  margin-bottom: -80px; }

@media screen and (max-width: 1000px) {
  .PublicationTeaserList--list {
    margin-bottom: -54px; } }

@media screen and (max-width: 580px) {
  .PublicationTeaserList--list {
    margin-bottom: -36px; } }

@media screen and (max-width: 580px) {
  .PublicationTeaserList--list {
    margin: -20px 0; } }

@media print {
  .PublicationTeaserList--list {
    display: block;
    font-size: 0; } }

.PublicationTeaserList--list--item {
  display: block;
  width: 25%;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 0 20px;
  margin-bottom: 80px;
  margin-bottom: 80px; }

@media screen and (max-width: 1000px) {
  .PublicationTeaserList--list--item {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .PublicationTeaserList--list--item {
    margin-bottom: 36px; } }

@media screen and (max-width: 1200px) {
  .PublicationTeaserList--list--item {
    width: 33.33%; } }

@media screen and (max-width: 750px) {
  .PublicationTeaserList--list--item {
    width: 50%; } }

@media screen and (max-width: 580px) {
  .PublicationTeaserList--list--item {
    width: 100%;
    border-top: 1px solid #CFCFCF;
    padding: 20px 0 20px 0;
    margin-bottom: 0; } }

@media print {
  .PublicationTeaserList--list--item {
    display: inline-block;
    width: 33.33%;
    vertical-align: top;
    font-size: 1rem;
    padding: 0 20px;
    margin-bottom: 80px;
    border-top: none; } }

.PublicationTeaserList--list--item > .PublicationTeaser {
  width: 100%; }

.PublicationTeaser {
  display: inline-block;
  text-align: left; }

.MediaGallery--slide .PublicationTeaser {
  padding-top: 5px; }

.PublicationTeaser.center {
  text-align: center; }

.PublicationTeaser.center .PublicationTeaser--header--pretitle {
  padding-right: 0; }

.PublicationImage {
  position: relative;
  font-size: 0;
  display: inline-block;
  background-color: #F4F5F6; }

.PublicationImage.boxed {
  display: block;
  padding: 30px;
  text-align: center; }

.PublicationImage--inner {
  display: inline-block;
  transition: all 0.25s ease-out;
  will-change: transform; }

a.PublicationTeaser:hover .PublicationImage--inner {
  -webkit-transform: rotate(-1deg);
  transform: rotate(-1deg); }

.PublicationTeaserList--list--item:nth-child(odd) a.PublicationTeaser:hover .PublicationImage--inner {
  -webkit-transform: rotate(1deg);
  transform: rotate(1deg); }

a.PublicationTeaser:hover .PublicationImage--inner:after {
  opacity: 1; }

.PublicationImage--inner:after, .PublicationImage--inner:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.PublicationImage--inner:before {
  box-shadow: inset 4px -5px 4px -4px rgba(0, 0, 0, 0.3), inset 0 1px 0 0 rgba(0, 0, 0, 0.2), inset 0 2px 0 0 rgba(255, 255, 255, 0.2); }

.PublicationImage--inner:after {
  background: linear-gradient(to bottom left, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
  box-shadow: -3px 3px 10px 0px rgba(0, 0, 0, 0.25), 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  transition: opacity 0.25s ease-out;
  opacity: 0.5; }

.PublicationTeaser--content {
  position: relative;
  z-index: 3; }

.PublicationTeaser--header {
  page-break-inside: avoid; }

.PublicationTeaser--header--pretitle {
  display: inline-block;
  width: auto;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  color: #FF0040;
  max-width: 100%;
  margin-bottom: 4px;
  padding-right: 2rem;
  padding-top: 1rem; }

@media (min-width: 750px) {
  .PublicationTeaser--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .PublicationTeaser--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .PublicationTeaser--header--pretitle {
    font-size: 16px; } }

@media print {
  .PublicationTeaser--header--pretitle {
    font-size: 14px;
    font-size: 0.875rem; } }

.PublicationTeaser--header--title {
  font-size: 18px;
  display: inline-block;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  position: relative;
  z-index: 1; }

@media (min-width: 750px) {
  .PublicationTeaser--header--title {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .PublicationTeaser--header--title {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .PublicationTeaser--header--title {
    font-size: 21px; } }

@media print {
  .PublicationTeaser--header--title {
    font-size: 16px;
    font-size: 1rem; } }

.PublicationTeaser--posttitle {
  font-size: 13px;
  line-height: 1.57;
  color: #9E9E9E; }

@media (min-width: 750px) {
  .PublicationTeaser--posttitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .PublicationTeaser--posttitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .PublicationTeaser--posttitle {
    font-size: 13px; } }

@media print {
  .PublicationTeaser--posttitle {
    font-size: 12px;
    font-size: 0.75rem; } }

html.theme .PublicationTeaser--posttitle {
  opacity: 0.6;
  color: currentColor; }

.QuickLinks {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  margin-bottom: 120px;
  margin-top: 120px; }

@media screen and (max-width: 1340px) {
  .QuickLinks {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .QuickLinks {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .QuickLinks {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .QuickLinks {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 1000px) {
  .QuickLinks {
    margin-bottom: 66px; } }

@media screen and (max-width: 580px) {
  .QuickLinks {
    margin-bottom: 44px; } }

@media screen and (max-width: 1000px) {
  .QuickLinks {
    margin-top: 66px; } }

@media screen and (max-width: 580px) {
  .QuickLinks {
    margin-top: 44px; } }

.QuickLinks--inner {
  overflow: hidden; }

.QuickLinks--list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -20px -40px; }

@media screen and (max-width: 580px) {
  .QuickLinks--list {
    margin: -20px 0; } }

.QuickLinks--list--item {
  display: block;
  width: 25%;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 5px 20px 0 20px;
  margin-bottom: 40px; }

@media screen and (max-width: 1200px) {
  .QuickLinks--list--item {
    width: 33.33%; } }

@media screen and (max-width: 750px) {
  .QuickLinks--list--item {
    width: 50%; } }

@media screen and (max-width: 580px) {
  .QuickLinks--list--item {
    width: 100%;
    border-left: none;
    padding: 20px 0 20px 0;
    margin-bottom: 0; } }

@media screen and (max-width: 580px) {
  .QuickLink {
    display: table;
    table-layout: fixed;
    width: 100%; }
  .QuickLink .QuickLink--pikto, .QuickLink .QuickLink--content {
    display: table-cell;
    vertical-align: middle; }
  .QuickLink .QuickLink--pikto {
    width: 80px; } }

.QuickLink:hover {
  color: #FF0040; }

.QuickLink--pikto {
  min-height: 80px;
  min-height: 5rem;
  margin-bottom: 0.5em;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }

.QuickLink:hover .QuickLink--pikto {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px); }

@media screen and (max-width: 580px) {
  .QuickLink--pikto {
    min-height: 0;
    text-align: center;
    min-width: 40px;
    min-width: 2.5rem;
    padding-right: 20px; } }

.QuickLink--pikto--img {
  width: 48px; }

.QuickLink--content {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }

.QuickLink:hover .QuickLink--content {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px); }

.QuickLink--title {
  font-size: 18px;
  line-height: 1.35;
  margin-bottom: 0.5em; }

@media (min-width: 750px) {
  .QuickLink--title {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .QuickLink--title {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .QuickLink--title {
    font-size: 21px; } }

@media print {
  .QuickLink--title {
    font-size: 16px;
    font-size: 1rem; } }

.QuickLink--text {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.38;
  color: #9E9E9E;
  color: #666666; }

@media (min-width: 750px) {
  .QuickLink--text {
    font-size: calc(0.66667vw + 10px); } }

@media (min-width: 1200px) {
  .QuickLink--text {
    font-size: calc(0vw + 18px); } }

@media (min-width: 1382px) {
  .QuickLink--text {
    font-size: 18px; } }

@media print {
  .QuickLink--text {
    font-size: 14px;
    font-size: 0.875rem; } }

html.theme .QuickLink--text {
  opacity: 0.6;
  color: currentColor; }

.ReadMoreLink {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 40px;
  text-align: center; }

@media screen and (max-width: 1340px) {
  .ReadMoreLink {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .ReadMoreLink {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .ReadMoreLink {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .ReadMoreLink {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 1000px) {
  .ReadMoreLink {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .ReadMoreLink {
    margin-bottom: 36px; } }

@media screen and (max-width: 1000px) {
  .ReadMoreLink {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .ReadMoreLink {
    margin-top: 25px; } }

.ReadMoreLink.left {
  text-align: left; }

.ReadMoreLink.no-gap, .ReadMoreLink.no-gap-top {
  margin-top: 0; }

.ReadMoreLink.no-gap, .ReadMoreLink.no-gap-bottom {
  margin-bottom: 0; }

.ReadMoreLink--link {
  cursor: pointer; }

.SectionHighlight {
  clear: both;
  margin-bottom: 160px; }

@media screen and (max-width: 1000px) {
  .SectionHighlight {
    margin-bottom: 76px; } }

@media screen and (max-width: 580px) {
  .SectionHighlight {
    margin-bottom: 51px; } }

@media only screen {
  .SectionHighlight.black .SectionHighlight--head {
    padding-bottom: 120px;
    color: #ffffff; } }

@media only screen and (max-width: 750px) {
  .SectionHighlight.black .SectionHighlight--head {
    padding-bottom: 60px; } }

@media only screen {
  .SectionHighlight.black .SectionHighlight--head:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000;
    z-index: 0; }
  .SectionHighlight.black .SectionHighlight--head .SectionHighlight--inner {
    padding: 80px 0 60px 0; } }

@media only screen and (max-width: 750px) {
  .SectionHighlight.black .SectionHighlight--head .SectionHighlight--inner {
    padding: 40px 0 30px 0; } }

@media only screen {
  .SectionHighlight.black .SectionHighlight--body {
    margin-top: -120px; } }

@media only screen and (max-width: 750px) {
  .SectionHighlight.black .SectionHighlight--body {
    margin-top: -60px; } }

@media only screen {
  .SectionHighlight.black .SectionHighlight--body .SectionHighlight--inner *:first-child.ArticleMedia .ArticleMedia--media:before {
    content: '';
    position: absolute;
    display: block;
    left: -50vw;
    right: -50vw;
    top: 0;
    bottom: 50%;
    background-color: #000000;
    z-index: -1; }
  .SectionHighlight.black .SectionHighlight--body .SectionHighlight--inner *:first-child.ArticleMedia .ArticleMedia--Image {
    overflow: visible; } }

.SectionHighlight--inner {
  position: relative; }

.SectionHighlight--head {
  margin-top: 80px;
  position: relative;
  z-index: 1;
  padding-bottom: 40px; }

@media screen and (max-width: 1000px) {
  .SectionHighlight--head {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .SectionHighlight--head {
    margin-top: 36px; } }

.SectionHighlight--head:after {
  content: '';
  display: block;
  margin-top: 40px; }

@media screen and (max-width: 750px) {
  .SectionHighlight--head {
    padding-top: 40px; } }

.SectionHighlight--head .SectionHighlight--inner {
  padding: 40px 0 0 0;
  text-align: center; }

.SectionHighlight--body {
  position: relative;
  z-index: 20; }

.SectionHighlight--body .SectionHighlight--inner {
  text-align: center; }

.SectionHighlight--header {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  page-break-inside: avoid; }

@media screen and (max-width: 1060px) {
  .SectionHighlight--header {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .SectionHighlight--header {
    width: calc(100% - 40px); } }

.SectionHighlight--header--pretitle {
  display: inline-block;
  width: auto;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  color: #FF0040;
  max-width: 100%;
  margin-bottom: 4px;
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 750px) {
  .SectionHighlight--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .SectionHighlight--header--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .SectionHighlight--header--pretitle {
    font-size: 16px; } }

@media print {
  .SectionHighlight--header--pretitle {
    font-size: 14px;
    font-size: 0.875rem; } }

@media screen and (max-width: 856px) {
  .SectionHighlight--header--pretitle {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .SectionHighlight--header--pretitle {
    width: calc(100% - 40px); } }

.SectionHighlight--header--title {
  font-size: 32px;
  display: inline-block;
  width: 100%;
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 750px) {
  .SectionHighlight--header--title {
    font-size: calc(6.22222vw - 14.66667px); } }

@media (min-width: 1200px) {
  .SectionHighlight--header--title {
    font-size: calc(0vw + 60px); } }

@media (min-width: 1382px) {
  .SectionHighlight--header--title {
    font-size: 60px; } }

@media print {
  .SectionHighlight--header--title {
    font-size: 46px;
    font-size: 2.875rem; } }

@media screen and (max-width: 856px) {
  .SectionHighlight--header--title {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .SectionHighlight--header--title {
    width: calc(100% - 40px); } }

.SectionHighlight--lead {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  line-height: 1.208;
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: left;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }

@media screen and (max-width: 1060px) {
  .SectionHighlight--lead {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .SectionHighlight--lead {
    width: calc(100% - 40px); } }

@media (min-width: 750px) {
  .SectionHighlight--lead {
    font-size: calc(3.11111vw + 0.66667px); } }

@media (min-width: 1200px) {
  .SectionHighlight--lead {
    font-size: calc(5.49451vw - 27.93407px); } }

@media (min-width: 1382px) {
  .SectionHighlight--lead {
    font-size: 48px; } }

@media print {
  .SectionHighlight--lead {
    font-size: 28px;
    font-size: 1.75rem; } }

@media screen and (max-width: 1000px) {
  .SectionHighlight--lead {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .SectionHighlight--lead {
    margin-bottom: 25px; } }

@media screen and (max-width: 1000px) {
  .SectionHighlight--lead {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .SectionHighlight--lead {
    margin-top: 25px; } }

.SectionHighlight--text {
  margin-bottom: 40px; }

@media screen and (max-width: 1000px) {
  .SectionHighlight--text {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .SectionHighlight--text {
    margin-bottom: 25px; } }

.SectionHighlight--linkbar {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  text-align: left; }

@media screen and (max-width: 1060px) {
  .SectionHighlight--linkbar {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .SectionHighlight--linkbar {
    width: calc(100% - 40px); } }

.SectionTeaserList {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  margin-bottom: 80px;
  text-align: left; }

@media screen and (max-width: 1340px) {
  .SectionTeaserList {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .SectionTeaserList {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .SectionTeaserList {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .SectionTeaserList {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 1000px) {
  .SectionTeaserList {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .SectionTeaserList {
    margin-bottom: 36px; } }

@media screen and (max-width: 1340px) {
  .SectionTeaserList {
    margin-left: 0;
    margin-right: 0; } }

.SectionTeaserList--list {
  width: calc(100% + 40px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: stretch;
  align-items: stretch; }

.SectionTeaserList--list--item {
  display: inline-block;
  width: calc(33% - 40px);
  margin-right: 40px;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  margin-bottom: 40px; }

@media screen and (max-width: 1340px) {
  .SectionTeaserList--list--item {
    width: calc(50% - 40px); } }

@media screen and (max-width: 750px) {
  .SectionTeaserList--list--item {
    margin-bottom: -4px; } }

@media screen and (max-width: 580px) {
  .SectionTeaserList--list--item {
    width: calc(100% - 40px); } }

.SectionTeaserList--list--item .SectionTeaser {
  display: inline-block;
  width: 100%;
  height: 100%; }

.SectionTeaser {
  color: #FF0040;
  position: relative;
  background-color: #FF0040;
  z-index: 1;
  page-break-inside: avoid; }

.SectionTeaser:before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  background-color: #ffffff;
  z-index: -1;
  transition: opacity 0.15s ease-out, -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, -webkit-transform 0.15s ease-out;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%; }

.SectionTeaser:focus, html.can-hover .SectionTeaser:hover {
  color: #ffffff; }

.SectionTeaser:focus:before, html.can-hover .SectionTeaser:hover:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  opacity: 0; }

.SectionTeaser:focus .SectionTeaser--link.Link, html.can-hover .SectionTeaser:hover .SectionTeaser--link.Link {
  color: #ffffff;
  border-bottom-color: #ffffff; }

.SectionTeaser:focus .SectionTeaser--link.Link, .SectionTeaser:focus .SectionTeaser--link.SectionTeaser--link-plus, .SectionTeaser:focus .OnPagePanel .SectionTeaser--link.accordion_inline--item--close, .OnPagePanel .SectionTeaser:focus .SectionTeaser--link.accordion_inline--item--close, html.can-hover .SectionTeaser:hover .SectionTeaser--link.Link, html.can-hover .SectionTeaser:hover .SectionTeaser--link.SectionTeaser--link-plus, html.can-hover .SectionTeaser:hover .OnPagePanel .SectionTeaser--link.accordion_inline--item--close, .OnPagePanel html.can-hover .SectionTeaser:hover .SectionTeaser--link.accordion_inline--item--close {
  color: #ffffff; }

.SectionTeaser.plus.selected {
  color: #ffffff; }

.SectionTeaser.plus.selected:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  opacity: 0; }

.SectionTeaser.plus.selected .SectionTeaser--link.Link {
  color: #ffffff;
  border-bottom-color: #ffffff; }

.SectionTeaser.plus.selected .SectionTeaser--link.Link, .SectionTeaser.plus.selected .SectionTeaser--link.SectionTeaser--link-plus, .SectionTeaser.plus.selected .OnPagePanel .SectionTeaser--link.accordion_inline--item--close, .OnPagePanel .SectionTeaser.plus.selected .SectionTeaser--link.accordion_inline--item--close {
  color: #ffffff; }

.SectionTeaser.plus.selected .SectionTeaser--link.SectionTeaser--link-plus:before, .SectionTeaser.plus.selected .OnPagePanel .SectionTeaser--link.accordion_inline--item--close:before, .OnPagePanel .SectionTeaser.plus.selected .SectionTeaser--link.accordion_inline--item--close:before {
  -webkit-transform: translate(1px, 2px) rotate(135deg);
  transform: translate(1px, 2px) rotate(135deg); }

@media screen and (max-width: 750px) {
  .SectionTeaser.plus .SectionTeaser--title {
    width: calc(100% - 60px);
    margin-bottom: 0px;
    min-height: 1em; }
  .SectionTeaser.plus .SectionTeaser--link-plus, .SectionTeaser.plus .OnPagePanel .accordion_inline--item--close, .OnPagePanel .SectionTeaser.plus .accordion_inline--item--close {
    right: 30px;
    bottom: auto;
    top: 50%; } }

.SectionTeaser--inner {
  border: 6px solid #FF0040;
  display: block;
  height: 100%;
  padding: 30px; }

@media screen and (max-width: 750px) {
  .SectionTeaser--inner {
    border-width: 4px; } }

.SectionTeaser--title {
  display: block;
  font-size: 20px;
  line-height: 1.25;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  font-weight: bold;
  margin-bottom: 50px;
  min-height: 2.5em; }

@media (min-width: 750px) {
  .SectionTeaser--title {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .SectionTeaser--title {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .SectionTeaser--title {
    font-size: 32px; } }

@media print {
  .SectionTeaser--title {
    font-size: 24px;
    font-size: 1.5rem; } }

@media screen and (max-width: 1200px) {
  .SectionTeaser--title {
    line-height: 1.4; } }

.SectionTeaser--link {
  position: absolute;
  bottom: 30px;
  color: #222222; }

.SectionTeaser--link-plus:before, .OnPagePanel .accordion_inline--item--close:before {
  content: '+';
  display: inline-block;
  line-height: 0px;
  font-size: 50px;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: normal;
  transition: -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  position: relative; }

.SectionTitle {
  page-break-inside: avoid; }

.SectionTitle.center {
  text-align: center; }

.SectionTitle:not(.wide) {
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  margin-top: 120px; }

@media screen and (max-width: 856px) {
  .SectionTitle:not(.wide) {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .SectionTitle:not(.wide) {
    width: calc(100% - 40px); } }

@media screen and (max-width: 1000px) {
  .SectionTitle:not(.wide) {
    margin-bottom: 46px; } }

@media screen and (max-width: 580px) {
  .SectionTitle:not(.wide) {
    margin-bottom: 31px; } }

@media screen and (max-width: 1000px) {
  .SectionTitle:not(.wide) {
    margin-top: 66px; } }

@media screen and (max-width: 580px) {
  .SectionTitle:not(.wide) {
    margin-top: 44px; } }

@media screen and (max-width: 580px) {
  .SectionTitle:not(.wide) {
    margin-top: 60px; } }

.SectionTitle.wide {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 120px; }

@media screen and (max-width: 1340px) {
  .SectionTitle.wide {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .SectionTitle.wide {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .SectionTitle.wide {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .SectionTitle.wide {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 1000px) {
  .SectionTitle.wide {
    margin-bottom: 46px; } }

@media screen and (max-width: 580px) {
  .SectionTitle.wide {
    margin-bottom: 31px; } }

@media screen and (max-width: 1000px) {
  .SectionTitle.wide {
    margin-top: 66px; } }

@media screen and (max-width: 580px) {
  .SectionTitle.wide {
    margin-top: 44px; } }

@media screen and (max-width: 580px) {
  .SectionTitle.wide {
    margin-top: 60px; } }

.SectionTitle.small {
  margin-bottom: 40px;
  margin-top: 80px; }

@media screen and (max-width: 1000px) {
  .SectionTitle.small {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .SectionTitle.small {
    margin-bottom: 25px; } }

@media screen and (max-width: 1000px) {
  .SectionTitle.small {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .SectionTitle.small {
    margin-top: 36px; } }

@media screen and (max-width: 580px) {
  .SectionTitle.small {
    margin-top: 40px; } }

.SectionTitle.small .SectionTitle--title {
  font-size: 24px;
  display: inline-block;
  width: 100%; }

@media (min-width: 750px) {
  .SectionTitle.small .SectionTitle--title {
    font-size: calc(3.11111vw + 0.66667px); } }

@media (min-width: 1200px) {
  .SectionTitle.small .SectionTitle--title {
    font-size: calc(5.49451vw - 27.93407px); } }

@media (min-width: 1382px) {
  .SectionTitle.small .SectionTitle--title {
    font-size: 48px; } }

@media print {
  .SectionTitle.small .SectionTitle--title {
    font-size: 28px;
    font-size: 1.75rem; } }

.SectionTitle.no-gap, .SectionTitle.no-gap-top {
  margin-top: 0; }

.SectionTitle.no-gap, .SectionTitle.no-gap-bottom {
  margin-bottom: 0; }

.SectionTitle > * {
  width: auto; }

.SectionTitle .CategoryMenu {
  margin-top: 20px;
  margin-top: 1.25rem; }

.SectionTitle .ReadMoreLink {
  margin-top: 30px;
  margin-top: 1.875rem; }

.SectionTitle--pretitle {
  display: inline-block;
  width: auto;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: normal;
  color: #FF0040;
  max-width: 100%;
  margin-bottom: 4px; }

@media (min-width: 750px) {
  .SectionTitle--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .SectionTitle--pretitle {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .SectionTitle--pretitle {
    font-size: 16px; } }

@media print {
  .SectionTitle--pretitle {
    font-size: 14px;
    font-size: 0.875rem; } }

.SectionTitle--title {
  font-size: 32px;
  display: inline-block;
  width: 100%;
  color: #222222; }

@media (min-width: 750px) {
  .SectionTitle--title {
    font-size: calc(6.22222vw - 14.66667px); } }

@media (min-width: 1200px) {
  .SectionTitle--title {
    font-size: calc(0vw + 60px); } }

@media (min-width: 1382px) {
  .SectionTitle--title {
    font-size: 60px; } }

@media print {
  .SectionTitle--title {
    font-size: 46px;
    font-size: 2.875rem; } }

.ShareMe {
  color: #FF0040;
  position: relative;
  height: auto !important;
  vertical-align: middle; }

.ShareMe--label, .ShareMe--content, .ShareMe--trigger {
  display: inline-block;
  vertical-align: middle; }

.ShareMe--content {
  font-size: 0;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.2s ease-out; }

.ShareMe--content .SocialLinks--item {
  transition: all 0.2s ease-out 0.2s; }

.ShareMe--content .SocialLinks--item:nth-child(1) {
  transition-delay: 0s; }

.ShareMe--content .SocialLinks--item:nth-child(2) {
  transition-delay: 0.1s; }

.ShareMe--content .SocialLinks--item:nth-child(3) {
  transition-delay: 0.2s; }

.ShareMe--content .SocialLinks--item:nth-child(4) {
  transition-delay: 0.3s; }

.ShareMe:not(.is-opened) .ShareMe--content .SocialLinks--item {
  visibility: hidden;
  width: 0;
  height: 1px;
  font-size: 0; }

.ShareMe:not(.is-opened) .ShareMe--content {
  visibility: hidden;
  overflow: hidden;
  margin: 0;
  width: 0;
  height: 1px; }

.ShareMe--trigger {
  font-size: 0; }

.ShareMe--trigger .ShareMe--trigger--icon {
  position: relative;
  display: inline-block; }

.ShareMe.is-opened .ShareMe--trigger {
  padding: 12.5px;
  font-size: 0;
  margin: -12.5px -12.5px -12.5px -2.5px;
  display: inline-block; }

.ShareMe.is-opened .ShareMe--trigger .ShareMe--trigger--icon {
  width: 15px;
  height: 15px;
  box-sizing: border-box; }

.ShareMe.is-opened .ShareMe--trigger .ShareMe--trigger--icon:before, .ShareMe.is-opened .ShareMe--trigger .ShareMe--trigger--icon:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -10.5px;
  display: block;
  height: 2px;
  width: 21px;
  background-color: currentColor;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out; }

.ShareMe.is-opened .ShareMe--trigger .ShareMe--trigger--icon:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg); }

.ShareMe:not(.is-opened) .ShareMe--trigger {
  padding: 12.5px;
  font-size: 0;
  margin: -12.5px -12.5px -12.5px -2.5px; }

.ShareMe:not(.is-opened) .ShareMe--trigger:before {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  left: 0;
  top: -12.5px;
  bottom: -12.5px; }

@-moz-document url-prefix() {
  .ShareMe:not(.is-opened) .ShareMe--trigger {
    margin-left: -77.5px;
    padding-left: 92.5px; } }

.ShareMe:not(.is-opened) .ShareMe--trigger .ShareMe--trigger--icon:after {
  content: '';
  display: inline-block;
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -72px;
  width: 15px;
  height: 15px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .ShareMe:not(.is-opened) .ShareMe--trigger .ShareMe--trigger--icon:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -68px;
    background-size: 159px 137px; } }

.ShareMe--trigger--labelclose, .ShareMe--trigger--labelopen {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ShareMe.is-opened .ShareMe--trigger--labelclose.ShareMe--trigger--labelopen, .ShareMe.is-opened .ShareMe--trigger--labelopen.ShareMe--trigger--labelopen {
  display: none; }

.ShareMe:not(.is-opened) .ShareMe--trigger--labelclose.ShareMe--trigger--labelclose, .ShareMe:not(.is-opened) .ShareMe--trigger--labelopen.ShareMe--trigger--labelclose {
  display: none; }

.SideContent {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  display: none;
  z-index: 10; }

@media screen and (max-width: 1340px) {
  .SideContent {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .SideContent {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .SideContent {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .SideContent {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (min-width: 1341px) {
  .SideContent {
    display: block; }
  .SideContent.left .SideContent--inner {
    float: left;
    margin-right: 40px; }
  .SideContent.right .SideContent--inner {
    float: right;
    margin-left: 40px; } }

.SideContent--inner {
  width: 100%;
  max-width: 164px;
  margin-left: auto;
  margin-right: auto; }

.SideContent--inner:after {
  content: '';
  display: block;
  margin-bottom: 40px; }

@media screen and (max-width: 1000px) {
  .SideContent--inner:after {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .SideContent--inner:after {
    margin-bottom: 25px; } }

@media screen and (max-width: 244px) {
  .SideContent--inner {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 244px) {
  .SideContent--inner {
    width: calc(100% - 40px); } }

@media screen and (min-width: 1341px) {
  .SideContent--inner {
    width: 100%;
    max-width: 164px;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (max-width: 1340px) {
  .SideContent--inner {
    width: 100%;
    max-width: 776px;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (max-width: 856px) {
  .SideContent--inner {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .SideContent--inner {
    width: calc(100% - 40px); } }

@media screen and (max-width: 1340px) {
  .SideContent--inner:after {
    margin-bottom: 80px; } }

@media screen and (max-width: 1000px) {
  .SideContent--inner:after {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .SideContent--inner:after {
    margin-bottom: 36px; } }

@media screen and (max-width: 856px) {
  .SideContent--inner {
    min-width: 100%;
    margin: 0; } }

.TagList {
  font-size: 0;
  margin: -5px; }

.TagList--item {
  display: inline-block;
  font-size: 1rem;
  margin: 5px; }

.TagList--item--link {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.57;
  padding-left: 13px;
  padding-left: 0.825rem;
  padding-right: 13px;
  padding-right: 0.825rem;
  line-height: 2;
  background-color: #ffffff;
  color: #FF0040;
  border: 1px solid #FF0040; }

@media (min-width: 750px) {
  .TagList--item--link {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .TagList--item--link {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .TagList--item--link {
    font-size: 13px; } }

@media print {
  .TagList--item--link {
    font-size: 12px;
    font-size: 0.75rem; } }

.TagList--item--link:active, html:not(.can-touch) .TagList--item--link:hover {
  background-color: #FF0040;
  color: #ffffff; }

.TeaserGrid {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px; }

@media screen and (max-width: 1060px) {
  .TeaserGrid {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .TeaserGrid {
    width: calc(100% - 40px); } }

@media screen and (max-width: 1000px) {
  .TeaserGrid {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .TeaserGrid {
    margin-bottom: 36px; } }

@media only screen {
  .TeaserGrid:not(.is-opened) .TeaserGrid--inner.is-visible-on-read-more {
    display: none; }
  .TeaserGrid:not(.is-opened) .TeaserGrid--readmore {
    display: block; }
  .TeaserGrid.is-opened .TeaserGrid--inner.is-visible-on-read-more > *:not(.has-scrollsighter).CommentTeaser, .TeaserGrid.is-opened .TeaserGrid--inner.is-visible-on-read-more > *:not(.has-scrollsighter).ArticleTeaser {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
    will-change: transform;
    opacity: 0; } }

.TeaserGrid--inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  page-break-inside: avoid;
  margin-right: -60px;
  margin-top: -60px; }

.TeaserGrid--inner > * {
  float: left;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  /* stylelint-disable */
  width: 50%;
  width: calc(100% / 2);
  /* stylelint-enable */
  margin-top: 60px;
  padding-right: 60px; }

.TeaserGrid--inner:after {
  content: '';
  display: table;
  clear: both; }

.TeaserGrid--inner > *.full {
  width: 100%; }

.TeaserGrid--inner + .TeaserGrid--inner {
  margin-top: 0; }

@media screen and (max-width: 1200px) {
  .TeaserGrid--inner {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    page-break-inside: avoid;
    margin-right: -60px;
    margin-top: -60px; }
  .TeaserGrid--inner > * {
    float: left;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    /* stylelint-disable */
    width: 50%;
    width: calc(100% / 2);
    /* stylelint-enable */
    margin-top: 60px;
    padding-right: 60px; }
  .TeaserGrid--inner:after {
    content: '';
    display: table;
    clear: both; } }

@media screen and (max-width: 750px) {
  .TeaserGrid--inner {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    page-break-inside: avoid;
    margin-right: 0px;
    margin-top: -20px;
    margin-right: 0; }
  .TeaserGrid--inner > * {
    float: left;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    /* stylelint-disable */
    width: 100%;
    width: calc(100% / 1);
    /* stylelint-enable */
    margin-top: 20px;
    padding-right: 0px; }
  .TeaserGrid--inner:after {
    content: '';
    display: table;
    clear: both; } }

@media print {
  .TeaserGrid--inner {
    display: block;
    page-break-inside: auto; }
  .TeaserGrid--inner > * {
    page-break-inside: avoid; } }

@media only screen {
  html.loaded .has-scrollsighter {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
    will-change: transform;
    opacity: 0; }
  html.loaded .has-scrollsighter.is-visible {
    -webkit-transform: none;
    transform: none;
    transition: opacity 0.5s cubic-bezier(0, 0.4, 0.2, 0.99), -webkit-transform 1s cubic-bezier(0, 0.4, 0.2, 0.99);
    transition: transform 1s cubic-bezier(0, 0.4, 0.2, 0.99), opacity 0.5s cubic-bezier(0, 0.4, 0.2, 0.99);
    transition: transform 1s cubic-bezier(0, 0.4, 0.2, 0.99), opacity 0.5s cubic-bezier(0, 0.4, 0.2, 0.99), -webkit-transform 1s cubic-bezier(0, 0.4, 0.2, 0.99);
    will-change: auto;
    opacity: 1; } }

.TeaserGrid--readmore {
  display: none;
  width: 100%;
  margin-bottom: 160px;
  margin-top: 80px; }

@media screen and (max-width: 1000px) {
  .TeaserGrid--readmore {
    margin-bottom: 76px; } }

@media screen and (max-width: 580px) {
  .TeaserGrid--readmore {
    margin-bottom: 51px; } }

@media screen and (max-width: 1000px) {
  .TeaserGrid--readmore {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .TeaserGrid--readmore {
    margin-top: 36px; } }

@media print {
  .TeaserGrid--readmore {
    margin-bottom: 80px;
    margin-top: 40px; } }

.CategorySwitch .DropDown--trigger {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  text-align: center;
  width: auto; }

.Footer {
  background-color: #000000;
  color: #ffffff;
  position: relative;
  z-index: 10;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: normal; }

@media screen and (max-width: 580px) {
  .Footer {
    text-align: center; } }

@media print {
  .Footer {
    background-color: #ffffff;
    color: #000000; }
  .Footer .Footer--linklist, .Footer .FooterNav, .Footer .Footer--row-CTA, .Footer .Footer--row:after, .Footer .Footer--row:before, .Footer .Footer--row:empty, .Footer .SocialLinks, .Footer .l-FlexGrid > *:empty {
    display: none; }
  .Footer .Footer--row {
    border: none; }
  .Footer .l-FlexGrid > * {
    padding: 0;
    margin: 0; }
  .Footer .Footer--contact {
    padding: 40px 0; } }

.Footer--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto; }

@media screen and (max-width: 1340px) {
  .Footer--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .Footer--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .Footer--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .Footer--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.Footer--row {
  border: 1px solid #000000; }

.Footer--row:before, .Footer--row:after {
  content: '';
  display: block;
  margin-bottom: 40px; }

@media screen and (max-width: 1000px) {
  .Footer--row:before, .Footer--row:after {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .Footer--row:before, .Footer--row:after {
    margin-bottom: 25px; } }

.Footer--row-nav {
  line-height: 1.875; }

.Footer--row-CTA {
  text-align: center; }

.Footer--row-CTA .Button {
  display: inline-block;
  margin: 20px 10px; }

@media screen and (max-width: 580px) {
  .Footer--row-CTA .Button {
    display: block;
    width: 100%;
    margin: 20px 0; } }

.Footer--row-CTA:before, .Footer--row-CTA:after {
  margin-bottom: 80px; }

@media screen and (max-width: 1000px) {
  .Footer--row-CTA:before, .Footer--row-CTA:after {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .Footer--row-CTA:before, .Footer--row-CTA:after {
    margin-bottom: 36px; } }

.Footer--row-copy {
  font-size: 13px;
  line-height: 1.57;
  color: #666666;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1); }

@media (min-width: 750px) {
  .Footer--row-copy {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .Footer--row-copy {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .Footer--row-copy {
    font-size: 13px; } }

@media print {
  .Footer--row-copy {
    font-size: 12px;
    font-size: 0.75rem; } }

@media screen and (max-width: 580px) {
  .Footer--row-copy {
    font-size: 16px;
    line-height: 1.25; } }

.Footer--linklist {
  display: inline-block;
  margin: 0 20px; }

@media screen and (max-width: 580px) {
  .Footer--linklist {
    display: block; } }

.Footer--linklist--item {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px; }

@media screen and (max-width: 580px) {
  .Footer--linklist--item {
    display: block; } }

.Footer--link:hover {
  color: #ffffff; }

.Footer--contact {
  margin-bottom: 10px; }

@media print {
  .Footer--contact {
    text-align: left;
    line-height: 1.3; } }

.Footer--contact--mail, .Footer--contact--company {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.Footer--seal {
  display: inline-block;
  margin-right: 40px; }

.Footer--seal--img {
  vertical-align: middle; }

@media only screen and (max-width: 580px) {
  .Footer--seal, .Footer--copy {
    display: block;
    margin: 40px 0; } }

.FooterNav--title {
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  color: #FF0040;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

@media screen and (max-width: 580px) {
  .FooterNav--title {
    font-size: 18px;
    line-height: 1.35; } }

.FooterNav--link:hover {
  color: #FF0040; }

.Header {
  background: #ffffff;
  color: #222222; }

.Header .Header--menurow {
  border-top: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.Header .Header--menurow:before, .Header .Header--menurow:after {
  content: '';
  display: block; }

.Header .Header--menurow:before {
  margin-top: 25px; }

@media screen and (max-width: 750px) {
  .Header .Header--menurow:before {
    margin-top: 20px; } }

.Header .Header--menurow:after {
  margin-bottom: 25px; }

@media screen and (max-width: 750px) {
  .Header .Header--menurow:after {
    margin-bottom: 20px; } }

.Header .Header--menurow.Header--menurow-categorymenu {
  padding-top: 0px; }

.Header .Header--menurow.Header--menurow-categorymenu:before {
  margin-top: 10px; }

@media screen and (max-width: 1000px) {
  .Header .Header--menurow.Header--menurow-categorymenu:before {
    margin-top: 10px; } }

@media screen and (max-width: 580px) {
  .Header .Header--menurow.Header--menurow-categorymenu:before {
    margin-top: 10px; } }

.Header .Header--menurow.Header--menurow-categorymenu:after {
  margin-bottom: 10px; }

@media screen and (max-width: 1000px) {
  .Header .Header--menurow.Header--menurow-categorymenu:after {
    margin-bottom: 10px; } }

@media screen and (max-width: 580px) {
  .Header .Header--menurow.Header--menurow-categorymenu:after {
    margin-bottom: 10px; } }

.Header.mobilemenu-is-active {
  height: 100vh; }

.Header.mobilemenu-is-active .Header--menurow-categorymenu, .Header.mobilemenu-is-active .Header--nav--services {
  display: none; }

.Header.mobilemenu-is-active ~ * {
  display: none; }

.Header:not(.loaded) .HeaderMainNav.HeaderMainNav, .Header:not(.loaded) .HeaderMetaNav.HeaderMetaNav {
  opacity: 0; }

@media print {
  .Header .Header--search, .Header .HeaderMainNav.HeaderMainNav, .Header .Header--nav--services, .Header .Header--nav--mobile, .Header .Header--menurow-categorymenu {
    display: none; } }

html.headermenumobile-isactive {
  overflow: hidden; }

.Header--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  width: 112.5rem;
  position: relative; }

@media screen and (max-width: 1960px) {
  .Header--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1340px) {
  .Header--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .Header--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .Header--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .Header--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.Header--search {
  background-color: #F4F5F6;
  transition: all 0.2s ease; }

.Header--search:not(.is-active) {
  margin: 0;
  height: 0px;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%); }

.Header--search.is-active {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.Header--search .HeaderSearchbar--close {
  background-color: #000000;
  color: #ffffff; }

.Header--search .HeaderSearchbar--close:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.Header--nav {
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center; }

.Header--nav:before, .Header--nav:after {
  content: "";
  display: table; }

.Header--nav:after {
  clear: both; }

.mobilemenu-is-active .Header--nav .HeaderMainNav.HeaderMainNav, .mobilemenu-is-active .Header--nav .HeaderMetaNav.HeaderMetaNav, .Header--nav.small .HeaderMainNav.HeaderMainNav, .Header--nav.small .HeaderMetaNav.HeaderMetaNav {
  display: none; }

.Header--nav:not(.small) .Header--nav--mobile {
  display: none; }

.Header.mobilemenu-is-active .Header--nav .Header--nav--mobile {
  display: block; }

.Header--nav--mainnav, .Header--nav--services, .Header--nav--mobile {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
  display: block; }

.Header--nav--mainnav.Header--nav--mobile, .Header--nav--services.Header--nav--mobile, .Header--nav--mobile.Header--nav--mobile {
  -webkit-flex: 0 0 0;
  flex: 0 0 0; }

.Header--nav--mainnav .HeaderMainNav, .Header--nav--mainnav .HeaderLogo {
  display: inline-block;
  vertical-align: middle; }

.Header--nav--mainnav .HeaderLogo {
  margin-right: 40px; }

.Header--nav--services {
  text-align: right; }

.Header--nav--services > * {
  text-align: left; }

.Header--nav--services .HeaderMetaNav, .Header--nav--services .HeaderCTA {
  display: inline-block;
  vertical-align: middle; }

.Header--nav--services .HeaderCTA {
  line-height: 40px; }

@media screen and (max-width: 750px) {
  .Header--nav--services .HeaderCTA {
    line-height: 30px; } }

.Header--nav--services .HeaderMetaNav {
  margin-right: 40px; }

.Header--nav--mobile {
  margin-left: 20px; }

html.printing .Header {
  visibility: hidden; }

html.printing .Header .HeaderLogo {
  visibility: visible; }

.HeaderLogo {
  position: relative;
  display: block;
  z-index: 0; }

.HeaderLogo .HeaderLogo-image {
  max-width: 100%;
  vertical-align: middle;
  height: auto; }

.HeaderLogo-image.HeaderLogo-image-mobile {
  display: none;
  width: 105px; }

@media screen and (max-width: 1000px) {
  .HeaderLogo-image.HeaderLogo-image-mobile {
    display: block; } }

.HeaderLogo-image.HeaderLogo-image-desktop {
  display: block;
  width: 136px; }

@media screen and (max-width: 1000px) {
  .HeaderLogo-image.HeaderLogo-image-desktop {
    display: none; } }

.HeaderMainNav {
  margin-right: 40px; }

.HeaderMainNav--list > li {
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle; }

.HeaderMainNav--list > li:first-child {
  margin-left: 0; }

.HeaderMainNav--list > li:last-child {
  margin-right: 0; }

.HeaderMainNav--link--text {
  font-size: 16px;
  line-height: 1.25;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold;
  color: #FF0040; }

@media (min-width: 750px) {
  .HeaderMainNav--link--text {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .HeaderMainNav--link--text {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .HeaderMainNav--link--text {
    font-size: 16px; } }

@media print {
  .HeaderMainNav--link--text {
    font-size: 14px;
    font-size: 0.875rem; } }

.HeaderMainNav--link:focus .HeaderMainNav--link--text, .HeaderMainNav--link:hover .HeaderMainNav--link--text {
  color: #000000; }

.HeaderMainNav--link.is-active .HeaderMainNav--link--text {
  text-decoration: underline;
  text-underline-position: under; }

.HeaderMetaNav .HeaderMetaNav--link[data-title], .HeaderMetaNav .HeaderMetaNav--link-search[data-title], .HeaderMetaNav .DropDown--trigger[data-title] {
  position: relative; }

html.can-hover .HeaderMetaNav .HeaderMetaNav--link[data-title]:hover:before, html.can-hover .HeaderMetaNav .HeaderMetaNav--link-search[data-title]:hover:before, html.can-hover .HeaderMetaNav .DropDown--trigger[data-title]:hover:before {
  content: attr(data-title);
  position: absolute;
  top: 100%;
  text-transform: none;
  left: 50%;
  line-height: 1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #FF0040;
  font-size: 13px;
  line-height: 1.57;
  padding-top: 4px; }

@media (min-width: 750px) {
  html.can-hover .HeaderMetaNav .HeaderMetaNav--link[data-title]:hover:before, html.can-hover .HeaderMetaNav .HeaderMetaNav--link-search[data-title]:hover:before, html.can-hover .HeaderMetaNav .DropDown--trigger[data-title]:hover:before {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  html.can-hover .HeaderMetaNav .HeaderMetaNav--link[data-title]:hover:before, html.can-hover .HeaderMetaNav .HeaderMetaNav--link-search[data-title]:hover:before, html.can-hover .HeaderMetaNav .DropDown--trigger[data-title]:hover:before {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  html.can-hover .HeaderMetaNav .HeaderMetaNav--link[data-title]:hover:before, html.can-hover .HeaderMetaNav .HeaderMetaNav--link-search[data-title]:hover:before, html.can-hover .HeaderMetaNav .DropDown--trigger[data-title]:hover:before {
    font-size: 13px; } }

@media print {
  html.can-hover .HeaderMetaNav .HeaderMetaNav--link[data-title]:hover:before, html.can-hover .HeaderMetaNav .HeaderMetaNav--link-search[data-title]:hover:before, html.can-hover .HeaderMetaNav .DropDown--trigger[data-title]:hover:before {
    font-size: 12px;
    font-size: 0.75rem; } }

.HeaderMetaNav--item {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.HeaderMetaNav--item + .HeaderMetaNav--item {
  margin-left: 5px; }

html.can-hover .HeaderMetaNav--item:hover:before {
  content: '';
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: rgba(158, 158, 158, 0.125);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 100%;
  box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  margin-top: -1px; }

.HeaderMetaNav--link {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  text-align: center; }

.HeaderMetaNav--link .Icon {
  vertical-align: middle; }

.HeaderMetaNav--link:hover .Icon[data-icon='publications']:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -122px -26px;
  width: 24px;
  height: 24px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .HeaderMetaNav--link:hover .Icon[data-icon='publications']:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -118px -25px;
    background-size: 159px 137px; } }

.HeaderMobile {
  display: none; }

.mobilemenu-is-active .HeaderMobile {
  display: block; }

.HeaderMobile--close {
  background-color: #000000;
  color: #ffffff; }

.HeaderMobile--close:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.HeaderMobile--inner {
  margin-top: 20px;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto; }

.HeaderMobile--nav {
  margin-bottom: 20px; }

.HeaderMobile--navlist--item {
  border-bottom: 1px solid #CFCFCF;
  padding: 1em 40px; }

@media screen and (max-width: 750px) {
  .HeaderMobile--navlist--item {
    padding: 1em 20px; } }

.HeaderMobile--navlist--link {
  display: block;
  width: 100%;
  text-align: left;
  vertical-align: middle;
  color: #FF0040; }

.HeaderMobile--navlist--link:active, html:not(.can-touch) .HeaderMobile--navlist--link:hover {
  color: #000000; }

.HeaderMobile--navlist--link.is-active {
  color: #FF0040; }

.HeaderMobile--navlist--item--dropdown .HeaderMobile--navlist--link {
  display: block;
  position: relative; }

.HeaderMobile--navlist--item--dropdown .HeaderMobile--navlist--link:after {
  width: 0.6em;
  height: 0.6em;
  content: '';
  position: absolute;
  top: 50%;
  border-style: solid;
  border-width: 2px 2px 0 0;
  -webkit-transform: translateY(-0.459em) translateX(-0.3em) scaleY(0.75) rotate(135deg);
  transform: translateY(-0.459em) translateX(-0.3em) scaleY(0.75) rotate(135deg);
  right: 0; }

.HeaderMobile--navlist--item--dropdown .HeaderMobile--navlist--link.is-opened:after {
  width: 0.6em;
  height: 0.6em;
  content: '';
  position: absolute;
  top: 50%;
  border-style: solid;
  border-width: 2px 2px 0 0;
  -webkit-transform: translateY(-0.141em) translateX(-0.3em) scaleY(0.75) rotate(-45deg);
  transform: translateY(-0.141em) translateX(-0.3em) scaleY(0.75) rotate(-45deg);
  right: 0; }

.HeaderMobile--navlist--item--dropdown .HeaderMobile--categories {
  display: none; }

.HeaderMobile--navlist--item--dropdown.is-opened .HeaderMobile--categories {
  display: block; }

.HeaderMobile--categories {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.25;
  line-height: 1.8;
  text-align: left;
  margin: 40px 0 20px 0; }

@media (min-width: 750px) {
  .HeaderMobile--categories {
    font-size: calc(2.22222vw + 3.33333px); } }

@media (min-width: 1200px) {
  .HeaderMobile--categories {
    font-size: calc(1.0989vw + 16.81319px); } }

@media (min-width: 1382px) {
  .HeaderMobile--categories {
    font-size: 32px; } }

@media print {
  .HeaderMobile--categories {
    font-size: 24px;
    font-size: 1.5rem; } }

@media screen and (max-width: 1200px) {
  .HeaderMobile--categories {
    line-height: 1.4; } }

.HeaderMobile--categorylist--link {
  color: #000000;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.HeaderMobile--categorylist--link:active, html:not(.can-touch) .HeaderMobile--categorylist--link:hover {
  color: #FF0040; }

.HeaderMobile--lang {
  margin-bottom: 20px;
  text-align: left;
  padding: 0px 20px 20px 12px; }

.HeaderMobile--langlist--item {
  display: inline-block; }

.HeaderMobile--langlist--link {
  color: #9E9E9E;
  padding: 0.5em; }

.HeaderMobile--langlist--link:active, html:not(.can-touch) .HeaderMobile--langlist--link:hover {
  color: #FF0040; }

.HeaderMobile--langlist--link.is-active {
  color: #FF0040; }

.Header--search .HeaderSearchbar--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  width: 112.5rem; }

@media screen and (max-width: 1960px) {
  .Header--search .HeaderSearchbar--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1340px) {
  .Header--search .HeaderSearchbar--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .Header--search .HeaderSearchbar--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .Header--search .HeaderSearchbar--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .Header--search .HeaderSearchbar--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.HeaderSearchbar--form {
  position: relative;
  padding-left: 0;
  padding-right: 38px; }

.HeaderSearchbar--input {
  display: block;
  line-height: 3;
  border: 0;
  background-color: transparent; }

@media screen and (min-width: 751px) {
  .HeaderSearchbar--input {
    width: 90%; } }

@media screen and (max-width: 750px) {
  .HeaderSearchbar--input {
    padding: 5px 5px 5px 15px; } }

.HeaderSearchbar--submit {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  display: inline-block;
  width: 30px;
  height: 30px; }

.HeaderSearchbar--submit:after {
  content: 'âœ•';
  background: url("img/icons/global/1x--sprite.png") no-repeat -26px -73px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .HeaderSearchbar--submit:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -25px -71px;
    background-size: 159px 137px; } }

.HeaderSearchbar--submit:active, html:not(.can-touch) .HeaderSearchbar--submit:hover {
  color: #FF0040; }

.Header--search .HeaderSearchbar .HeaderSearchbar--submit {
  right: auto;
  left: 0; }

.Header--search .HeaderSearchbar .HeaderSearchbar--form {
  padding-right: 0;
  padding-left: 38px; }

.HeaderMobile--inner > .HeaderSearchbar {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px; }

.HeaderMobile--inner > .HeaderSearchbar .HeaderSearchbar--inner {
  background-color: #F4F5F6; }

.HeaderMobile--inner > .HeaderSearchbar .HeaderSearchbar--submit {
  right: 10px;
  left: auto; }

.HeaderSticky {
  background: #ffffff;
  color: #222222; }

.HeaderSticky .ShareMe {
  display: inline-block;
  margin-left: 20px; }

@media screen and (max-width: 750px) {
  .HeaderSticky .ShareMe {
    display: none; } }

.HeaderSticky.js-stickybox--animsize {
  overflow: hidden;
  position: relative; }

@media print {
  .HeaderSticky {
    display: none; } }

.HeaderSticky--stickybox {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  will-change: transform; }

.HeaderSticky.is-detached .HeaderSticky--stickybox {
  position: fixed;
  z-index: 100;
  right: 0;
  left: 0;
  top: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  border-bottom: none; }

.HeaderSticky.is-detached .HeaderSticky--stickybox .HeaderSticky--show-below-top {
  display: inline-block; }

.HeaderSticky:not(.is-detached) .HeaderSticky--stickybox .HeaderSticky--show-on-top {
  display: inline-block; }

.HeaderSticky--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  width: 112.5rem;
  padding-top: 20px;
  padding-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center; }

@media screen and (max-width: 1960px) {
  .HeaderSticky--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1340px) {
  .HeaderSticky--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .HeaderSticky--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .HeaderSticky--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .HeaderSticky--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

.HeaderSticky.is-detached .HeaderSticky--inner {
  padding-top: 10px;
  padding-bottom: 10px; }

.HeaderSticky--inner .HeaderSticky--left, .HeaderSticky--inner .HeaderSticky--right {
  display: block;
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto; }

.HeaderSticky--inner .HeaderSticky--left {
  text-align: left;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 50%; }

.HeaderSticky--inner .HeaderSticky--right {
  text-align: right; }

.HeaderSticky--show-on-top, .HeaderSticky--show-below-top {
  vertical-align: middle;
  max-width: 100%;
  display: block;
  transition: opacity 0.3s ease-out, -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out, opacity 0.3s ease-out;
  transition: transform 0.1s ease-out, opacity 0.3s ease-out, -webkit-transform 0.1s ease-out; }

.HeaderSticky--show-on-top.HeaderSticky--show-on-top, .HeaderSticky--show-below-top.HeaderSticky--show-on-top {
  opacity: 1;
  visibility: visible;
  position: relative; }

.HeaderSticky--show-on-top.HeaderSticky--show-below-top, .HeaderSticky--show-below-top.HeaderSticky--show-below-top {
  opacity: 0;
  visibility: hidden;
  position: absolute; }

.HeaderSticky.is-detached .HeaderSticky--show-on-top.HeaderSticky--show-on-top, .HeaderSticky.is-detached .HeaderSticky--show-below-top.HeaderSticky--show-on-top {
  opacity: 0;
  visibility: hidden;
  position: absolute; }

.HeaderSticky.is-detached .HeaderSticky--show-on-top.HeaderSticky--show-below-top, .HeaderSticky.is-detached .HeaderSticky--show-below-top.HeaderSticky--show-below-top {
  opacity: 1;
  visibility: visible;
  position: relative; }

.HeaderSticky--left .HeaderSticky--show-on-top.HeaderSticky--show-on-top, .HeaderSticky--left .HeaderSticky--show-below-top.HeaderSticky--show-on-top {
  -webkit-transform: translateY(0px);
  transform: translateY(0px); }

.HeaderSticky--left .HeaderSticky--show-on-top.HeaderSticky--show-below-top, .HeaderSticky--left .HeaderSticky--show-below-top.HeaderSticky--show-below-top {
  -webkit-transform: translateY(15px);
  transform: translateY(15px); }

.HeaderSticky.is-detached .HeaderSticky--left .HeaderSticky--show-on-top.HeaderSticky--show-on-top, .HeaderSticky.is-detached .HeaderSticky--left .HeaderSticky--show-below-top.HeaderSticky--show-on-top {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px); }

.HeaderSticky.is-detached .HeaderSticky--left .HeaderSticky--show-on-top.HeaderSticky--show-below-top, .HeaderSticky.is-detached .HeaderSticky--left .HeaderSticky--show-below-top.HeaderSticky--show-below-top {
  -webkit-transform: translateY(0px);
  transform: translateY(0px); }

.HeaderSticky--back, .HeaderSticky--totop {
  position: relative;
  padding: 0;
  vertical-align: middle;
  text-align: left;
  display: inline-block;
  padding-left: 34px; }

.HeaderSticky--back:hover, .HeaderSticky--totop:hover {
  color: #FF0040; }

.HeaderSticky--back {
  font-size: 16px;
  line-height: 1.25;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%; }

@media (min-width: 750px) {
  .HeaderSticky--back {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .HeaderSticky--back {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .HeaderSticky--back {
    font-size: 16px; } }

@media print {
  .HeaderSticky--back {
    font-size: 14px;
    font-size: 0.875rem; } }

.HeaderSticky--back:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -18px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px;
  left: 10px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .HeaderSticky--back:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -17px;
    background-size: 159px 137px; } }

html.can-hover .HeaderSticky--back:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px 0px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px;
  left: 10px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  html.can-hover .HeaderSticky--back:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px 0px;
    background-size: 159px 137px; } }

.HeaderSticky--totop {
  display: block;
  width: 100%;
  line-height: 1; }

.HeaderSticky--totop:before {
  margin-left: -4px;
  background: url("img/icons/global/1x--sprite.png") no-repeat -96px -26px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 0; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .HeaderSticky--totop:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -93px -25px;
    background-size: 159px 137px; } }

html.can-hover .HeaderSticky--totop:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -96px 0px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 0; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  html.can-hover .HeaderSticky--totop:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -93px 0px;
    background-size: 159px 137px; } }

.HeaderSticky--totop--title, .HeaderSticky--totop--pretitle {
  display: inline-block;
  width: 100%; }

.HeaderSticky--totop--pretitle {
  font-size: 13px;
  line-height: 1.57;
  color: #9E9E9E; }

@media (min-width: 750px) {
  .HeaderSticky--totop--pretitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .HeaderSticky--totop--pretitle {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .HeaderSticky--totop--pretitle {
    font-size: 13px; } }

@media print {
  .HeaderSticky--totop--pretitle {
    font-size: 12px;
    font-size: 0.75rem; } }

.HeaderSticky--totop--title {
  font-size: 16px;
  line-height: 1.25;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

@media (min-width: 750px) {
  .HeaderSticky--totop--title {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .HeaderSticky--totop--title {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .HeaderSticky--totop--title {
    font-size: 16px; } }

@media print {
  .HeaderSticky--totop--title {
    font-size: 14px;
    font-size: 0.875rem; } }

.HeaderSticky--CTA {
  font-size: 16px;
  line-height: 1.25;
  line-height: 40px;
  padding: 0 1.5rem;
  vertical-align: middle; }

@media (min-width: 750px) {
  .HeaderSticky--CTA {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .HeaderSticky--CTA {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .HeaderSticky--CTA {
    font-size: 16px; } }

@media print {
  .HeaderSticky--CTA {
    font-size: 14px;
    font-size: 0.875rem; } }

@media screen and (max-width: 750px) {
  .HeaderSticky--CTA {
    font-size: 14px;
    font-size: 0.875rem;
    padding: 0 1.25rem;
    line-height: 36px; } }

.HeaderSticky--readindicator {
  font-size: 0; }

.HeaderSticky--readindicator--bar {
  background-color: #FF0040;
  display: inline-block;
  width: 0;
  height: 2px; }

.LanguageSwitch .DropDown--trigger {
  text-transform: uppercase;
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  text-align: center; }

.LanguageSwitch .DropDown--trigger:hover {
  color: #FF0040; }

.MobileMenuToggle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 10px;
  margin-right: -10px;
  margin-left: -10px; }

.MobileMenuToggle:after {
  content: '';
  display: inline-block;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  transition: -webkit-transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1); }

.MobileMenuToggle.is-active:after {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1); }

.MobileMenuToggle.is-active:active:after, html:not(.can-touch) .MobileMenuToggle.is-active:hover:after {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1); }

.MobileMenuToggle--line {
  display: block;
  width: 20px;
  height: 2px;
  position: relative;
  background: #000000;
  border-radius: 3px;
  z-index: 1;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: background 0.4s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.45s ease, background 0.45s ease, -webkit-transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.4s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.45s ease, background 0.45s ease;
  transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.4s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.45s ease, background 0.45s ease, -webkit-transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1); }

.MobileMenuToggle--line + .MobileMenuToggle--line {
  margin-top: 4px; }

.MobileMenuToggle.is-active .MobileMenuToggle--line {
  opacity: 1;
  -webkit-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg); }

.MobileMenuToggle.is-active .MobileMenuToggle--line:nth-last-child(2) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
  transform: rotate(0deg) scale(0.2, 0.2); }

.MobileMenuToggle.is-active .MobileMenuToggle--line:nth-last-child(1) {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg); }

.SearchMenuToggle {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  position: relative; }

.SearchMenuToggle:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -26px -73px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px;
  vertical-align: middle; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SearchMenuToggle:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -25px -71px;
    background-size: 159px 137px; } }

.SearchMenuToggle:hover:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -52px -73px;
  width: 24px;
  height: 24px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -12px;
  vertical-align: middle; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SearchMenuToggle:hover:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -50px -71px;
    background-size: 159px 137px; } }

.SocialLinks--link, .SocialLinks--item {
  display: inline-block;
  vertical-align: middle; }

.SocialLinks--link {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: -15px 0; }

.SocialLinks--link:hover {
  -webkit-animation: SocialLinks--link-hover 0.2s forwards;
  animation: SocialLinks--link-hover 0.2s forwards; }

.SocialLinks--link.instagram:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -96px -52px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.instagram:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -68px -25px;
    background-size: 159px 137px; } }

.SocialLinks--link.instagram:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -126px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.instagram:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -119px -121px;
    background-size: 159px 137px; } }

.SocialLinks--link.twitter:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -104px -73px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.twitter:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -100px -71px;
    background-size: 159px 137px; } }

.SocialLinks--link.twitter:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -44px -26px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.twitter:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -75px -46px;
    background-size: 159px 137px; } }

.SocialLinks--link.fb:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -90px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.fb:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -85px -121px;
    background-size: 159px 137px; } }

.SocialLinks--link.fb:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -70px -26px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.fb:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -43px -25px;
    background-size: 159px 137px; } }

.SocialLinks--link.youtube:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -82px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.youtube:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -78px -96px;
    background-size: 159px 137px; } }

.SocialLinks--link.youtube:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -64px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.youtube:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -61px -96px;
    background-size: 159px 137px; } }

.SocialLinks--link.mail:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -18px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.mail:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -17px -121px;
    background-size: 159px 137px; } }

.SocialLinks--link.mail:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -36px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks--link.mail:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -34px -121px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.instagram:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -54px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.instagram:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -51px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.instagram:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -126px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.instagram:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -119px -121px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.twitter:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -46px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.twitter:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -44px -96px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.twitter:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -44px -26px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.twitter:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -75px -46px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.fb:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat 0px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.fb:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat 0px -121px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.fb:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -70px -26px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.fb:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -43px -25px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.youtube:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -100px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.youtube:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -95px -96px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.youtube:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -64px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.youtube:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -61px -96px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.mail:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -118px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.mail:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -112px -96px;
    background-size: 159px 137px; } }

.SocialLinks.dark .SocialLinks--link.mail:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -36px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.dark .SocialLinks--link.mail:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -34px -121px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.instagram:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -126px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.instagram:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -119px -121px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.instagram:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -54px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.instagram:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -51px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.twitter:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -44px -26px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.twitter:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -75px -46px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.twitter:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -46px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.twitter:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -44px -96px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.fb:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -70px -26px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.fb:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -43px -25px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.fb:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat 0px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.fb:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat 0px -121px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.youtube:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -64px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.youtube:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -61px -96px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.youtube:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -100px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.youtube:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -95px -96px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.mail:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -36px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.mail:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -34px -121px;
    background-size: 159px 137px; } }

.SocialLinks.red .SocialLinks--link.mail:hover:before {
  background: url("img/icons/global/1x--sprite.png") no-repeat -118px -99px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .SocialLinks.red .SocialLinks--link.mail:hover:before {
    background: url("img/icons/global/2x--sprite.png") no-repeat -112px -96px;
    background-size: 159px 137px; } }

@-webkit-keyframes SocialLinks--link-hover {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  90% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes SocialLinks--link-hover {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  90% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.accordion_inline {
  display: block;
  margin: -40px -20px 0 -20px; }

.accordion_inline:before, .accordion_inline:after {
  content: "";
  display: table; }

.accordion_inline:after {
  clear: both; }

.accordion_inline--item {
  position: relative;
  display: block;
  width: 100%;
  margin: 40px 20px 0 20px;
  float: left; }

.accordion_inline--item.accordion_inline--item-1of4 {
  clear: left; }

.accordion_inline--item.accordion_inline--item-1of4 .accordion_inline--item--outer {
  float: left;
  right: calc(-300% - 120px);
  left: 0; }

.accordion_inline--item.accordion_inline--item-2of4 .accordion_inline--item--outer {
  float: left;
  right: calc(-200% - 80px);
  left: calc(-100% - 40px); }

.accordion_inline--item.accordion_inline--item-3of4 .accordion_inline--item--outer {
  float: left;
  right: calc(-100% - 40px);
  left: calc(-200% - 80px); }

.accordion_inline--item.accordion_inline--item-4of4 .accordion_inline--item--outer {
  float: left;
  right: 0;
  left: calc(-300% - 120px); }

.accordion_inline--item.accordion_inline--item-1of3 {
  clear: left; }

.accordion_inline--item.accordion_inline--item-1of3 .accordion_inline--item--outer {
  float: left;
  right: calc(-200% - 80px);
  left: 0; }

.accordion_inline--item.accordion_inline--item-2of3 .accordion_inline--item--outer {
  float: left;
  right: calc(-100% - 40px);
  left: calc(-100% - 40px); }

.accordion_inline--item.accordion_inline--item-3of3 .accordion_inline--item--outer {
  float: left;
  right: 0;
  left: calc(-200% - 80px); }

.accordion_inline--item.accordion_inline--item-1of2 {
  clear: left; }

.accordion_inline--item.accordion_inline--item-1of2 .accordion_inline--item--outer {
  float: left;
  right: calc(-100% - 40px);
  left: 0; }

.accordion_inline--item.accordion_inline--item-2of2 .accordion_inline--item--outer {
  float: left;
  right: 0;
  left: calc(-100% - 40px); }

.accordion_inline--item.accordion_inline--item-1of1 {
  clear: left; }

.accordion_inline--item.accordion_inline--item-1of1 .accordion_inline--item--outer {
  float: left;
  right: 0;
  left: 0; }

.accordion_inline--item.selected .accordion_inline--item--outer {
  display: block; }

.accordion_inline--item--inner {
  display: block;
  width: 100%;
  text-align: left; }

.accordion_inline--item--outer {
  display: none;
  position: absolute;
  top: 100%; }

.accordion {
  list-style: none; }

.accordion--header {
  position: relative; }

.accordion--header > * {
  z-index: 1; }

.accordion--trigger {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  color: currentColor;
  text-align: left;
  top: 0;
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  z-index: 2; }

@media screen and (-ms-high-contrast: active) {
  .accordion--trigger {
    background: transparent; } }

.accordion--panel {
  display: none;
  padding-top: 1px;
  margin-top: -1px; }

.accordion--panel.is-closing {
  height: 0; }

.accordion--panel.is-opened {
  display: block; }

/* #addsearch-results { font-family: 'Radikal W01', Arial, Sans-Serif; font-weight: bold; font-size: 16px; line-height: 1.25; color: #222222; z-index: 99; -webkit-transition: box-shadow 0.3s ease-in-out 0s; -moz-transition: box-shadow 0.3s ease-in-out 0s; transition: box-shadow 0.3s ease-in-out 0s; -webkit-box-shadow: 0px 200000px 0px 200000px rgba(0,0,0,0.5),  0 40px 50px 0 rgba(0,0,0,0.15); -moz-box-shadow: 0px 200000px 0px 200000px rgba(0,0,0,0.5),  0 40px 50px 0 rgba(0,0,0,0.15); box-shadow: 0px 200000px 0px 200000px rgba(0,0,0,0.5),  0 40px 50px 0 rgba(0,0,0,0.15); } #addsearch-results .addsearch-result-item-sub h1 a, #addsearch-results .addsearch-result-item-sub h2 a { font-weight: bold; font-size: 16px; line-height: 1.25; color: #ff0040; padding: 0 0 0.5em 0; } #addsearch-results .addsearch-result-item-sub { border-bottom: 1px solid rgba(0,0,0,0.2); border-right: 1px solid rgba(0,0,0,0.2); } #addsearch-results .addsearch-result-item-sub div, #addsearch-results.addsearch-m2 .addsearch-result-item-sub div { padding-top: 20px; padding-bottom: 20px; } #addsearch-results.addsearch-m2 .addsearch-result-item-sub div { padding-left: 10px !important; padding-right: 10px !important; } #addsearch-results .addsearch-result-item-sub p { color: #666666; font-family: 'GT-Sectra', Times, serif; font-size: 16px; font-weight: 300; line-height: 22px; } #addsearch-results .addsearch-result-item-sub p em { font-weight: bold; color: #222222; } #addsearch-results .addsearch-result-item-sub p .addsearch_category, #addsearch-results.addsearch-m2 .addsearch_category { margin-top: 1em; margin-bottom: 0; } #addsearch-results .addsearch-result-item-container, #addsearch-results .addsearch-result-item-sub { background-size: 135px; } #addsearch-results.addsearch-m2 .addsearch-result-item-container, #addsearch-results.addsearch-m2 .addsearch-result-item-sub { background-size: 0 !important; background-image: none !important; } #addsearch-logo { display: none !important; } */
.DropDown {
  position: relative;
  text-align: left; }

.DropDown--trigger {
  width: auto;
  height: auto;
  white-space: nowrap; }

.DropDown--trigger:hover {
  color: #FF0040; }

.DropDown--trigger--label {
  display: inline-block; }

.DropDown--dropdown {
  display: none;
  position: absolute;
  z-index: 20;
  background-color: #ffffff;
  border: 1px solid #CFCFCF; }

.DropDown--dropdown.is-active {
  display: block; }

.DropDown--list--item + .DropDown--list--item {
  border-top: 1px solid #CFCFCF; }

.DropDown--link {
  display: block;
  padding: 0.5em 1em; }

.DropDown--link.is-selected {
  color: #FF0040; }

.DropDown--link:active, html:not(.can-touch) .DropDown--link:hover {
  color: #FF0040; }

.DropDown--close {
  background-color: #000000;
  color: #ffffff; }

.DropDown--close:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.IFrame {
  margin-bottom: 80px;
  margin-top: 80px;
  display: block;
  width: 100%;
  overflow: hidden;
  border: none;
  transition: height 0.1s ease-out; }

@media screen and (max-width: 1000px) {
  .IFrame {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .IFrame {
    margin-bottom: 36px; } }

@media screen and (max-width: 1000px) {
  .IFrame {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .IFrame {
    margin-top: 36px; } }

.inline_scroll {
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none; }

.inline_scroll::-webkit-scrollbar {
  background: transparent;
  width: 0px;
  height: 0px; }

.inline_scroll::-webkit-scrollbar {
  display: none; }

.inline_scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0; }

.l-pane {
  padding-left: 40px;
  padding-right: 40px; }

.l-pane:before, .l-pane:after {
  content: "";
  display: table; }

.l-pane:after {
  clear: both; }

@media screen and (max-width: 750px) {
  .l-pane {
    padding-left: 20px;
    padding-right: 20px; } }

.l-pane:before, .l-pane:after {
  display: block;
  content: '';
  float: none;
  clear: both;
  height: 1px; }

.l-pane:before {
  margin-bottom: 40px;
  margin-top: -1px; }

@media screen and (max-width: 1000px) {
  .l-pane:before {
    margin-bottom: 38px; } }

@media screen and (max-width: 580px) {
  .l-pane:before {
    margin-bottom: 25px; } }

.l-pane:after {
  margin-top: 40px;
  margin-bottom: -1px; }

@media screen and (max-width: 1000px) {
  .l-pane:after {
    margin-top: 38px; } }

@media screen and (max-width: 580px) {
  .l-pane:after {
    margin-top: 25px; } }

.l-pane.gray-light {
  background-color: #F4F5F6; }

.l-pane.large_gap:before, .l-pane.large_gap-top:before {
  margin-bottom: 80px; }

@media screen and (max-width: 1000px) {
  .l-pane.large_gap:before, .l-pane.large_gap-top:before {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .l-pane.large_gap:before, .l-pane.large_gap-top:before {
    margin-bottom: 36px; } }

.l-pane.large_gap:after, .l-pane.large_gap-bottom:after {
  margin-top: 80px; }

@media screen and (max-width: 1000px) {
  .l-pane.large_gap:after, .l-pane.large_gap-bottom:after {
    margin-top: 54px; } }

@media screen and (max-width: 580px) {
  .l-pane.large_gap:after, .l-pane.large_gap-bottom:after {
    margin-top: 36px; } }

.l-pane.xlarge_gap:before, .l-pane.xlarge_gap-top:before {
  margin-bottom: 120px; }

@media screen and (max-width: 1000px) {
  .l-pane.xlarge_gap:before, .l-pane.xlarge_gap-top:before {
    margin-bottom: 66px; } }

@media screen and (max-width: 580px) {
  .l-pane.xlarge_gap:before, .l-pane.xlarge_gap-top:before {
    margin-bottom: 44px; } }

.l-pane.xlarge_gap:after, .l-pane.xlarge_gap-bottom:after {
  margin-top: 120px; }

@media screen and (max-width: 1000px) {
  .l-pane.xlarge_gap:after, .l-pane.xlarge_gap-bottom:after {
    margin-top: 66px; } }

@media screen and (max-width: 580px) {
  .l-pane.xlarge_gap:after, .l-pane.xlarge_gap-bottom:after {
    margin-top: 44px; } }

.l-pane.xxlarge_gap:before, .l-pane.xxlarge_gap-top:before {
  margin-bottom: 160px; }

@media screen and (max-width: 1000px) {
  .l-pane.xxlarge_gap:before, .l-pane.xxlarge_gap-top:before {
    margin-bottom: 76px; } }

@media screen and (max-width: 580px) {
  .l-pane.xxlarge_gap:before, .l-pane.xxlarge_gap-top:before {
    margin-bottom: 51px; } }

.l-pane.xxlarge_gap:after, .l-pane.xxlarge_gap-bottom:after {
  margin-top: 160px; }

@media screen and (max-width: 1000px) {
  .l-pane.xxlarge_gap:after, .l-pane.xxlarge_gap-bottom:after {
    margin-top: 76px; } }

@media screen and (max-width: 580px) {
  .l-pane.xxlarge_gap:after, .l-pane.xxlarge_gap-bottom:after {
    margin-top: 51px; } }

.l-pane.no_gap:before, .l-pane.no_gap-top:before {
  margin: 0;
  height: 0; }

.l-pane.no_gap:after, .l-pane.no_gap-bottom:after {
  margin: 0;
  height: 0; }

.NotificationBar {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  display: none; }

noscript .NotificationBar {
  display: block; }

.NotificationBar--inner {
  position: relative;
  width: 73.75rem;
  margin: 0 auto;
  width: 112.5rem;
  background-color: #F4F5F6;
  color: #222222;
  padding: 20px 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.05); }

@media screen and (max-width: 1960px) {
  .NotificationBar--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1340px) {
  .NotificationBar--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (max-width: 1200px) {
  .NotificationBar--inner {
    box-sizing: border-box;
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 750px) {
  .NotificationBar--inner {
    padding-left: 20px;
    padding-right: 20px; } }

@media print {
  .NotificationBar--inner {
    width: auto;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (min-width: 1961px) {
  .NotificationBar--inner {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125); } }

@media screen and (max-width: 1960px) {
  .NotificationBar--inner {
    padding: 20px 60px; } }

@media screen and (max-width: 750px) {
  .NotificationBar--inner {
    padding: 10px 50px 15px 20px; } }

.NotificationBar--content {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.57;
  text-align: center; }

@media (min-width: 750px) {
  .NotificationBar--content {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .NotificationBar--content {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .NotificationBar--content {
    font-size: 21px; } }

@media print {
  .NotificationBar--content {
    font-size: 16px;
    font-size: 1rem; } }

.NotificationBar--content table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: 1px solid #F4F5F6;
  margin-bottom: 20px;
  margin-top: 20px; }

.NotificationBar--content table thead > tr {
  font-size: 18px;
  line-height: 1.35; }

@media (min-width: 750px) {
  .NotificationBar--content table thead > tr {
    font-size: calc(0.44444vw + 14.66667px); } }

@media (min-width: 1200px) {
  .NotificationBar--content table thead > tr {
    font-size: calc(0.54945vw + 13.40659px); } }

@media (min-width: 1382px) {
  .NotificationBar--content table thead > tr {
    font-size: 21px; } }

@media print {
  .NotificationBar--content table thead > tr {
    font-size: 16px;
    font-size: 1rem; } }

.NotificationBar--content table thead > tr > th, .NotificationBar--content table thead > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.NotificationBar--content table thead > tr:last-child > th, .NotificationBar--content table thead > tr:last-child > td {
  border-bottom: 1px solid #000000; }

.NotificationBar--content table thead > tr > th {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.NotificationBar--content table tbody > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .NotificationBar--content table tbody > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .NotificationBar--content table tbody > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .NotificationBar--content table tbody > tr {
    font-size: 21px; } }

@media print {
  .NotificationBar--content table tbody > tr {
    font-size: 16px;
    font-size: 1rem; } }

.NotificationBar--content table tbody > tr > th, .NotificationBar--content table tbody > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.NotificationBar--content table tbody > tr:first-child > th, .NotificationBar--content table tbody > tr:first-child > td {
  border-top: 1px solid #F4F5F6; }

.NotificationBar--content table tfoot > tr {
  font-family: "GT-Sectra", "Times New Roman", serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.6; }

@media (min-width: 750px) {
  .NotificationBar--content table tfoot > tr {
    font-size: calc(0.88889vw + 11.33333px); } }

@media (min-width: 1200px) {
  .NotificationBar--content table tfoot > tr {
    font-size: calc(-0.54945vw + 28.59341px); } }

@media (min-width: 1382px) {
  .NotificationBar--content table tfoot > tr {
    font-size: 21px; } }

@media print {
  .NotificationBar--content table tfoot > tr {
    font-size: 16px;
    font-size: 1rem; } }

.NotificationBar--content table tfoot > tr > th, .NotificationBar--content table tfoot > tr > td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #F4F5F6; }

.NotificationBar--content table tfoot > tr:first-child > th, .NotificationBar--content table tfoot > tr:first-child > td {
  position: relative; }

.NotificationBar--content table tfoot > tr:first-child > th:before, .NotificationBar--content table tfoot > tr:first-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

.NotificationBar--content table tfoot > tr:last-child > th, .NotificationBar--content table tfoot > tr:last-child > td {
  border-bottom: 1px solid #F4F5F6;
  position: relative; }

.NotificationBar--content table tfoot > tr:last-child > th:before, .NotificationBar--content table tfoot > tr:last-child > td:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  display: inline-block;
  background-color: #F4F5F6; }

@media screen and (max-width: 1000px) {
  .NotificationBar--content table {
    margin-bottom: 20px; } }

@media screen and (max-width: 580px) {
  .NotificationBar--content table {
    margin-bottom: 18px; } }

@media screen and (max-width: 1000px) {
  .NotificationBar--content table {
    margin-top: 20px; } }

@media screen and (max-width: 580px) {
  .NotificationBar--content table {
    margin-top: 18px; } }

.NotificationBar--content p {
  margin-top: 1em;
  margin-bottom: 1em; }

.NotificationBar--content ul:not([class]) {
  margin: 1em 0;
  display: block; }

.NotificationBar--content ul:not([class]) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

.NotificationBar--content ul:not([class]) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

.NotificationBar--content ul:not([class]) > li:before {
  content: '\2013'; }

.NotificationBar--content ol:not([class]) {
  margin: 1em 0;
  display: block;
  counter-reset: ol--counter; }

.NotificationBar--content ol:not([class]) > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 1.5ex;
  padding-left: 30px; }

.NotificationBar--content ol:not([class]) > li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #FF0040;
  padding-right: 10px;
  text-align: left;
  width: 20px;
  overflow: visible; }

.NotificationBar--content ol:not([class]) > li:before {
  counter-increment: ol--counter;
  content: counter(ol--counter) "."; }

html.ie .NotificationBar--content ol:not([class]) > li {
  list-style: decimal inside none; }

.NotificationBar--content i {
  font-style: italic; }

.NotificationBar--content strong {
  font-weight: bold; }

.NotificationBar--content a:not([class]) {
  position: relative;
  max-width: 100%;
  transition: color 0.1s linear;
  text-decoration: none;
  border-bottom: 2px solid #FF0040; }

.NotificationBar--content a:not([class]):active, html:not(.can-touch) .NotificationBar--content a:not([class]):hover {
  color: #FF0040; }

a:active .NotificationBar--content a:not([class]), html:not(.can-touch) a:hover .NotificationBar--content a:not([class]) {
  color: #FF0040; }

@media (min-width: 750px) {
  .NotificationBar--content {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1200px) {
  .NotificationBar--content {
    font-size: calc(0vw + 13px); } }

@media (min-width: 1382px) {
  .NotificationBar--content {
    font-size: 13px; } }

@media print {
  .NotificationBar--content {
    font-size: 12px;
    font-size: 0.75rem; } }

@media screen and (max-width: 750px) {
  .NotificationBar--content {
    text-align: left; } }

.NotificationBar--close {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

@media screen and (max-width: 750px) {
  .NotificationBar--close {
    right: 5px; } }

.NotificationBar--close:before, .NotificationBar--close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -10.5px;
  display: block;
  height: 2px;
  width: 21px;
  background-color: currentColor;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out; }

.NotificationBar--close:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg); }

.NotificationBar--close:hover:before, .NotificationBar--close:hover:after {
  color: #FF0040;
  margin-top: -1.5px;
  height: 3px; }

/* stylelint-disable */
/* prettier-ignore-start */
.RaiseNow {
  margin-bottom: 80px; }

@media screen and (max-width: 1000px) {
  .RaiseNow {
    margin-bottom: 54px; } }

@media screen and (max-width: 580px) {
  .RaiseNow {
    margin-bottom: 36px; } }

.RaiseNow--inner {
  width: 100%;
  max-width: 776px;
  margin-left: auto;
  margin-right: auto; }

@media screen and (max-width: 856px) {
  .RaiseNow--inner {
    max-width: 100%;
    width: calc(100% - 80px); } }

@media screen and (max-width: 750px) {
  .RaiseNow--inner {
    width: calc(100% - 40px); } }

.RaiseNow #lema-container.lema-container {
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 1.25;
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 750px) {
  .RaiseNow #lema-container.lema-container {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1200px) {
  .RaiseNow #lema-container.lema-container {
    font-size: calc(0vw + 16px); } }

@media (min-width: 1382px) {
  .RaiseNow #lema-container.lema-container {
    font-size: 16px; } }

@media print {
  .RaiseNow #lema-container.lema-container {
    font-size: 14px;
    font-size: 0.875rem; } }

.RaiseNow #lema-container.lema-container a, .RaiseNow #lema-container.lema-container button, .RaiseNow #lema-container.lema-container label, .RaiseNow #lema-container.lema-container input, .RaiseNow #lema-container.lema-container textarea, .RaiseNow #lema-container.lema-container select, .RaiseNow #lema-container.lema-container p, .RaiseNow #lema-container.lema-container span, .RaiseNow #lema-container.lema-container div, .RaiseNow #lema-container.lema-container li, .RaiseNow #lema-container.lema-container input::-webkit-input-placeholder, .RaiseNow #lema-container.lema-container .lema-error-messages label {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.RaiseNow #lema-container.lema-container a, .RaiseNow #lema-container.lema-container button, .RaiseNow #lema-container.lema-container label, .RaiseNow #lema-container.lema-container input, .RaiseNow #lema-container.lema-container textarea, .RaiseNow #lema-container.lema-container select, .RaiseNow #lema-container.lema-container p, .RaiseNow #lema-container.lema-container span, .RaiseNow #lema-container.lema-container div, .RaiseNow #lema-container.lema-container li, .RaiseNow #lema-container.lema-container input:-ms-input-placeholder, .RaiseNow #lema-container.lema-container .lema-error-messages label {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.RaiseNow #lema-container.lema-container a, .RaiseNow #lema-container.lema-container button, .RaiseNow #lema-container.lema-container label, .RaiseNow #lema-container.lema-container input, .RaiseNow #lema-container.lema-container textarea, .RaiseNow #lema-container.lema-container select, .RaiseNow #lema-container.lema-container p, .RaiseNow #lema-container.lema-container span, .RaiseNow #lema-container.lema-container div, .RaiseNow #lema-container.lema-container li, .RaiseNow #lema-container.lema-container input::-ms-input-placeholder, .RaiseNow #lema-container.lema-container .lema-error-messages label {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.RaiseNow #lema-container.lema-container a, .RaiseNow #lema-container.lema-container button, .RaiseNow #lema-container.lema-container label, .RaiseNow #lema-container.lema-container input, .RaiseNow #lema-container.lema-container textarea, .RaiseNow #lema-container.lema-container select, .RaiseNow #lema-container.lema-container p, .RaiseNow #lema-container.lema-container span, .RaiseNow #lema-container.lema-container div, .RaiseNow #lema-container.lema-container li, .RaiseNow #lema-container.lema-container input::placeholder, .RaiseNow #lema-container.lema-container .lema-error-messages label {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

.RaiseNow #lema-container.lema-container .lema-overlay-header, .RaiseNow #lema-container.lema-container .select2-results .select2-highlighted {
  background: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-linklist a:hover, .RaiseNow #lema-container.lema-container .lema-linklist a, .RaiseNow #lema-container.lema-container .lema-accordion-item.accordion-open .lema-accordion-header, .RaiseNow #lema-container.lema-container .lema-link, .RaiseNow #lema-container.lema-container input.getunik-checkbox:checked + label, .RaiseNow #lema-container.lema-container input.getunik-radio:checked + label {
  border-color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-accordion-item.accordion-open .lema-accordion-content, .RaiseNow #lema-container.lema-container .lema-accordion-item.accordion-open .lema-accordion-header {
  border-color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-tabs li.active > a, .RaiseNow #lema-container.lema-container #lema-container.lema-container .lema-tabs li.active > a:hover, .RaiseNow #lema-container.lema-container #lema-container.lema-container .lema-tabs li.active > a:focus {
  color: #FF0040;
  border-color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-button-small {
  background-color: #FF0040;
  border-color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-step {
  margin-bottom: 60px; }

@media screen and (max-width: 1000px) {
  .RaiseNow #lema-container.lema-container .lema-step {
    margin-bottom: 46px; } }

@media screen and (max-width: 580px) {
  .RaiseNow #lema-container.lema-container .lema-step {
    margin-bottom: 31px; } }

.RaiseNow #lema-container.lema-container .lema-content.lema-accordion .lema-step-content {
  background-color: #F4F5F6; }

.RaiseNow #lema-container.lema-container .lema-step-header {
  border-bottom: none; }

.RaiseNow #lema-container.lema-container .lema-step-header-text {
  color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-step-number-text {
  background-color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-amount-box.lema-active {
  color: #FF0040;
  border-color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-amount-box.lema-active .lema-amount-box-amount, .RaiseNow #lema-container.lema-container .lema-amount-box.lema-active .lema-amount-box-custom-input {
  color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-amount-box .lema-error-messages {
  clear: both; }

.RaiseNow #lema-container.lema-container .lema-amount-box-custom-input, .RaiseNow #lema-container.lema-container .lema-amount-box-amount {
  font-size: 30px !important;
  line-height: 1.75 !important; }

.RaiseNow #lema-container.lema-container .lema-summary-check {
  padding: 0; }

.RaiseNow #lema-container.lema-container .lema-button-donate {
  background-color: #FF0040;
  color: #ffffff;
  border-radius: 0; }

.RaiseNow #lema-container.lema-container input.getunik-checkbox:checked + label, .RaiseNow #lema-container.lema-container input.getunik-radio:checked + label {
  color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-tabs li.active > a, .RaiseNow #lema-container.lema-container .lema-tabs li.active > a:hover, .RaiseNow #lema-container.lema-container .lema-tabs li.active > a:focus {
  color: #FF0040;
  border-bottom-color: #FF0040; }

.RaiseNow #lema-container.lema-container .lema-accordion-item .lema-accordion-header .lema-accordion-item-title:before, .RaiseNow #lema-container.lema-container input.getunik-checkbox + label:before, .RaiseNow #lema-container.lema-container input.getunik-radio + label:before {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }

.RaiseNow #lema-container.lema-container .lema-accordion-item.accordion-open .lema-accordion-content, .RaiseNow #lema-container.lema-container .lema-accordion-item.accordion-open .lema-accordion-header {
  color: #FF0040;
  border-color: #FF0040; }

html .select2-results .select2-highlighted {
  background: #FF0040; }

html .select2-drop .select2-results li {
  font-family: "Radikal W01", Arial, Sans-Serif;
  font-weight: bold; }

/* prettier-ignore-end */
/* stylelint-enable */
.scrollslider {
  position: relative;
  min-height: 40px;
  overflow: hidden;
  -webkit-scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  background-color: #ffffff;
  /*&.is-firefox { // hack to remove scrollbars for FF .scroll_slider--area { overflow-x: hidden; } &.has-scroll { .scroll_slider--area { overflow-x: scroll; &:after { content: ''; position: absolute; left: 0; right: 0; bottom: 0; display: inline-block; //height: 20px; background-color: $bgColor; z-index: 1; } } &.has-scroll-nowidth { .scroll_slider--area { &:after { height: 0px; } } } } }*/ }

.scrollslider.is-firefox .scroll_slider--area {
  overflow-x: hidden; }

@media screen and (min-width: 1201px) {
  .scrollslider .scroll_slider--area {
    overflow-x: hidden; } }

.scroll_slider--area {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  width: calc(100% - 2 * 20px);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 20px; }

.scroll_slider--area::-webkit-scrollbar {
  background: transparent;
  width: 0px;
  height: 0px; }

.scroll_slider--area::-webkit-scrollbar {
  display: none; }

.scroll_slider--area::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0; }

.scrollslider--scrollbutton {
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  width: 40px;
  min-height: 40px;
  overflow: visible;
  visibility: hidden; }

.scrollslider--scrollbutton > button {
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  will-change: transform, opacity;
  transition: all 300ms cubic-bezier(0.42, 0, 0.175, 1), opacity 200ms cubic-bezier(0.42, 0, 0.175, 1);
  z-index: 99;
  overflow: visible; }

.scrollslider--scrollbutton > button:after {
  content: '';
  display: inline-block;
  width: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
  opacity: 0; }

.scrollslider--scrollbutton > button:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none; }

.scrollslider--scrollbutton > button .scrollslider--scrollbutton-icon {
  opacity: 0.25; }

.scrollslider--scrollbutton.at-left {
  left: -12px; }

.scrollslider--scrollbutton.at-left > button:after {
  left: 100%;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.scrollslider--scrollbutton.at-left > button .scrollslider--scrollbutton-icon:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -18px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .scrollslider--scrollbutton.at-left > button .scrollslider--scrollbutton-icon:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -17px;
    background-size: 159px 137px; } }

html.can-hover .scrollslider--scrollbutton.at-left > button:hover .scrollslider--scrollbutton-icon:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px 0px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  html.can-hover .scrollslider--scrollbutton.at-left > button:hover .scrollslider--scrollbutton-icon:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px 0px;
    background-size: 159px 137px; } }

.scrollslider--scrollbutton.at-right {
  right: -12px; }

.scrollslider--scrollbutton.at-right > button:after {
  right: 100%; }

.scrollslider--scrollbutton.at-right > button .scrollslider--scrollbutton-icon:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -108px -125px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .scrollslider--scrollbutton.at-right > button .scrollslider--scrollbutton-icon:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -102px -121px;
    background-size: 159px 137px; } }

html.can-hover .scrollslider--scrollbutton.at-right > button:hover .scrollslider--scrollbutton-icon:after {
  background: url("img/icons/global/1x--sprite.png") no-repeat -148px -36px;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  html.can-hover .scrollslider--scrollbutton.at-right > button:hover .scrollslider--scrollbutton-icon:after {
    background: url("img/icons/global/2x--sprite.png") no-repeat -143px -34px;
    background-size: 159px 137px; } }

.scrollslider--scrollbutton-icon {
  position: absolute;
  z-index: 2;
  left: 0;
  display: inline-block;
  width: 100%;
  background-color: inherit;
  letter-spacing: -1ex;
  text-indent: -9999px;
  color: transparent;
  text-decoration: none;
  line-height: 1;
  line-height: 0; }

button:hover .scrollslider--scrollbutton-icon:after {
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

.scrollslider.has-scroll .scrollslider--scrollbutton {
  visibility: visible; }

.scrollslider.has-leftscroll .scrollslider--scrollbutton.at-left > button:after, .scrollslider.has-leftscroll .scrollslider--scrollbutton.at-left > button .scrollslider--scrollbutton-icon, .scrollslider.has-rightscroll .scrollslider--scrollbutton.at-right > button:after, .scrollslider.has-rightscroll .scrollslider--scrollbutton.at-right > button .scrollslider--scrollbutton-icon {
  opacity: 1; }

.swiper-notification {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*! Flickity v2.0.5
http://flickity.metafizzy.co
---------------------------------------------- */
[data-js-slider]:not(.is-slider-ready) .js-slider--next, [data-js-slider]:not(.is-slider-ready) .js-slider--prev {
  visibility: hidden; }

.flickity-viewport {
  position: relative;
  height: 100%;
  -webkit-flex: 0 0 100%;
  flex: 0 0 100%; }

.TeaserSlider .flickity-viewport {
  z-index: 1; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled {
  /* draggable = active */ }

.flickity-enabled.is-draggable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable * {
  -webkit-user-drag: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-enabled.is-dragging .js-slider--slide {
  pointer-events: none; }

.is-hidden {
  display: none !important; }

.is-disabled, *[disabled] {
  opacity: 0.5;
  pointer-events: none; }

/* Variables */
/* COLOURS */
/* FONTS */
/* FONT SIZES */
/* MARGINS */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

canvas {
  background: white; }

.chapter-control {
  display: none; }

.invisible {
  display: none !important; }

.controls {
  z-index: 500;
  position: absolute;
  height: 50px;
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  .controls .Button {
    will-change: opacity;
    -webkit-transition: opacity 0.5s;
    /* Safari */
    transition: opacity 0.5s;
    margin: 5px; }
    .controls .Button:disabled {
      opacity: 0;
      cursor: progress; }
  @media screen and (max-width: 600px) {
    .controls {
      height: 30px; }
      .controls button {
        height: 30px;
        margin-top: 30px !important;
        line-height: 30px;
        font-size: 11px; } }

.always-visible {
  opacity: 1 !important; }

#story-stage {
  position: relative; }

.canvas-logo {
  position: absolute;
  right: 32px;
  bottom: 0;
  display: inline-block;
  width: 125px;
  height: 41.01562px;
  z-index: 1;
  background: url("/static/media/logo_publiceye_raw_red.393eddc3.png") no-repeat center center;
  background-size: 100%; }
  .canvas-logo:hover {
    cursor: pointer; }
  @media screen and (max-width: 500px) {
    .canvas-logo {
      display: none !important; } }

a.red, span.red, i.red {
  color: #ff0040;
  font-family: "Radikal Bold"; }
  a.red.link:hover, span.red.link:hover, i.red.link:hover {
    color: #ff0040;
    border-bottom: 2px solid #ff0040; }

hr {
  margin: 10px auto; }

.fat {
  font-family: "Radikal Bold", sans-serif; }

.ui.publiceye-red.label {
  background-color: #ff0040 !important; }

.text-vert {
  font-family: "Radikal Bold", sans-serif;
  background: #c6cc00;
  color: white;
  padding: 0 5px; }

.text-mer {
  font-family: "Radikal Bold", sans-serif;
  background: #006397;
  color: white;
  padding: 0 5px; }

.text-jaune {
  font-family: "Radikal Bold", sans-serif;
  background: #ffe300;
  color: black;
  padding: 0 5px; }

.text-violet {
  font-family: "Radikal Bold", sans-serif;
  background: #5b004d;
  color: white;
  padding: 0 5px; }

.text-brown {
  font-family: "Radikal Bold", sans-serif;
  background: #8b1921;
  color: white;
  padding: 0 5px; }

.text-red {
  font-family: "Radikal Bold", sans-serif;
  background: #ff0040;
  color: white;
  padding: 0 5px; }

.text-blue {
  font-family: "Radikal Bold", sans-serif;
  background: #12c3e1;
  color: white;
  padding: 0 5px; }

i.flag:not(.icon) {
  margin: 0 2px !important; }

a:hover, .red.link:hover {
  cursor: pointer;
  color: #ff0040; }

.hyphens {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }
