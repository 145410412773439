@font-face {
    font-family: 'Radikal Bold';
    src: url('RadikalW01-Bold.eot');
    src: url('RadikalW01-Bold.eot?#iefix') format('embedded-opentype'),
        url('RadikalW01-Bold.woff2') format('woff2'),
        url('RadikalW01-Bold.woff') format('woff'),
        url('RadikalW01-Bold.ttf') format('truetype'),
        url('RadikalW01-Bold.svg#RadikalW01-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

