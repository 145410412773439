/* STYLES FOR MAP COMPONENT*/
$random-number: random(10) + 10;

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

.grid-container {
  height: 0;
  padding-top: 56.25%;
  position: relative;
}

#grid-component {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  .problem-tiles-wrapper {
    div {
      -webkit-animation: slide-in-blurred-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
      -moz-animation: slide-in-blurred-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
      -o-animation: slide-in-blurred-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
      animation: slide-in-blurred-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;

      @for $i from 0 to 9 {
        &:nth-child(#{$i + 1}) {
          animation-duration: 0.3s;
          animation-delay: $i * 0.025s;
        }
      }
    }
  }

  .spotlight {
    div {
      -webkit-animation: flickerAnimation 1s infinite;
      -moz-animation: flickerAnimation 1s infinite;
      -o-animation: flickerAnimation 1s infinite;
      animation: flickerAnimation 1s infinite;

      @for $i from 0 to 15 {
        $t: (1 + .01*random(800)) * 1s;

        &:nth-child(#{$i + 1}) {
          /* same styles as before */
          animation-duration: 3s;
          animation-delay: $i * .5s;
        }
      }
    }
  }

  div {
    display: flex;
    align-content: space-around;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    // height: 50%;
    // top: 5%;

    h3.commodity-title {
      font-size: 1em;
      padding: 0;
      position: absolute;
      background: white;
      bottom: 0;
      left: 0;
      z-index: 1000;
      // border: 2px solid $color-red;
      color: $color-red;
      width: calc(100%);
      text-align: center;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      will-change: background;
      margin: auto;

      @media screen and (min-width: 600px) {
        font-size: 1.6em;
        padding: 10px 14px;
        border: 0 solid $color-red;
        bottom: 0;
      }

      &:hover {
        background: $color-red;
        color: white;
        cursor: pointer;
      }
    }

    div {
      &.tile {
        background: transparent;
        background-position: center;
        padding: 0;

        &.problem.container {
          opacity: 0.5;
          transition: opacity 0.2s;
          will-change: opacity;
          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }

        &.visible {
          img {
            opacity: 1 !important;
            animation: none;
            -webkit-animation: none;
          }
        }

        p {

          background-position: center;
          height: auto;
          justify-content: center;
          align-items: center;
          display: flex;
          background: rgba(255,255,255,0.9);
          padding: 5px;
          border-radius: 0;

          @media screen and (max-width: 500px) {
            padding: 2px;
          }

          &.tile-problem {
            font-family: $font-family-sans-serif-bold;
            width: 100%;
            font-size: 1em;
            line-height: 1.2;
            @media screen and (max-width: 500px) {
              font-size: 10px;
              line-height: 1.1;
            }
          }

          text-align: center;
          // text-transform: uppercase;
          -webkit-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
          line-height: 1.2;
          font-size: 1em;
          @media screen and (max-width: 500px) {
            font-size: 0.8em;
          }
        }

        img {
          opacity: 1;
          object-fit: contain;

          &.inactive {
            opacity: 0.2;
          }

          &.active {
            &:not(.visible) {
              -webkit-animation: vibrate-2 0.3s both infinite;
              animation: vibrate-2 0.3s both infinite;
            }
            opacity: 1;
            width: 100%;
          }

          &.visible {
            opacity: 1
          }
        }
      }
    }
  }

  .controls {
    @media screen and (max-width: 500px) {
      display: flex;
      @media screen and (max-width: 600px) {
        top: -32px !important;
      }
    }
  }

}
