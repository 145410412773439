/* STYLES FOR ORIENTATION LOCK COMPONENT*/

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

#orientation-lock {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: $color-red;
  text-align: center;
  color: white;
  img {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
  }
  .orientation-text {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 15px;
    margin: 0;
    background: $color-red;
    h3 {
      padding: 10px;
      margin: 0;
      color: white;
    }
  }


  @media  (max-device-width: 720px) and (orientation: landscape) {
    display: block;
  }

}
