/* STYLES FOR HEADER COMPONENT*/

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

#header-component {

  .button-column {
    line-height: 60px;
    vertical-align: middle;
  }
  .language-button {
    margin-left: 10px;
    font-size: 1.5em;
    font-family: $font-family-sans-serif-bold, sans-serif;
    &.active {
      border-bottom: 2px solid $color-red;
      color: $color-red;
    }
    @media screen and (max-width: 500px) {
      font-size: 0.8em;
      margin-left: 6px;
    }
  }
  .column.title {
    display: none;
  }
  .app-title {
    &:hover {
      cursor: pointer;
    }
  }
  .header-logo {
    max-height: 60px;
    @media screen and (max-width: 500px) {
      width: 80px;
      padding-top: 10px;
    }
  }
  .top-text {
    font-family: $font-family-sans-serif-bold, sans-serif;
    line-height: 60px;
    vertical-align: middle;
    font-size: 2em;
    font-weight: 500;

    @media screen and (max-width: 500px) {
      font-size: 1em;
    }

    ul li {
      margin-bottom: 15px;
      text-align: left;
    }
  }
}
