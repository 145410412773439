/* STYLES FOR AUTOSIZE COMPONENT */

/* Import vars */
@import '../../scss/vars';

#autosize {
  .resizeable {
    text-align: center;
    font-family: $font-family-sans-serif-bold;
    background: $color-red;
    margin-top: 15px;
    color: $color-white;
    .size-teller {
      font-size: $font-size-medium;
      line-height: 50px;
      vertical-align: middle;
    }
  }
  .buttons {
    text-align: center;
    .Button {
      margin: auto 5px;
    }
  }
}
