/* STYLES FOR TEXT COMPONENT*/

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

#text-component {
  .top-text {
    font-family: $font-family-sans-serif-bold, sans-serif;
    line-height: 1.2;
    font-size: 0.9em;
    font-weight: 500;

    &.smaller {
      font-size: 90%;
      line-height: 1.2;
    }

    &.smallest {
      font-size: 80%;
      line-height: 1.15;
    }

    ul li {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .further-links {
    margin: 10px;
    font-size: 1em;
    a, p {
      margin: auto auto 5px auto;
      transition: opacity 0.5s;
      -webkit-transition: opacity 0.5s;
      will-change: opacity;

      span, i {
        &:hover {
          cursor: pointer;
          border-bottom: 2px solid $color-red;
        }
      }

      &.red:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    @media screen and (max-width: 500px) {
      margin: 5px;
    }
  }
}
