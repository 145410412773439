/* Variables */

/* COLOURS */
$color-red: rgb(255,0,64);
$color-blue: #12c3e1;
$color-brown: #8b1921;
$color-black: #3b3b34;
$color-violet: #5b004d;
$color-mer: #006397;
$color-vert: #c6cc00;
$color-jaune: #ffe300;
$color-grey: #ddd;
$color-white: #fff;
$color-black: #000;

/* FONTS */
$font-family-sans-serif-bold: 'Radikal Bold';
$font-family-sans-serif-regular: 'Radikal Regular';
$font-family-serif-regular: 'GT Sectra';
$font-family-serif-medium: 'GT Sectra Medium';
$font-family-serif-bold: 'GT Sectra';

/* FONT SIZES */
$font-size-smaller: 0.8rem;
$font-size-small: 1rem;
$font-size-regular: 1.4rem;
$font-size-medium: 2rem;
$font-size-large: 3rem;
$font-size-larger: 3.2rem;

/* MARGINS */
$margin-smaller: 0.8rem;
$margin-small: 1rem;
$margin-medium: 2rem;
$margin-large: 3rem;
$margin-larger: 3.2rem;
