/* STYLES FOR CHAT COMPONENT*/

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

.chat-component {
  .rsc-content {
    height: 100%;
    overflow-y: scroll;

    @media screen and (max-width: 500px) {
      height: 100vh;
    }
  }
  .rcs-ts-bubble, .rsc-ts-bubble {
    max-width: 80%;
  }
  .rsc-os-option-element {
    font-family: $font-family-sans-serif-bold;
  }
  .rsc-ts-user {
    .rsc-ts-bubble {
      background: $color-blue !important;
    }
  }
  .rsc-ts-bot + .rsc-ts-bot {
    margin-left: -6px;
  }
  .rsc-cs {
    max-width: 50%;
  }
}
