/* STYLES FOR MAP COMPONENT*/

/* Import vars */
@import '../../scss/vars';

.map-container {
  // padding-top: 56.25%;
  // margin: 15px;
  // position: relative;
}

.map-component-wrapper {
  padding-top: 56.25%;
  margin: 15px;
  position: relative;
}

#map-component {

  overflow: hidden;

  path.selected {
    fill: $color-red !important;
  }

  position: absolute;
  bottom: 11px;
  left: 0;
  max-width: 900px;
  margin: 15px 30px 15px 15px;
  border-color: $color-red;
  z-index: 10;
  background: white;
  .touch-message {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.8);
    color: black;

    &.visible {
      display: flex;
    }
  }
  .button-container {
    position: absolute;
    margin: 5px;

    .Button {
      margin: 5px;
    }
  }
  .annotation {
    font-size: 1.5em;
    font-family: $font-family-sans-serif-bold;

    &.white {
      fill: white;
      stroke: white;
      stroke-width: 8px;
      &.small {
        stroke-width: 2px;
      }
    }
    &.small {
      font-size: 0.8em;
    }
  }
  .choose-notification {
    width: 100%;
    text-align: center;
    position: absolute;
    top: -15px;
    .save-buttons {
      position: relative;
      top: -20px;
      .Button {
        margin: 5px;
      }
    }
  }
}
