/* STYLES FOR BLACKOUT COMPONENT*/

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

#graphic-component {
  z-index: 50;
  position: relative;

  .controls {
    position: absolute;
  }

  .graphic-container {
    bottom: 0;
    margin: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 6px $color-red;

    div {
      text-align: center;
      color: $color-white;
    }
  }
}
