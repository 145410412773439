/* STYLES FOR FLIPCARD COMPONENT*/

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

.flipcards-component.flex-grid {
  z-index: 50;
  bottom: 3px;
  margin: 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .flex-grid-thirds {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.5s;
    -webkit-transition: opacity 0.5s;

    &:not(:hover) {
      @for $i from 0 to 9 {
        &:nth-child(#{$i + 1}) {
          animation-duration: (0.8 + (0.5 * $i)) * 0.1s;
          animation-delay: $i * 0.025s;
        }
      }
      -webkit-animation: vibrate-2 0.3s linear 3 both;
      animation: vibrate-2 0.3s linear 3 both;
    }

    &:hover {
      // -webkit-animation: jello-vertical 0.9s both;
      // animation: jello-vertical 0.9s both;
      cursor: pointer;

      @media screen and (min-width: 501px) {
        -webkit-animation: vibrate-2 0.3s linear infinite both;
        animation: vibrate-2 0.3s linear infinite both;
      }
      // z-index: 100;
      border: 4px solid white;
      background-color: $color-mer;
    }

    /*
    -webkit-animation: slide-in-blurred-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    -moz-animation: slide-in-blurred-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    -o-animation: slide-in-blurred-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    */

    @for $i from 0 to 9 {
      &:nth-child(#{$i + 1}) {
        animation-duration: 0.5s;
        animation-delay: $i * 0.025s;
      }
    }
  }

  .flipcards-overlay {
    z-index: 999;
    // animation-duration: 0.75s;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-red;
    width: 100%;
    border: 6px solid $color-red;

    &:hover {
      cursor: n-resize;
    }

    .ui.header > .image:not(.icon), .ui.header > img {
      @media screen and (max-width: 500px) {
        width: 1em;
      }
    }

    h1 {
      display: inline;
      font-family: $font-family-sans-serif-bold;
      font-size: 2em;
      padding: 5px 10px;
      color: white;
      position: absolute;
      left: 0;
      top: 0;

      @media screen and (max-width: 500px) {
        padding: 5px;
        margin: 0;
        font-size: 1em;
        line-height: 1.1;
      }

      @media screen and (max-width: 321px) {
        font-size: 12px;
      }
    }

    .ui.text.container {
      margin-left: 5px !important;
      margin-right: 5px !important;
      @media screen and (max-width: 500px) {
        padding: 5px;
      }
    }

    .overlay-content {
      font-family: $font-family-serif-regular;
      font-size: 1.5em;
      margin-top: 5px;
      line-height: 1.3;
      padding: 0 15px;

      @media screen and (max-width: 500px) {
        padding-top: 5px;
        font-size: 11px;
        line-height: 1.1;
      }

      @media screen and (max-width: 321px) {
        font-size: 10px;
      }
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 0;

      @media screen and (max-width: 500px) {
        font-size: 1.2em;
        top: 0;
        right: -5px;
      }
    }
  }
}
