/* STYLES FOR STORYLINE COMPONENT */
/* MARC FEHR FOR PUBLIC EYE IN APRIL 2019 */

/* Import vars */
@import '../../scss/vars';

/* Import animations */
@import '../../scss/animations';

#story-stage {
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
  margin-top: 15px;

  #redbox {
    position: relative;
    bottom: -11px;
  }

  canvas {
    position: relative;
  }

  .canvas-logo {
    bottom: 8px;
  }

  .ui[class*="center aligned"].segment {
    font-family: $font-family-sans-serif-bold;
  }

  .grid-container {
    bottom: 4px;
    margin: 15px;
    position: relative;
    background: white;
    z-index: 10;
  }

  #story {
    max-height: calc(100vh / 2);
    // height: 100%;
    margin: 0 15px -12px 15px;
    position: relative;
    height: 0;
    padding-top: 37.5%;

    #messages {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      width: 100%;
      background: white;
      position: absolute;
      padding: 15px 15px 30px 15px;
      height: 100%;
      top: 0;
      left: 0;

      ul li {
        margin: 5px auto;
        -webkit-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
        margin-bottom: 10px;

        @media screen and (max-width: 600px) {
          font-size: 90%;
          margin: 5px auto;
        }
      }

      @media screen and (max-width: 500px) {
        padding: 15px 5px 20px 5px;
      }

      &.dark {
        background: $color-black;
        color: $color-white;
      }

      .chapter-control {
        // display: none;
        position: absolute;
        color: $color-red;
        top: 10px;
        left: 10px;
      }

      .slider-container {
        z-index: 999;
        position: relative;
        max-width: 290px;
        width: 100%;
        margin: 0 auto 20px auto;
        align-self: flex-end;
        display: block;
        .slider-value {
          margin-bottom: 0;
          text-align: center;
          font-size: 2em;
          color: $color-red;

          @media screen and (max-width: 500px) {
            font-size: 1.5em;
          }
        }
        .rc-slider {
          margin-top: 10px;
          .rc-slider-rail {
            background-color: #ddd;
            height: 8px;
          }
          .rc-slider-track {
            height: 8px;
            background-color: $color-red;
          }
          .rc-slider-handle {
            width: 18px;
            height: 18px;
            margin-top: -5px;
            border-color: $color-red;
          }
        }

        .ui.pointing.label, .ui[class*="pointing above"].label {
          margin: 0 auto;
        }

        .call-to-action {
          position: absolute;
          top: 75px;
          width: 100%;
          text-align: center;
          margin: 0 auto;
          @media screen and (max-width: 500px) {
            top: -15px;
          }
        }
      }

      .story-text {
        line-height: 1.25;
        text-align: center;
        font-size: 2rem;
        font-family: $font-family-sans-serif-bold;

        @media screen and (max-width: 900px) {
          font-size: 1.5rem;
        }
        @media screen and (max-width: 420px) {
          font-size: 1rem;
        }
      }

      @media screen and (max-width: 420px) {
        margin: 0;
      }
    }

    @media screen and (max-width: 800px) {
      max-height: calc(100vh / 2);
    }
    @media screen and (max-width: 420px) {
      max-height: calc(100vw);
    }

    // HEADER STYLINGS
    .ui.huge.header {
      font-family: $font-family-sans-serif-bold;
    }
  }
}
